import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { MasterTypes, ProcessStepsEnum } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import { LoadingOutlined } from "@ant-design/icons";
import { HttpMethods } from "../../Utilities/ApiUtility.axios";

const { TextArea } = Input;
const Rejection: React.FC<{
  open: boolean;
  id: string;
  processStep: ProcessStepsEnum | undefined;
  onCancel: (rec: boolean) => void;
}> = (props) => {
  const [form] = Form.useForm();
  const [textValue, setTextValue] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [masterData, setMasterData] = React.useState<{
    reason: any;
  }>({
    reason: [],
  });
  const onSubmit = () => {

    form
      .validateFields()
      .then(async (values: any) => {
        let val = {
          ...values,
          processStep: props?.processStep,
          reason: {
            name: values?.reason?.label,
            id: values?.reason?.value
          },
          id: props?.id
        }
        setSaveLoading(true)
        let res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.ENQUIRY_REJECTION, {}, val);
        if (res?.result) {
          props?.onCancel(true);
        }
        setSaveLoading(false)
      })
      .catch((error: any) => {
      });
  };

  const getMaster = async () => {
    setIsLoading(true)
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    setIsLoading(false)
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        reason: any;
      } = {
        reason: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Reason
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };

  React.useEffect(() => {
    getMaster();
  }, []);
  //console.log(props,"propsData");
  return (
    <>
      <Modal
        title="Rejection Form"
        open={props.open}
        maskClosable={false}
        onCancel={() => props.onCancel(false)}
        width={500}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "25px",
            }}
          >
            <Button onClick={() => props.onCancel(false)}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={saveLoading}>
              Save
            </Button>
          </Space>
        }
      >
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <>
            <Divider className="marginDivider" />

            <Form form={form} layout="vertical" requiredMark={true}>
              <Form.Item name="reason" label="Reason" className="marginbtm" rules={[{
                required: true,
                message: "this field is required"
              }]}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Reason"
                  optionFilterProp="children"
                  labelInValue
                  allowClear
                  popupMatchSelectWidth={true}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={masterData?.reason}
                />
              </Form.Item>

              <Form.Item name="note" label="Note" className="marginbtm">
                <TextArea
                  showCount
                  rows={4}
                  placeholder="Add Note Here....."
                  maxLength={100}
                  onChange={(e: any) => {
                    setTextValue(e.target.value);
                    //console.log(textValue, "textValue");
                  }}
                />
              </Form.Item>
            </Form>
          </>
        </Spin>
      </Modal>
    </>
  );
};

export default Rejection;
