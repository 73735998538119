import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { EnquiryType } from "../../../Constant/Enums";
import { Breadcrumb, Button, Col, Result, Row, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../../Common/Common.css";
import WebsiteActions from "./WebsiteActionsConfiguration/WebsiteActions";
import ApplicationActions from "./ApplicationActionConfiguration/ApplicationActions";

interface IActionConfiguration {
  ProcessStepType: EnquiryType;
}

const getComponent = (processStepType: EnquiryType) => {
  if (processStepType === EnquiryType.Undefined)
    return (
      <>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      </>
    );
  else if (processStepType === EnquiryType.Website) return <WebsiteActions/>;
  else return <ApplicationActions/>;
};
const ActionConfiguration = (props: IActionConfiguration) => {
  return (
    <>
      <Row justify="end" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Fees Type",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>{getComponent(props.ProcessStepType)}</Col>
      </Row>
    </>
  );
};

export default ActionConfiguration;
