import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  ConfigProvider,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Table,
  message,
  Dropdown,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  DownloadOutlined,
  EyeOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Upload, { RcFile } from "antd/es/upload";
import type { MenuProps, TableColumnsType } from "antd";
import Search from "antd/es/input/Search";
import {
  AttachmentsCategory,
  CategoryAttachmentName,
  CategoryAttachmentOptions,
  MasterTypes,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";

interface AttacmentsDataType {
  key: string;
  name: string;
  age: number;
  address: string;
}
const data: AttacmentsDataType[] = [];

const AttachmentTable: React.FC<{
  type: number;
  apiData: any;
  setReload?:any
  attachmentContentRefresh: boolean;
  onSubmit: (rec: boolean) => void;

}> = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
    },
  ]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, index: any) => {
    // let prevAtt = [...attachmentList];

    let currentFileBase64 = await getBase64(file as RcFile);

    let fileInfo = {
      id: "",
      name: file?.name,
      contentType: file?.type,
      length: file?.size,
      path: currentFileBase64,
    };

    // prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

    setAttachmentList([fileInfo]);
  };
  const [refresh, setRefresh] = useState(false);
  const [imagePath,setImagePath]= useState("");
  const [documentType, setDocumentType] = useState<any[]>([]);
  const [listParams, setListParams] = useState<any>({
    filterId: "",
    nameSearch: "",
    batchSearch: "",
    sortCol: "",
    sortDir: "",
    userRoleId: "",
    start: 0,
    length: 10,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  changeListParams(inputName, value);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  changeListParams(inputName);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
              // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                onChange={(e) => changeListParams(inputName, e.target?.value)}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };

  const items: MenuProps["items"] = [
    {
      label: "Download",
      key: "Download",
      icon: <DownloadOutlined />,
    },
  ];
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };
  
  // const downloadImage=async ()=>{
    
  //   let res = await ApiUtility.downloadFile(file
  //   );
  //   if (res?.status) {
  //     console.log("res",res?.status)
  //   } else {
  //     message.error("Failed to download file");
  //   }
  //   console.log("file",file);
    
  // }
  // const menuProps: any = {
  //   items,
  //   onClick: downloadImage,
  // };

  const columns: TableColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Category"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
          option={CategoryAttachmentOptions}
        />
      ),
      dataIndex: "category",
      key: "category",
      width: "15%",
      render: (category: any) => {
        return <>{CategoryAttachmentName[category]}</>;
      },
    },
    {
      title: (
        <THeader
          title={"Document Type"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
          option={documentType}
        />
      ),
      dataIndex: "fileType",
      key: "fileType",
      width: "10%",
      render: (fileType: any) => {
        return <>{fileType?.name}</>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "Upload date",
      dataIndex: "uploadedDate",
      key: "",
      width: "15%",
    },
    {
      title: "Verified",
      dataIndex: "Verified",
      key: "Verified",
      width: "15%",
      align: "center",
      render: (_: any, record: any) => (
        <>
          {
            record?.documentIsVerified ? <CheckOutlined /> : <CloseOutlined />

          }
        </>)
    },
    {
      title: (
        <THeader
          title={"Upload By"}
          inputName={"enqId"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "uploadedBy",
      key: "",
      width: "15%",
    },
    // {
    //   title: (
    //     <THeader
    //       title={"Assign To"}
    //       inputName={"enqId"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "",
    //   key: "",
    //   width: "20%",
    // },
    // {
    //   title: "Verification",
    //   dataIndex: "",
    //   key: "",
    //   width: "20%",
    // },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (_: any, att: any) => {
        return (
          <>
            <Dropdown.Button
              size="small"
              type="link"
              icon={<DownOutlined  onClick={()=>setImagePath(att)}/>}
              trigger={["click"]}
              // menu={menuProps}
              onClick={() => {
                window.open(att?.fileName?.path, "_blank");
              }}
            >
              Preview
            </Dropdown.Button>
          </>
        );
      },
    },
  ];

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setDocumentType(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  //console.log(counselorData, "testcou");
  React.useEffect(() => {
    getMaster();
  }, [props?.setReload]);

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (val: any) => {
        let formValues = {
          ...val,
          fileType: {
            id: val?.fileType?.value,
            name: val?.fileType?.label,
          },
          fileName:
            attachmentList.length > 0
              ? attachmentList[0]
              : {
                id: "",
                name: "",
                contentType: "",
                length: 0,
                path: "",
              },
          description: val?.description,
          category: AttachmentsCategory.StudentDocuments,
        };
        setLoading(true);
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.ENQUIRY_ADD_ATTACHMENTS + "?",
          {
            id: props?.apiData?.id,
            fieldName: "Attachments",
            valueType: "",
          },
          [formValues]
        );
        setLoading(false);
        if (response?.status) {
          message.success("Attachment Submitted");
          props.onSubmit(true)
          form.resetFields();
        } else {
          message.error(response?.message);
        }
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  };
  useEffect(() => {
    setTableData(
      props?.apiData?.attachments?.map((itm: any) => ({
        ...itm,
        uploadedDate: itm?.updateInfo?.date
          ? dayjs(itm?.updateInfo?.date)?.format("DD MMM YYYY")
          : "-",
        uploadedBy: itm?.updateInfo?.userName ? itm?.updateInfo?.userName : "-",
      }))
    );
  }, [props?.apiData, props?.attachmentContentRefresh,props?.setReload]);
  console.log("apiData From Att = ", props?.apiData?.attachments);
  console.log("apiData table Data = ", tableData);
  return (
    <>
      <Form form={form} requiredMark={false}>
        <Row gutter={[10, 10]}>
          <Col lg={5} xl={5}>
            <Form.Item
              name={"fileType"}
              rules={[
                {
                  required: true,
                  message: "Select Doc Type",
                },
              ]}
            >
              <Select
                placeholder="Select Doc Type"
                options={documentType}
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col lg={8} xl={8}>
            <Form.Item name={"description"}>
              <Input.TextArea
                placeholder="Description"
                style={{ width: "100%" }}
                autoSize={{
                  minRows: 1,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={3} xl={3}>
            <Form.Item
              name={"fileName"}
              rules={[
                {
                  required: true,
                  message: "Choose Doc",
                },
              ]}
            >
              <Upload
                defaultFileList={[]}
                beforeUpload={(file: any, index: any) => {
                  handelFile(file, index);
                  return false;
                }}
                maxCount={1}
                style={{width:'100%'}}
              >
                <Tooltip
                  title={
                    form.getFieldsValue(["attachments"])?.attachments?.fileName
                      ?.name
                  }
                >
                  <Button icon={<UploadOutlined />} style={{width:'100%'}}>
                    {form.getFieldsValue(["attachments"])?.attachments?.fileName
                      ?.id
                      ? form.getFieldsValue(["attachments"])?.attachments
                        ?.fileName?.name?.length > 10
                        ? form
                          .getFieldsValue(["attachments"])
                          ?.attachments?.fileName?.name?.substr(0, 5) + "..."
                        : form.getFieldsValue(["attachments"])?.attachments
                          ?.fileName?.name
                      : "Click to Upload"}
                  </Button>
                </Tooltip>
              </Upload>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Button type="primary" onClick={onSubmit} loading={loading} style={{marginLeft:20}}>
              Add
            </Button>
          </Col>
        </Row>
      </Form>

      <Row style={{ paddingTop: "5px" }}>
        <Col lg={24}>
          <Table columns={columns} dataSource={tableData} />
        </Col>
      </Row>
    </>
  );
};

export default AttachmentTable;
