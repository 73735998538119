import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message, Select, ConfigProvider } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import AddEditBatch from "./AddEditBatch";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { MasterTypes } from "../../../Constant/Enums";
import { BatchMaster } from "../../../ApiUrls/URLS";
import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../../../Services/HTTPCalls";
const api = new ApiCalls(BatchMaster.endPoints, BatchMaster.prefix);
interface IBatch {
  key: string;
  sNo: number;
  id: string;
  name: string;
  inTime: any;
  outTime: any;
}


//#region  Custom TableHeader
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
  defaultValue,
  setDepartmentSearchValue
}: any) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: "#1B1A1A",
              colorFillAlter: "transparent",
            },
            Select: {
              colorTextPlaceholder: "#1B1A1A",
            },
          },
        }}
      >
        <div
          className="searchDivStyle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {ifSelect ? (
            <Select
              allowClear
              style={{ width: "100%" }}
              onSelect={(value) => {
                setListParams({...listParams, start: 0 }); // Move this to the top
                if (changeListParams) {
                  changeListParams(inputName, value);
                }
                if (inputName === "departmentSelection") {
                  setListParams({...listParams, departmentId: value });
                  setDepartmentSearchValue(value);
                }
                if (inputName === "course") {
                  setListParams({...listParams, courseId: value });
                }
                setShouldRefresh((x: boolean) =>!x);
              }}
              onClear={() => {
                // if (inputName === "counselors")
                //   changeListParams(inputName, "");
                // else
                //   changeListParams(inputName)
                
                setListParams({ ...listParams, start: 0,departmentId:"",courseId:"" });
                setShouldRefresh((x: boolean) => !x);
              }}
              popupMatchSelectWidth={false}
              variant="borderless"
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={option}
            // defaultValue={defaultValue}
            />
          ) : (
            <Search
              placeholder={title}
              className="CitSearchInput"
              allowClear
              onChange={(e:any) => {
                if (changeListParams) {
                  changeListParams(inputName, e.target?.value);
                };
                // props?.changeListParams(inputName, e.target?.value)
              }}
              onSearch={(val) => {

                setListParams({ ...listParams, start: 0,search:val });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              variant="borderless"
            />
          )}
        </div>
      </ConfigProvider>
    </>
  );
};
//#endregion


const Batch: React.FC = () => {
  const [listParams, setListParams] = useState({
    search: "",
    departmentId:"",
    courseId:"",
    sortCol: "Name",
    sortDir: "ascend",
  });
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allBatch, setAllBatch] = useState<IBatch[]>();
  const [departmentSearchOption, setDepartmentSearchOption] = useState<any>();
  const [departmentSearchValue, setDepartmentSearchValue] = useState<any>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  


  useEffect(() => {
    setPageLoader(true);
    getAllBatchDetails(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);


  
  const getAllBatchDetails = async (start: any, length: any) => {
    // await api
    //   .GET(BatchMaster.endPoints.getList + "?", {
    //     masterType: MasterTypes.Batch,
    //     start: start,
    //     length: length,
    //     search: listParams.search,
    //     sortCol: listParams.sortCol,
    //     sortDir: listParams.sortDir,
    //   })
    await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_BATCH_SEARCHING+"?",
      {
        MasterTypes: MasterTypes.Batch,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
        departmentId: listParams.departmentId,
        courseId:listParams.courseId
      }
    )
      .then((res: IApiResponse<any>) => {
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          console.log(res?.result?.items);
          setAllBatch(
            res?.result?.items?.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              code: r?.code,
             course: r?.course?.name,
              department: r?.department?.name,
              schema: r?.schema?.name
              // inTime: r?.inTime,
              // outTime: r?.outTime,
            }))
          );
          // Get unique department names
          const uniqueDepartments = Array.from(new Map(res?.result?.items?.map((r: any) => 
            [r?.department?.id, { value: r?.department?.id, label: r?.department?.name }])
          ).values());
          setDepartmentSearchOption(uniqueDepartments);
          setPageLoader(false);
        } else {
          setAllBatch([])
          setPageLoader(false);
        }
      });
  };

  const { data: courseSearchOption } = useQuery(
    {
        queryKey: ['courseSearch',departmentSearchValue],            
        queryFn: (async () => {
          let data:IApiResponse = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_COURSE_BY_DEPARTMENT +"?",
            {
              departmentId : departmentSearchValue
            })
         return data?.result?.map((res:any)=>{
          return{
            value:res?.id,
            label:res?.name
          }
         })
        }),
        enabled: departmentSearchValue!== undefined && departmentSearchValue!== '' && departmentSearchValue!== null
    },
)

  const columns: ColumnsType<IBatch> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (_, record, index) => <p>{_}</p>,
    },
    {
      title:(
        <>
          <THeader
            title={"Batch Name"}
            inputName={"batchSearch"}
            // changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={listParams}
            setListParams={setListParams}
          // ifSelect={true}
          />
        </>
      ),
      // title: "Batch Name",
      dataIndex: "name",
      showSorterTooltip: false,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setBatchId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Batch Code",
      dataIndex: "code",
      key: "Code",
      render: (text: any) => text,
    },
    {
      title:(
        <>
          <THeader
            title={"Department"}
            inputName={"departmentSelection"}
            // changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={listParams}
            setListParams={setListParams}
          ifSelect={true}
          option={departmentSearchOption}
          setDepartmentSearchValue={setDepartmentSearchValue}
          />
        </>
      ),
      // title: "Department",
      dataIndex: "department",
      key: "department",
      width:"20%",
      render(value, record, index) {
        // console.log(record)
        return <span>{value}</span>;
      },
      // render: (text: any) => text,
    },
    {
      title:(
        <>
          <THeader
            title={"Course"}
            inputName={"course"}
            // changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={listParams}
            setListParams={setListParams}
            ifSelect={true}
            option={courseSearchOption}
          />
        </>
      ),
      // title: "Course",
      dataIndex: "course",
      key: "Course",
      render: (text: any) => text,
    },
    {
      title: "Schema",
      dataIndex: "schema",
      key: "schema",
      render: (text: any) => text,
    },  
    // {
    //   title: "From",
    //   dataIndex: "inTime",
    //   width: "15%",
    //   render: (text: any) => <p>{text}</p>,
    // },
    // {
    //   title: "To",
    //   dataIndex: "outTime",
    //   width: "15%",
    //   render: (text: any) => <p>{text}</p>,
    // },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd" }}
            onClick={() => {
              setBatchId(record.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
  ];



  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      <AddEditBatch
        setTrigger={setTrigger}
        batchId={batchId}
        openDrawer={openDrawer}
        SetDrawerClose={setOpenDrawer}
        setBatchId={setBatchId}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Batches",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Button
              type="primary"
              className="cit-add-btn mb-1"
              onClick={() => {
                setBatchId(undefined);
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined />
              Batch
            </Button>

            <Search
              size="middle"
              placeholder="Search Name Here..."
              className="att-search-input mb-1"
              allowClear
              onSearch={(val: string) => setListParamsAndRefresh(val)}
              onChange={(e: any) =>
                e.target.value === "" ? setListParamsAndRefresh("") : null
              }
              style={{ width: 200 }}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allBatch}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Batch;
