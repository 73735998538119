import * as React from "react";
import { useContext, useState, useEffect } from "react";
import UserList from '../../../component/Users/UserList'

type Props = {}

const User = (props: Props) => {
    return (
        <UserList />
    )
}

export default User