import { notification } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Enquiry from "../Enquiry/Enquiry";
import Fees from "../pages/Fees/Fees";
import StudentsList from "../pages/Students/StudentsList";
import Batch from "../Admin/Masters/Batch/Batch";
import Attendance from "../Admin/Attendance/Attendance";
import Holiday from "../Admin/Masters/Holidays/Holiday";
import Gender from "../Admin/Masters/Gender/Gender";
import Category from "../Admin/Masters/Category/Category";
import Curriculum from "../Admin/Masters/Curriculum/Curriculum";
import Stream from "../Admin/Masters/Stream/Stream";
import Class from "../Admin/Masters/Class/Class";
import PaymentMedium from "../Admin/Masters/PaymentMethod/PaymentMedium";
import Credentials from "../Admin/Masters/Credentials/Credentials";
import Document from "../Admin/Masters/Document/Document";
import Status from "../Admin/Masters/Status/Status";
import Medium from "../Admin/Masters/Medium/Medium";
import Setting from "../pages/setting/Setting";
import EnquirySetting from "../pages/setting/Enquiry/EnquirySetting";
import StudentSetting from "../pages/setting/Student/StudentSetting";
import MasterSetting from "../pages/setting/Masters/MasterSetting";
import AttendanceSetting from "../pages/setting/Attendance/AttendanceSetting";
import FessSetting from "../pages/setting/Fees/FessSetting";
import Course from "../Admin/Masters/Course/Course";
import Subjects from "../Admin/Masters/Subjects/Subjects";
import Semester from "../Admin/Masters/Semester/Semester";
import Department from "../Admin/Masters/Department/Department";
import User from "../pages/setting/User/User";
import RoleList from "../Admin/Masters/Role/RoleList";
import CourseSetting from "../pages/setting/Course/Course";
import { AccessCodes, IAccessCodes } from "./Sidemenus";
import FeesType from "../Admin/Masters/FeesType/FeesType";
import ProcessSteps from "../Admin/Masters/ProcessSteps/ProcessSteps";
import EnquiryLead from "../Enquiry/EnquiryLead";
import { EnquiryType } from "../Constant/Enums";
import ProcessStepSetting from "../Admin/Masters/ProcessSteps/ProcessStepSetting";
import ActionConfiguration from "../Admin/Masters/ProcessSteps/ActionConfiguration";
import EnquiryFormStudents from "../Enquiry/EnquiryFormStudents";
import Admission_Enquiry from "../Enquiry/Admission_Enquiry";
import Source from "../Admin/Masters/Source/Source";
import AssignTo from "../Admin/Masters/AssigneTo/AssignTo";
import Reason from "../Admin/Masters/Reason/Reason";
import Platform from "../Admin/Masters/Platform/Platform";
import Reminder from "../Admin/Masters/Reminder/Reminder";
import Task from "../Admin/Masters/Task/Task";
import MailTemplates from "../Admin/Masters/MailTemplates/MailTemplates";
import Session from "../Admin/Masters/Session/Session";
import ManageSchedule from "../pages/LMS/ManageSchedule";
import ClassandSubjects from "../pages/LMS/Class&Subjects";
import LessionPlanning from "../pages/LMS/LessionPlanning";
import Room from "../Admin/Masters/RoomNo/Room";
import SubjectAllocation from "../pages/LMS/SubjectAlllocation/SubjectAllocation";
import DesignCourse from "../component/DesignCourse/DesignCourse";
import Sections from "../Admin/Masters/Sections/Sections";
import Degree from "../pages/GraduationAlumni/Degree/AddEditDegree";
import Assessments_Grades from "../pages/Assessments/Assessments&Grades/Assessments_Grades";
import FacultyMapWithDept from "../Admin/Masters/FacultyMapWithDept/FacultyMapWithDept";
import Ecard from "../component/Ecards/Ecards";
import SubjectAllocationAdd from "../pages/LMS/SubjectAlllocation/SubjectAllocationAdd";
import SubjectAllocationList from "../pages/LMS/SubjectAlllocation/SubjectAllocationList";
import SubjectTypes from "../Admin/Masters/SubjectTypes/SubjectTypes";
import { duration } from "moment";
import Dashboard from "../pages/Dashboard/Dashboard";
import Page404 from "./Page404";
import MandatoryDocumentType from "../Admin/Masters/Document/MandatoryDocumentType";
import CallLogs from "../pages/Calls/TATACallLogs";
import TATACallLogs from "../pages/Calls/TATACallLogs";
import LeaveRequests from "../pages/LeaveRequests/LeaveRequests";
import PrivacyPolicy from "../Admin/Masters/PrivacyPolicy/PrivacyPolicy";
import LeaveTypes from "../Admin/Masters/LeaveTypes/LeaveTypes";

export const adminContext = React.createContext<any>(null);

const PageRoute: React.FC = () => {
  type NotificationType = "success" | "info" | "warning" | "error";
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    type: NotificationType,
    message: string | undefined,
  ) => {
    api[type]({
      message: "",
      description: message,
      duration: 3,
      style: {
        width: 400,
        top: 18,
        borderRadius: 0,
        padding: "20px 12px",
      },
    });
  };
  let rights: IAccessCodes = JSON.parse(
    localStorage.getItem("tp-user") + ""
  )?.rights;
  return (
    <adminContext.Provider value={{ openNotification }}>
      <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<Dashboard/>} />
        {rights?.enquiries === AccessCodes.enquiries ? (
          // <Route path="/enquiry" element={<EnquiryFormStudents />} />
          <Route
            path="/enquiry/*"
            element={
              <>
                <Routes>
                  <Route path="" element={<Admission_Enquiry />} />
                  {/* <Route path="/addEditEnquiry" element={<EnquiryFormStudents />}/> */}
                </Routes>
              </>
            }
          />
        ) : null}
        {rights?.students === AccessCodes.students ? (
          <Route path="/student" element={<StudentsList />} />
        ) : null}
        {rights?.lms === AccessCodes.lms && (
          // <Route path="/student" element={<StudentsList />} />
          <>
        <Route path="/class&Subjects" element={<ClassandSubjects />} />
        <Route path="/subjectAllocation" element={<SubjectAllocationList/>} />
        <Route path="/manageSchedule" element={<ManageSchedule />} />
        <Route path="/lessionPlanning" element={<LessionPlanning />} />
        <Route path = "/graduationalumni" element = {<Degree/>}/>
          </>
        )}
        {/* {<Route path="Batch" element={<Batch />} />} */}
        {/* <Route path="Category" element={<Category />} /> */}
        {/* <Route path="Gender" element={<Master />} /> */}
        {/* {<Route path="Gender" element={<Gender />} />} */}
        {rights?.attendance === AccessCodes.attendance ? (
          <Route path="attendance" element={<Attendance />} />
        ) : null}
        <Route path="/requests" element={<LeaveRequests/>}/>
        {/* <Route path="Course" element={<Course />} /> */}
        {/* <Route path="Subjects" element={<Subjects />} /> */}
        {/* <Route path="Semesters" element={<Semester />} /> */}
        {/* <Route path="Departments" element={<Department />} /> */}
        {rights?.fees === AccessCodes.fees ? (
          <Route path="/fees" element={<Fees />} />
        ) : null}
        {rights?.assessment === AccessCodes.assessment && (
          <Route path="/assessmentsGrades" element={<Assessments_Grades />} />
        )}
        <Route path="callLogs" element={<TATACallLogs/>}/>
        {/* {rights?.enquiryLead === AccessCodes.enquiryLead ? ( */}
        <Route path="Holiday" element={<Holiday />} />
        {rights?.settings === AccessCodes.settings ? (
          <Route
            path="settings/*"
            element={
              <>
                <Routes>
                  <Route path="/" element={<Setting />} />
                  {rights?.curriculums === AccessCodes.curriculums &&
                    <Route path="/Curriculum" element={<Curriculum />} />
                  }
                  {rights?.streams === AccessCodes.streams ? (
                    <Route path="/Stream" element={<Stream />} />
                  ) : null}
                  {rights?.classes === AccessCodes.classes ? (
                    <Route path="Class" element={<Class />} />
                  ) : null}
                  {rights?.paymentMedium === AccessCodes.paymentMedium ? (
                    <Route path="Payment" element={<PaymentMedium />} />
                  ) : null}
                  {rights?.credentials === AccessCodes.credentials ? (
                    <Route path="Credentials" element={<Credentials />} />
                  ) : null}
                  {rights?.documents === AccessCodes.documents ? (
                    <Route path="Document" element={<Document />} />
                  ) : null}
                   {rights?.documents === AccessCodes.documents ? (
                    <Route path="Mandatory-document-type" element={<MandatoryDocumentType />} />
                  ) : null}
                  {rights?.holidays === AccessCodes.holidays ? (
                    <Route path="Holiday" element={<Holiday />} />
                  ) : null}
                  {rights?.status === AccessCodes.status ? (
                    <Route path="Status" element={<Status />} />
                  ) : null}
                  {rights?.medium === AccessCodes.medium ? (
                    <Route path="Medium" element={<Medium />} />
                  ) : null}
                  {rights?.role === AccessCodes.role ? (
                    <Route path="Role" element={<RoleList />} />
                  ) : null}
                  {rights?.feesType === AccessCodes.feesType ? (
                    <Route path="FeesType" element={<FeesType />} />
                  ) : null}
                  {rights?.feesType === AccessCodes.feesType ? (
                    <Route path="FeesType" element={<FeesType />} />
                  ) : null}
                  <Route path="Source" element={<Source />} />
                  {/* <Route path="AssignTo" element={<AssignTo />} /> */}
                  <Route path="User" element={<User />} />
                  <Route path="Reason" element={<Reason />} />
                  <Route path="PlatForm" element={<Platform />} />
                  <Route path="Reminder" element={<Reminder />} />
                  <Route path="Task" element={<Task />} />
                  <Route path="MailTemplate" element={<MailTemplates />} />
                  <Route path="Course" element={<Course />} />
                  <Route path="Session" element={<Session />} />
                  <Route path="Semester" element={<Semester />} />
                  <Route path="Batch" element={<Batch />} />
                  <Route path="Subject" element={<Subjects />} />
                  <Route path="Sections" element={<Sections/>}/>
                  <Route path="Room" element={<Room />} />
                  <Route path="designCourse" element={<DesignCourse />} />
                  <Route path="facultyMapWithDept" element={<FacultyMapWithDept />} />
                  <Route path="eCard" element={<Ecard />} />
                  <Route path="subjectTypes" element={<SubjectTypes/>}/>
                  <Route path="PrivacyPolicy" element={<PrivacyPolicy/>}/>
                  <Route path="leaveTypes" element={<LeaveTypes/>}/>
                  
                  {/* Process Steps For Website */}
                  {rights?.feesType === AccessCodes.feesType ? (
                    // <Route path="processStepsForWebsite" element={<ProcessSteps ProcessType={EnquiryType.Website} />} />
                    <Route
                      path="processStepsForWebsite/*"
                      element={
                        <>
                          <Routes>
                            <Route
                              path=""
                              element={
                                <>
                                  <ProcessStepSetting
                                    processType={EnquiryType.Website}
                                  />
                                </>
                              }
                            />
                            <Route
                              path="addEditProcessSteps"
                              element={
                                <>
                                  <ProcessSteps
                                    ProcessType={EnquiryType.Website}
                                  />
                                </>
                              }
                            />
                            <Route
                              path="configActions"
                              element={
                                <>
                                  <ActionConfiguration
                                    ProcessStepType={EnquiryType.Website}
                                  />
                                </>
                              }
                            />
                          </Routes>
                        </>
                      }
                    />
                  ) : null}
                  {/* Process Steps For Application */}
                  {rights?.feesType === AccessCodes.feesType ? (
                    // <Route path="processStepsForWebsite" element={<ProcessSteps ProcessType={EnquiryType.Website} />} />
                    <Route
                      path="processStepsForApplication/*"
                      element={
                        <>
                          <Routes>
                            <Route
                              path=""
                              element={
                                <>
                                  <ProcessStepSetting
                                    processType={EnquiryType.Application}
                                  />
                                </>
                              }
                            />
                            <Route
                              path="addEditProcessSteps"
                              element={
                                <>
                                  <ProcessSteps
                                    ProcessType={EnquiryType.Application}
                                  />
                                </>
                              }
                            />
                            <Route
                              path="configActions"
                              element={
                                <>
                                  <ActionConfiguration
                                    ProcessStepType={EnquiryType.Application}
                                  />
                                </>
                              }
                            />
                          </Routes>
                        </>
                      }
                    />
                  ) : null}
                </Routes>
              </>
            }
          ></Route>
        ) : null}
        {/* Setting routes */}
        {/* {rights?.settings === AccessCodes.settings ? (
          <Route path="settings/*" element={<User />} />
        ) : null} */}
        {/* <Route path="settings/course" element={<CourseSetting />} /> */}
        {/* <Route path="settings/enquiry" element={<EnquirySetting />} /> */}
        {/* <Route path="settings/student" element={<StudentSetting />} /> */}
        {/* <Route path="settings/masters" element={<MasterSetting />} /> */}
        {/* <Route path="settings/attendance" element={<AttendanceSetting />} /> */}
        {/* <Route path="settings/fees" element={<FessSetting />} /> */}
        
        <Route path="*" element={<Page404/>} />
      </Routes>
      {contextHolder}
    </adminContext.Provider>
  );
};
export default PageRoute;
