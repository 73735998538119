import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Drawer, Button, Form, Input, Spin, message } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { CategoryMaster, ClassesMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

interface AddEditClassIn {
  setTrigger: any;
  openDrawer: any;
  classId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(ClassesMaster.endPoints, ClassesMaster.prefix)

const AddEditClass = (props: AddEditClassIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType:MasterTypes.Classes,
        id: props?.classId,
        name: values?.name,
      };
      await api.POST(CategoryMaster.endPoints.save,null,val).then((res: any) => {
        if (res.status) {
          onClose();
          openNotification(
            "success",
            props?.classId
              ? "Class updated successfully"
              : "Class saved successfully"
          );
          props?.setTrigger((x: boolean) => !x);
        } else {
          openNotification("error", res.message);
        }
        setBtnLoading(false);
      });
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.classId) {
        setLoading(true);
        getClassById(props?.classId);
      }
    }
  }, [props?.openDrawer]);

  const getClassById = async (id: any) => {
    setLoading(true);
          await api.GET(ClassesMaster.endPoints.getById +"/" + id + "?", {  masterType:MasterTypes.Classes  }).then((data: IApiResponse) => {
            if (data?.result) {
                let res = data?.result;
                form.setFieldsValue({
                name: res?.name,     
                });
                setLoading(false);
            }
            else {
                message.error(data?.message)
                setLoading(false);
            }
        });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.classId ? "Edit Class" : "Add Class"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.classId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="class-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter class name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter Class Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditClass;
