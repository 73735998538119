import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { Footer } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import HTTPSCalls from "../Services/HTTPCalls";
import { IApiResponse } from "../Utilities/ApiUtility.axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/store";
import { isDefaultPassword, setUserData } from "../Store/Slice/userSlice";

const ChangePasswordModal = ({ openModel, setOpenModel }: any) => {
  const [form] = useForm();
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [hasOpened, setHasOpened] = React.useState<boolean>(false);
  const handleCancel = () => {
    setOpenModel((pre: any) => !pre);
    console.log(openModel, "state=-=-=-");
  };
  const user = useSelector((state: RootState) => state.user)
  const defaultPassword = useSelector(isDefaultPassword)
  useEffect(() => {
    if (defaultPassword && !hasOpened) {
      setOpenModel(true);
      setHasOpened(true);
    }
  }, [defaultPassword, hasOpened, setOpenModel]);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);

        const payload = {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        };

        return HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.RESET_PASSWORD + "/" + user.id, {}, payload);
      })
      .then((response: IApiResponse) => {
        if (response.result) {
          form.resetFields();
          setOpenModel(false);
          dispatch(setUserData({ ...user, isDefaultPassword: false }))
        } else {
          console.log("Failed to change password", response);
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      })
      .catch((error) => {
        console.log("Validation failed or error occurred", error);
      });
  };


  return (
    <>
      <Modal
        maskClosable={false}
        title="Change your password"
        open={openModel}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closable={!defaultPassword}
        // okButtonProps={{disabled:defaultPassword}}
        cancelButtonProps={{ disabled: defaultPassword }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[{ required: true, message: "Please enter current password" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: "Please enter new password" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
