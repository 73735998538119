import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { Breadcrumb, Button, Col, List, message, Row, Space, Spin, Table } from 'antd'
import Search from 'antd/es/input/Search'
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import AddEditMailTemplaes from './AddEditMailTemplaes'
import { ApiCalls } from '../../../ApisCaller/ApiCall'
import { MailTemplatesMaster } from '../../../ApiUrls/URLS'
import { MasterTypes } from '../../../Constant/Enums'
import { IApiResponse } from '../../../Utilities/ApiUtility.axios'
import { ColumnsType } from 'antd/es/table'
interface I_CURRICULUM {
    key: string;
    sNo: number;
    id: string;
    name: string;
  }
const MailTemplates = () => {
  const navigate = useNavigate()
  const columns: ColumnsType<I_CURRICULUM> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setMailTemplatesId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },

    // {
    //   title: " ",
    //   width: "5%",
    //   render: (_: any, record: any) => (
    //     <Row justify="end">
    //       <FormOutlined
    //         className="ca-edit-btn me-2"
    //         style={{ color: "#a5abdd" }}
    //         onClick={() => {
    //         //   setCurriculumId(record?.id);
    //           setOpenDrawer(true);
    //         }}
    //       />
    //     </Row>
    //   ),
    // },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [pageLoader, setPageLoader] = useState<boolean>(false)
  const [trigger, setTrigger] = useState<boolean>(false)
  const [allCurriculum, setAllCurriculum] = useState<I_CURRICULUM[]>();
  const [mailTemplatesId,setMailTemplatesId]=useState<string|undefined>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });
  useEffect(() => {
    setPageLoader(true);
    get_curriculum(
        (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        pagingItems?.pageSize
      );
  }, [trigger,listParams, pagingItems])
 const api = new ApiCalls(MailTemplatesMaster.endPoints, MailTemplatesMaster.prefix);
  const get_curriculum = async (start: any, length: any) => {
    await api
      .GET(MailTemplatesMaster.endPoints.getList + "?", {
        masterType: MasterTypes.MailTemplates,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllCurriculum(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };
  
  return (
    <>
      <AddEditMailTemplaes
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        SetDrawerClose={setOpenDrawer} 
        templateId={mailTemplatesId}      />
      <Row
        justify={'space-between'}
        align='middle'
        style={{ marginBottom: '6px' }}
      >
        <ArrowLeftOutlined
          style={{ color: '#1677ff' }}
          onClick={() => {
            navigate('/settings')
          }}
        />
        <Breadcrumb
          style={{ color: '#172B4D' }}
          items={[
            {
              title: 'Mail Template'
            }
          ]}
        ></Breadcrumb>
      </Row>
      <Row className='roundedCornerSmall bg-white' style={{ padding: '16px' }}>
        <Col span={24}>
          <Row justify='space-between'>
            <Button
              type='primary'
              className='cit-add-btn mb-1'
              onClick={() => {
                setMailTemplatesId(undefined);
                setOpenDrawer(true)
              }}
            >
              <PlusOutlined />
              Generate Template
            </Button>

            <Search
              size='middle'
              placeholder='Search...'
              allowClear
              className='att-search-input mb-1'
              // onSearch={(val: string) => setListParamsAndRefresh(val)}
              // onChange={(e: any) =>
              //     e.target.value === "" ? setListParamsAndRefresh("") : null
              // }
              style={{ width: 170 }}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: '12px' }}>
          <Table
            className='cit-table'
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />
            }}
            size='small'
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allCurriculum}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: 'custom-table-select',
              // current: pagingItems.currentPage,
              // pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              // total: pagingItems.totalRecords,
              pageSizeOptions: ['15', '25', '50', '100']
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey
                })
              // paging &&
              //     setPagingItems({
              //         ...pagingItems,
              //         currentPage: paging?.current ?? 1,
              //         pageSize: paging?.pageSize ?? 15,
              //     });
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default MailTemplates
