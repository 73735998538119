import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditSubjects from "./AddEditSubjects";
import dayjs from "dayjs";
import "../.../../../../Custom.css";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { SubjectsMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
interface ISubjects {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(SubjectsMaster.endPoints, SubjectsMaster.prefix);

const Subjects = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<ISubjects> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setSubjectsId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),

      // render:
    },
    {
      title: "Subject Code",
      dataIndex: "subjectCode",
      key: "SubjectCode",
      render: (text: any) => text,
    },
    {
      title: "Subject Credit",
      dataIndex: "subjectCredit",
      key: "SubjectCredit",
      render: (text: any) => text,
    },
    {
      title: "Course",
      dataIndex: "sessionCourse",
      key: "Course",
      render: (text: any) => text,
    },
    {
      title: "Session",
      dataIndex: "session",
      key: "Session",
      render: (text: any) => text,
    },
    {
      title: "Semester",
      dataIndex: "semester",
      key: "Semester",
      render: (text: any) => text,
    },
    // {
    //   title: "Module Code",
    //   dataIndex: "moduleCode",
    //   showSorterTooltip: false,
    //   sorter: true,
    //   key: "moduleCode",
    //   render: (text: any) => text,

    //   // render:
    // },
    // {
    //   title: "Course",
    //   dataIndex: "courseRef",
    //   showSorterTooltip: false,
    //   sorter: true,
    //   key: "course",
    //   render: (text: any) => text?.name,

    //   // render:
    // },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd" }}
            onClick={() => {
              setSubjectsId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [subjectsId, setSubjectsId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allSubjects, setAllSubjects] = useState<ISubjects[]>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  useEffect(() => {
    setPageLoader(true);
    get_subjects(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_subjects = async (start: any, length: any) => {
    await api
      .GET(SubjectsMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Subjects,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllSubjects(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              subjectCode: r?.subjectCode,
              subjectCredit: r?.subjectCredit,
              sessionCourse: r?.sessionCourse?.name,
              session: r?.session?.name,
              semester: r?.semester?.name
              // courseRef: r?.courseRef,
              // c: r?.createdBy.date
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      <AddEditSubjects
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        subjectsId={subjectsId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Subjects",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Button
              type="primary"
              className="cit-add-btn mb-1"
              onClick={() => {
                setSubjectsId(undefined);
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined />
              Subject
            </Button>

            <Search
              size="middle"
              placeholder="Search..."
              allowClear
              className="att-search-input mb-1"
              onSearch={(val: string) => setListParamsAndRefresh(val)}
              onChange={(e: any) =>
                e.target.value === "" ? setListParamsAndRefresh("") : null
              }
              style={{ width: 170 }}
            />
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allSubjects}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Subjects;
