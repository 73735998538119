import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Form, Modal, Row, Col, Input, Button,  Skeleton } from "antd";
import { MasterTypes } from "../../../../Constant/Enums";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { IApiResponse } from "../../../../Utilities/ApiUtility.axios";
import { adminContext } from "../../../../Common/PageRoute";
import { useQuery } from "@tanstack/react-query";
import { ApiCalls } from "../../../../ApisCaller/ApiCall";
import { AssessmentCategoryMaster } from "../../../../ApiUrls/URLS";

interface I_Props {
  isAssessModalOpen: boolean;
  setAssessIsModalOpen: Function;
  categoryId: String | undefined;
  refetch: Function;
  setCategoryId: Function;
}
const AddCategory: React.FC<I_Props> = ({
  isAssessModalOpen,
  setAssessIsModalOpen,
  categoryId,
  refetch,
  setCategoryId,
}) => {
  const { openNotification } = React.useContext(adminContext);
  const [btnLoading, setBtnLoading] = useState(false);
  const api = new ApiCalls(
    AssessmentCategoryMaster.endPoints,
    AssessmentCategoryMaster.prefix
  );
  const [form] = Form.useForm();

  const { data: categoryDetail, isLoading: categoryLoading } = useQuery({
    queryKey: ["categoryDetail", categoryId],
    queryFn: async () => {
      let data: IApiResponse = await api.GET(
        AssessmentCategoryMaster.endPoints.getById + "/" + categoryId + "?",
        {
          masterType: MasterTypes.AssessmentCategory,
        }
      );
      if (data?.result) {
        form.setFieldsValue({
          categoryName: data?.result?.name,
        });
      } else {
        console.log(data?.message);
      }
      return data?.result;
    },
    enabled:
      categoryId !== undefined && categoryId !== "" && categoryId !== null,
  });

  const saveUpdateAssementsCategory = (value: any) => {
    setBtnLoading(true);
    let assessmentDetail = {
      masterType: MasterTypes.AssessmentCategory,
      ...(categoryId && { id: categoryId }),
      name: value?.categoryName,
    };
    HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.POST_MASTER,
      {},
      {
        ...assessmentDetail,
      }
    ).then((res: IApiResponse) => {
      console.log("Post res = ", res);
      // if (res?.status && res?.message === null) {
        // onClose();
        openNotification(
          "success",
          categoryId
            ? "Assessments category updated successfully"
            : "Assessments Category saved successfully"
        );
        setAssessIsModalOpen(false);
        setBtnLoading(false);
        setCategoryId(null);
        form.resetFields();
        refetch();
      // } else {
      //   openNotification("error", res.message);
      //   setBtnLoading(false);
      // }
    });
    console.log(assessmentDetail, "Assessment values");
  };

  const handleSubmit = (val: any) => {
    console.log("submit", val);
    saveUpdateAssementsCategory(val);
  };

  const handleCancel = () => {
    form.resetFields();
    setCategoryId(null);
    setAssessIsModalOpen(false);
  };

  return (
    <Modal
      title="Add Category"
      open={isAssessModalOpen}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      {categoryLoading ? <Skeleton />:
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Form.Item
                label="Category Name"
                name="categoryName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Category Name!",
                  },
                ]}
              >
                <Input
                onKeyPress={(e) => !/[a-z]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 20 }}>
            <Col>
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                {categoryId ? "Update" : "Save"}
              </Button>
              <Button
                type="default"
                onClick={handleCancel}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>}
    </Modal>
  );
};

export default AddCategory;
