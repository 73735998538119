import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
    batchId:""
};

const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    setBatch: (state, action: PayloadAction<string>) => {
      state.batchId = action.payload;
    },
    resetBatch: (state) => {
      state.batchId = initialState.batchId;
    },
  },
});
export const selectBatchState=(state:RootState)=>state.batch.batchId
export const { setBatch,resetBatch } = batchSlice.actions;
export default batchSlice.reducer;