import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { ActiveInactiveOptions, RecordStatusType } from "../../../Constant/Enums";
import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditHoliday from "./AddEditHoliday";
import dayjs from "dayjs";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { HolidaysMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { useNavigate } from "react-router";
import { AccessCodes, IAccessCodes } from "../../../Common/Sidemenus";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface IHoliday {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(HolidaysMaster.endPoints, HolidaysMaster.prefix);

const Holiday = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<IHoliday> = [
    {
      title: "S. No",
      dataIndex: "sNo",
      width: "5%",
      render: (_, record, index) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setHolidayId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "From",
      dataIndex: "fromTo",
      key: "From",
      width: "15%",
      render: (text: any) => <p>{dayjs.utc(text?.from).format("DD MMM YYYY")}</p>,
    },
    {
      title: "To",
      dataIndex: "fromTo",
      key: "To",
      width: "15%",
      render: (text: any) => <p>{dayjs.utc(text?.to).format("DD MMM YYYY")}</p>,
    },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd" }}
            onClick={() => {
              setHolidayId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
    {
      title: "",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          {record.recordStatus === RecordStatusType.Deleted ? (
            <UndoOutlined
              className="ca-edit-btn me-2"
              style={{ color: "#a5abdd", cursor: "pointer" }}
              onClick={() => UpdateRecordStatus(record?.id, "restore")}
            />
          ) : (
            <DeleteOutlined
              className="ca-edit-btn me-2"
              style={{ color: "#a5abdd", cursor: "pointer" }}
              onClick={() => UpdateRecordStatus(record?.id, "delete")}
            />
          )}
        </Row>
      ),
    },

  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [holidayId, setHolidayId] = useState<string | undefined>();
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allHoliday, setAllHoliday] = useState<IHoliday[]>();
  const [changedStatus, setChangedStatus] = useState<string>("active");
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  useEffect(() => {
    setPageLoader(true);
    get_holiday(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_holiday = async (start: any, length: any) => {
    await api
      .GET(HolidaysMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Holidays,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
        status: changedStatus,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllHoliday(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              fromTo: r?.fromTo,
              recordStatus: r?.recordStatus,
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  let rights: IAccessCodes = JSON.parse(
    localStorage.getItem("tp-user") + ""
  )?.rights;

  const UpdateRecordStatus = async (holidayId?: string, action: string = "delete") => {
    if (!holidayId) return message.error("Holiday ID is required.");
    try {
      setPageLoader(true);

      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}?objId=${holidayId}&restore=${action}`,
        {}, {}
      );
      setTrigger(!trigger);
    } catch (error) {
      message.error("Error occurred while updating record status.");
    } finally {
      setPageLoader(false);
    }
  };

  return (
    <>
      <AddEditHoliday
        setTrigger={setTrigger}
        openDrawer={openDrawer}
        holidayId={holidayId}
        SetDrawerClose={setOpenDrawer}
      />
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Holidays",
            },
          ]}
        ></Breadcrumb>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={10}>
            <Col lg={18}>
              {rights?.holidays === AccessCodes.holidays && (
                <Button
                  type="primary"
                  className="cit-add-btn mb-1"
                  onClick={() => {
                    setHolidayId(undefined);
                    setOpenDrawer(true);
                  }}
                >
                  <PlusOutlined />
                  Holiday
                </Button>
              )}
            </Col>
            <Col lg={3}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val) => {
                  setChangedStatus(val);
                  setPagingItems((prev) => ({ ...prev, currentPage: 1 }));
                  setTrigger((prev) => !prev);
                }}
                defaultValue={"active"}
                options={ActiveInactiveOptions}
              />
            </Col>

            <Col lg={3}>
              <Search
                size="middle"
                allowClear
                placeholder="Search..."
                className="att-search-input mb-1"
                onSearch={(val: string) => setListParamsAndRefresh(val)}
                onChange={(e: any) =>
                  e.target.value === "" ? setListParamsAndRefresh("") : null
                }
                style={{ width: 170 }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ paddingTop: "12px" }}>
          <Table
            className="cit-table"
            loading={{
              spinning: pageLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            size="small"
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={allHoliday}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: ["15", "25", "50", "100"],
            }}
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                setListParams({
                  ...listParams,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey,
                });
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Holiday;
