import * as React from "react";
import { useContext, useState, useEffect } from "react";
import type { TableColumnsType } from "antd";
import { ConfigProvider, Select, Spin, Table } from "antd";
import Search from "antd/es/input/Search";
import { LoadingOutlined } from "@ant-design/icons";
import { pageSizeOption } from "../Users/UserList";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
import { meetingType, outcomeType } from "../../Constant/Enums";
import '../EnquiryLeadModel/EnquiryLead.css'
import { AssigneeAvatar } from "../../Common/AssigneeAvatar";
interface MeetingDataType {
  key: string;
  title: string;
  taskType: any;
  mettingType: any;
  assignTo: any;
  startDate: any;
  dueDate: any;
  outcomeType: any;
}

const data: MeetingDataType[] = [];
const TaskTable: React.FC<{
  type: number;
  apiData: any;
  taskContentRefresh:boolean;
}> = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [showIsApproveAll, setShowIsApproveAll] = useState(false);
  const [approveRequestList, setApproveRequestList]: any = useState([]);
  const [lodading, setLoading] = useState<boolean>(false);
  const tableLoading = {
    spinning: lodading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [listParams, setListParams] = useState<any>({
    EnquiryId: props?.apiData?.id,
    type: props?.type,
    start: 0,
    length: 10,
    search: "",
    sortCol: "",
    sortDir: "",
    note:"",
    phoneNo:"",
    callType:0,
    outComeType:0,
    subject:"",
    toMail:"",
    title:"",
    meetingType:0,
    meetingAddress:"",
    taskType:""
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  changeListParams(inputName, value);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  changeListParams(inputName);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
                // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                onChange={(e) => changeListParams(inputName, e.target?.value)}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };

  const columns: TableColumnsType<MeetingDataType> = [
    {
      title: (
        <THeader
          title={"Title"}
          inputName={"title"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "title",
      key: "",
      width: "15%",
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
      // responsive: ["xl"],
      // width: 150,
      render: (val: any) => (
        <span className=" font13">{!!val ? <AssigneeAvatar data={val} /> : "-"}</span>
      ),
    },
    {
      title: (
        <THeader
          title={"Task Type"}
          inputName={"taskType"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "taskType",
      key: "",
      width: "20%",
    },

    // {
    //   title: (
    //     <THeader
    //       title={"Meeting Type"}
    //       inputName={"mettingType"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "mettingType",
    //   key: "",
    //   width: "20%",
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Assigner"}
    //       inputName={"enqId"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "",
    //   key: "",
    //   width: "20%",
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Assign To"}
    //       inputName={"assignTo"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "assignTo",
    //   key: "",
    //   width: "20%",
    // },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "",
      width: "15%",
      // sorter: (a, b) => a.startDate.length - b.startDate.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "",
      width: "15%",
      // sorter: (a, b) => a.dueDate.length - b.dueDate.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "OutCome",
      dataIndex: "outcomeType",
      key: "",
      width: "20%",
      // sorter: (a, b) => a.outcomeType.length - b.outcomeType.length,
      // sortDirections: ["descend", "ascend"],
    },
  ];

  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item);
      });
      setApproveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsApproveAll(true)
        : setShowIsApproveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };
  const getList = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.EnquiryCommunication_GETALL + "?",
      {
        EnquiryId: listParams?.EnquiryId,
        type: listParams?.type,
        start: listParams?.start,
        length: listParams?.length,
        search: listParams?.search,
        sortCol: listParams?.sortCol,
        sortDir: listParams?.sortDir,
        note: listParams?.note,
        phoneNo:listParams?.phoneNo,
        callType:listParams?.callType,
        outComeType:listParams?.outComeType,
        subject:listParams?.subject,
        toMail:listParams?.toMail,
        title:listParams?.title,
        meetingType:listParams?.meetingType,
        meetingAddress:listParams?.meetingAddress,
        taskType:listParams?.taskType
      }
    );
    setTotalNoOfRecords(res?.result?.totalRecords);
    setDataSource(
      res?.result?.map((itm: any, indx: number) => ({
        ...itm,
        key: indx,
        title: itm?.title,
        taskType: itm?.taskType?.name,
        mettingType:itm?.mettingType > 0? meetingType[itm?.mettingType]:"-",
        // assignTo: itm?.assignTo,
        startDate: dayjs(itm?.startDate).format("DD MMM YYYY"),
        dueDate: dayjs(itm?.dueDate).format("DD MMM YYYY"),
        outcomeType:itm?.outcomeType > 0? outcomeType[itm?.outcomeType]:"-",
      }))
    );
    console.log(dataSource, "dataTask");
    if (res?.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
    console.log(res, "listAllTask");
  };

  React.useEffect(() => {
    getList();
  }, [props?.taskContentRefresh,refresh]);

  return (
    <>
      <Table
       className={"Tabel-style"}
        columns={columns}
        dataSource={dataSource}
        loading={tableLoading}
        // rowSelection={rowSelection}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            changeListParams("start", (page - 1) * pageSize);
            changeListParams("length", pageSize);
            setRefresh((x) => !x);
          },
          current: listParams.start / listParams.length + 1,
          pageSize: listParams.length,
          showSizeChanger: true,
          total: totalNoOfRecords,
          pageSizeOptions: pageSizeOption,
          position: ["bottomRight"],
        }}
        scroll={{ y: `calc(100vh - 330px)` }}
      />
    </>
  );
};

export default TaskTable;
