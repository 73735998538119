import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Drawer,
  Button,
  Space,
  Form,
  Select,
  DatePicker,
  InputNumber,
  message,
  Input,
  Upload,
} from "antd";
import form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import {
  EnquiryEndPoint,
  FeesTypeMaster,
  PaymentMethodMaster,
} from "../../ApiUrls/URLS";
import { CandidateRequestType, MasterTypes, PaidByEnum } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import { RcFile, UploadFile } from "antd/es/upload";
const masterApi = new ApiCalls(
  PaymentMethodMaster.endPoints,
  PaymentMethodMaster.prefix
);
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");
const AddEditFees = (props: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    let attachments=props?.propsData?.attachments?.map((e:any)=>{
      return {
        ...e,
        name:e?.fileName,
        thumbUrl:e?.path
      }
    })
    let data ={
      ...props?.propsData,
      attachments
    }
    let path=props?.propsData?.attachments?.map((e:any)=>e?.path)
    if (path) {
      setCurrectPath(path)
    }
    form.setFieldsValue(data);
    // form.setFieldValue("attachments",props?.propsData?.attachments)
    console.log("InsideFees",data)
  }, [props?.propsData]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [streamData, setStremData] = useState<any[]>([]);
  const [isRefund,setIsRefund]= useState<boolean>(false)
  const [currectPath, setCurrectPath] = useState<any>([]);
  const [isPaidToParul,setIsPaidToParul]= useState<boolean>(false)
  const [masterData, setMasterData] = useState<{
    batch: any;
  }>({
    batch: [],
  });
  const [masterOptions, setMasterOptions] = useState<any>({
    studentDetails: [{ label: "", value: "" }],
    feesTypeOption: [{ label: "", value: "" }],
    paymentDetails: [{ label: "", value: "" }],
  });
  const GetPaymentMethods = async () => {
    let res = await masterApi.GET(PaymentMethodMaster.endPoints.getList + "?", {
      masterType: MasterTypes.PaymentMethod,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const paymentOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      paymentDetails: paymentOptions,
    }));
  };
  const GetStudents = async () => {
    // setIsLoading(true);
    let res = await api.GET(EnquiryEndPoint.getList + "?", {
      type: CandidateRequestType.Student,
      length: 99999,
      exists:"active"
    });

    const studentOptions =
      res?.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.fullName,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      studentDetails: studentOptions,
    }));
  };
  //console.log(masterOptions,"MasterOptionsStudent");
  
  const GetFeesType = async () => {
    let res = await masterApi.GET(FeesTypeMaster.endPoints.getList + "?", {
      masterType: MasterTypes.FeesType,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const feesTypeOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
        isRefundable:item?.isRefundable||false,
        isPaidToParul:item?.isPaidToParul || false,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      feesTypeOption: feesTypeOptions,
    }));
  };
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        batch: any;
      } = {
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
      setStremData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items
      );
    } else {
      message.error(res?.message);
    }
  };

  useEffect(() => {
    GetFeesType();
    GetStudents();
    GetPaymentMethods();
    getMaster();
  }, []);

  const handleClick = async () => {
    form.validateFields().then(async (formValues: any) => {
      let val = {
        ...formValues,
        id: props?.propsData?.id,
        student: {
          id: formValues?.student?.value,
          name: formValues?.student?.label,
        },
        medium: {
          id: formValues?.medium?.value,
          name: formValues?.medium?.label,
        },
        feesType: {
          id: formValues?.feesType?.value,
          name: formValues?.feesType?.label,
        },
        stream: {
          id: formValues?.stream?.value,
          name: formValues?.stream?.label,
        },
        batch: {
          id: formValues?.batch?.value,
          name: formValues?.batch?.label,
        },
        index: props?.propsData?.key,
        IsRefundable: isRefund,
        isPaidToParul:isPaidToParul,
        attachments:
            formValues?.attachments?.length > 0
              ? formValues?.attachments?.map((item: any, index: number) => {
                return {
                    fileName: {
                      // id: item?.uid ? item?.uid : "",
                      // id: item?.fileName?.id || "",
                      ...(item?.id ? { id: item?.id } : {id:""}),
                      name: item?.name || "",
                      contentType: item?.type ||"",
                      length: item?.size || 0,
                      // path: currectPath.length>0 ? currectPath[index] : "",
                      path:item?.thumbUrl ||item?.originFileObj?.thumbUrl||""
                    },
                  };
                })
              : [],
        paidBy: formValues?.paidBy//isPaidToParul? formValues?.paidBy : PaidByEnum.CIT 
      };
      setIsLoading(true);
      await HTTPSCalls.POST(
        val?.id
          ? HTTPSCalls.ENDPOINTS.PAYMENT_UPDATE
          : HTTPSCalls.ENDPOINTS.PAYMENT_ADD,
        {},
        {
          ...val,
        }
      ).then((res) => {
        if (res?.message) {
          message.error(res?.message);
          setIsLoading(false);
          // props?.onClose(false);
        } else {
          message.success("Submitted Successfully");
          //   setReset((prev: number) => prev + 1);
          setIsLoading(false);
          props?.onClose(true);
        }
      });
      console.log(formValues,"Val",val);
      
    });
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    console.log("normFile",e)
    return e?.fileList;
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
    const handleBeforeUpload = async (file: any) => {
      if (file.type === "application/pdf" || file.type === "audio/mpeg") { 
        let base64 = (await getBase64(file as RcFile)).toString();
        file.thumbUrl = base64;
        console.log("base64", base64);
      } else {
        handelFile(file);
      }
      return false; 
    };
    const handlePreview = async (file: any) => {
      let url: any = null; 
      try {
        if (!file.url && !file.preview) {
          const fileToPreview = file.originFileObj || file;
          url = await handelFile(fileToPreview);
        }
      } catch (error) {
        console.error('Error getting base64:', error);
        url = null; 
      }
        
      console.log("onPreview", url);
      window.open(url||file?.path  , "_blank");
    };
    
    const handelFile = async (file: any) => {
      let currentFileBase64 = await getBase64(file as RcFile);
      
      setCurrectPath([...currectPath, currentFileBase64]);
      console.log("currentFileBase",currentFileBase64);
      if (file instanceof Blob) {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        return url;
      } else {
        console.error('File is not a valid Blob or File type'); 
        return null;
      }
    };
    
    
    
  return (
    <>
      <Drawer
        title="Fees"
        width={500}
        onClose={props?.onClose}
        open={true}
        extra={
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              props?.onClose();
            }}
          ></Button>
        }
        // bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "end" }}>
            <Space>
              <Button
                onClick={() => {
                  props?.onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleClick();
                }}
                type="primary"
                loading={isLoading}
              >
                Submit
              </Button>
            </Space>
          </div>
        }
      >
        <div style={{ padding: "20px" }}>
          <Form
            form={form}
            layout="horizontal"
            wrapperCol={{ md: 18 }}
            labelCol={{ md: 6 }}
            labelAlign="left"
          >
            <Form.Item
              name="student"
              rules={[
                { required: true, message: "Please input your Details!" },
              ]}
              label="Student"
            >
              <Select
                disabled={!!props?.propsData?.id}
                showSearch
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.studentDetails}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                   (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())}
              />
            </Form.Item>

            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please input your Date!" }]}
            >
              <DatePicker
                disabled={!!props?.propsData?.id}
                format="DD-MMM-YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="feesType"
              rules={[
                { required: true, message: "Please Select Fees Type!" },
              ]}
              label="Fees Type"
            >
              <Select
                showSearch
                disabled={!!props?.propsData?.id}
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.feesTypeOption}
                onSelect={(text,record)=>{
                  if (record?.isRefundable) {
                    form.setFieldValue("paidBy",PaidByEnum.CIT)
                    setIsRefund(record?.isRefundable)
                    setIsPaidToParul(false)
                  } else if (record?.isPaidToParul) {
                    setIsPaidToParul(record?.isPaidToParul)
                    setIsRefund(false)
                  } else {
                    form.setFieldValue("paidBy",PaidByEnum.Student)
                    setIsRefund(false)
                    setIsPaidToParul(false)
                  }
                }}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            {/* {isPaidToParul && */}
              <Form.Item
                name="paidBy"
                rules={[
                  { required: true, message: "Please Select Paid By" },
                ]}
                label="Paid by"
              >
                <Select
                  showSearch
                  disabled={!!props?.propsData?.id || !isPaidToParul}
                  style={{ width: "100%" }}
                  // defaultValue={PaidByEnum.CIT}
                  onChange={(e:any)=>{
                    form.setFieldValue("paidBy",e.value)
                  }
                  }
                  labelInValue
                  options={[
                    {
                      label:"Student",
                      value:PaidByEnum.Student
                    },
                    {
                      label:"CIT",
                      value:PaidByEnum.CIT
                    }
                  ]}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
              </Form.Item>
            {/* } */}
            <Form.Item
              name="medium"
              rules={[{ required: true, message: "Please input your Medium!" }]}
              label="Medium"
            >
              <Select
                disabled={!!props?.propsData?.id}
                showSearch
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.paymentDetails}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="amount"
              rules={[
                {
                  type: "number",
                  min: 0,
                  message: "Amount can't be 0 or negative",
                },
                { required: true, message: "Please input your Amount!" },
                // {
                //   validator: (rule: any, value: any) => {
                //     console.log();

                //     if (value < 0) {
                //       return Promise.reject("Amount can't be <=0")
                //     }
                //     else
                //       return Promise.resolve()
                //   }
                // }
              ]}
              label="Amount"
            >
              <InputNumber
                disabled={!!props?.propsData?.id}
                controls={false}
                name="amount"
                style={{ width: "100%" }}
                onClick={()=>console.log("record",form.getFieldsValue())}
              />
            </Form.Item>

            <Form.Item name="transactionId" label="Transaction Id">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="mediumDetails"
              rules={[
                { required: true, message: "Please input your Details!" },
              ]}
              label="Details"
            >
              <TextArea />
            </Form.Item>
            <Form.Item
                  name="attachments"
                  label="Upload"
                  
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    action="/upload.do"
                    listType="picture"
                    onPreview={handlePreview}
                    beforeUpload={(file: any) => {
                      handleBeforeUpload(file)
                      // handelFile(file);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Add Attachment</Button>
                  </Upload>
                </Form.Item>
            {/* <Form.Item name="stream" label="Stream">
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Stream"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={streamData}
              ></Select>
            </Form.Item> */}
            {/* <Form.Item name="batch" label="Batch">
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Batch"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                options={masterData?.batch}
              ></Select>
            </Form.Item> */}
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default AddEditFees;
