import { Form, FormInstance, Input, Select } from 'antd'
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { MasterTypes } from '../../../Constant/Enums';

interface IAddEditCourse{
    data:any,
    form:FormInstance<any>
}
const AddEditCourse = ({data,form}:IAddEditCourse) => {
const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  useEffect(()=>{
    if(!!data)
        form.setFieldsValue({...data})
  },[])
  return (
    <Form
            form={form}
            name="department-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
        
            style={{ padding: 16 }}
            labelAlign="left"
            requiredMark={false}
            {...formItemLayout}
          >
              <Form.Item
              label="Department"
              name="department"
              rules={[
                { required: true, message: "field is required" },
              ]}
            >
            <Select
            placeholder={"Department"}
            disabled
            />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "field is required" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Course Name"
                size="middle"
                onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            {/* <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "field is required",
                },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Department Code Name"
                size="middle"
                onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
                }
              />
            </Form.Item> */}
          </Form>
  )
}

export default AddEditCourse