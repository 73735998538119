import * as React from "react";
import { useContext, useState, useRef } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Upload,
  message,
} from "antd";
import {
  DeleteOutlined,
  EyeFilled,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import HTTPSCalls from "../../Services/HTTPCalls";
import {
  AttachmentsCategory,
  MasterTypes,
  ProcessStepsEnum,
  VerificationSubSteps,
} from "../../Constant/Enums";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import FeesSummary from "../../pages/Fees/FeesSummary";
import Rejection from "./Rejection ";
import EnrollForm from "../../Enquiry/EnrollForm";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum ViewType {
  Undefined,
  CV,
  Enroll,
}

interface IEnquiryLead {
  open: boolean;
  id: string;
  onCancel: (rec: boolean) => void;
  editRecord: any;
}

const EmailEnquiry = (props: IEnquiryLead) => {
  const [form] = Form.useForm();
  const editor = useRef();


  const [textValue, setTextValue] = React.useState<string>("");
  const [show, setShow] = React.useState<string>("");
  const [htmlContents, setHtmlContents] = useState<string>("");
  const [enrollForm, setEnrollForm] = React.useState<string>("");
  const [viewLoading, setViewLoading] = useState<boolean>(false);
  const [viewDownload, setViewDownload] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<any>({
    one: false,
    two: false,
  });
  const [masterLoading, setMasterLoading] = React.useState<boolean>(false);
  const [masterData, setMasterData] = React.useState<{
    document: any;
  }>({
    document: [],
  });
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: null,
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: null,
    },
  ]);
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const contentBody = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Mail</title>
  </head>
  <body>
      <div style="width: 60%; font-size: 16px; font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif;">
          <p><span style="font-size: 16px">Hi,  ${props?.editRecord?.fullName}</span></p>
          <p><span style="font-size: 16px">We are delighted to inform you that your enrollment in the B.Sc. Computer Science program at LSBU has been successfully processed. We believe that this course will equip you with valuable knowledge and skills essential for excelling in your chosen field.</span></p>
          <p><span style="font-size: 16px">I've attached to this email your enrollment form and invoice for the enrollment fee. If you have any questions or need any more help, please feel free to reach out to us.</span></p><p><span style="font-size: 16px">Once again, congratulations on your successful enrollment!</span></p><p><span style="font-size: 16px">--</span>
          <div style="line-height:0mm; font-size: 50px; font-family: verdana, sans-serif;">
          </div>
          <div style="line-height:0mm; margin-top: 38px; font-family: 'trebuchet ms', sans-serif; font-size: 13px;">
              <h4 style="color:#666666;">CAPSITECH INSTITUTE OF TECHNOLOGY</h4>
              <h4 style="color:#a80000; font-size: 10.6667px; font-family: Arial, sans-serif;">H-299 | 3rd Phase, RIICO Industrial Area | Boranada | Jodhpur-342012</h4>
          </div>
          <div style="line-height:1mm; margin-top: 25px;">
              <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Phone : +91 0291 294 2244 | Mob. +91 (0) 8302303370</p>
              <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Email : admissions@capsitech.com | Web URL : <a href="https://www.capsitech.com/" style="color:rgb(0, 68, 255)">https://www.capsitech.com/</a></p>
          </div>
          <div>
              <img src="https://cit.capsitech.com/images/Citlogo.svg" />
          </div>
      </div>
  </body>
</html>
 `;
  const getMaster = async () => {
    setMasterLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setMasterLoading(false);
      let obj: {
        document: any;
      } = {
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    });
  };

  React.useEffect(() => {
    getMaster();
  }, []);
  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                  {
                    file.name
                  }

                </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) { },
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };

  //-----------Enroll-Form endPoint--------------//
  const viewEnrollForm = async (enquiryId: any, type: any) => {
    setViewLoading(true);
    enquiryId = props?.id;
    type = ViewType.Enroll;
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.EnrollForm_GET + "?", {
      enqId: enquiryId,
      type: ViewType.Enroll,
    });
    if (res?.result) {
      setViewLoading(false);
      setHtmlContents(res?.result);
      setEnrollForm("enrollForm");
    } else {
      setViewLoading(false);
      setHtmlContents("");
      setEnrollForm("");
    }
  };

  //-----------View-Download endPoint--------------//
  const onDownload = async () => {
    setViewDownload(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {
        enqId: props?.id,
        type: ViewType.Enroll,
      }
    );
    if (res?.status) {
      setViewDownload(false);
    } else {
      setViewDownload(false);
    }
  };

  const onSubmit = (isValid: boolean) => {
    form.validateFields().then(async (values: any) => {
      setButtonLoading(() => {
        if (isValid) {
          return { one: false, two: true };
        } else {
          return { one: true, two: false };
        }
      });
      let formVal = {
        to: values?.mail,
        cc: "",
        subject: values?.subject,
        body: values?.body,                        
        attachments: values?.attachments ? await Promise.all(
          values?.attachments?.fileList?.map(
            async (itm: any, index: number) => {
              let currentFileBase64 = await getBase64(
                itm?.originFileObj as RcFile
              );
              let fileInfo = {
                fileType: null,
                fileName: {
                  id: "",
                  name: itm?.name,
                  contentType: itm?.type,
                  length: itm?.size,
                  path: currentFileBase64,
                },
                description: null,
                category: AttachmentsCategory.EmailInvoice,
              };
              return fileInfo;
            }
          )
        ) : [],
        enquiryId: props?.id,
      };

      var res = await sendEmail(formVal); //Send Email
      if (res?.status && !res?.message) {
        if (isValid === true) {
          await changeStep();
          props?.onCancel(true);
        } else {
          setShow("rejectionModel");
        }
      } else {
        message.error(res?.message);
      }
      setButtonLoading(() => {
        return { one: false, two: false };
      });
    });
  };

  const changeStep: any = async () => {
    let val = {
      processStep: ProcessStepsEnum.Verification,
      hasSubSteps: true,
      subSteps: VerificationSubSteps.Waiting,
      id: props?.id,
    };
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.ENQUIRY_SET_STEPS,
      {},
      val
    );
    return res.status;
  };
  const sendEmail = async (val: any) => {
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.ENQUIRY_SEND_EMAIL_INVOICE,
      {},
      { ...val }
    );
    return res;
  };
  return (
    <>
      <Modal
        title="Mail Invoice"
        open={props.open}
        maskClosable={false}
        onCancel={() => props.onCancel(false)}
        width={1080}
        style={{ top: 20 }}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={() => props.onCancel(false)}>Cancel</Button>
            <Button
              loading={buttonLoading.one}
              onClick={() => onSubmit(false)}
              type="primary"
              danger
            >
              Send & Reject
            </Button>
            <Button
              loading={buttonLoading.two}
              onClick={() => onSubmit(true)}
              type="primary"
            >
              Send & Next
            </Button>
          </Space>
        }
      >
        <Divider className="marginDivider" />

        <Form
          form={form}
          layout="vertical"
          requiredMark={true}
          initialValues={{
            mail: props?.editRecord?.contactDetail?.email,
            subject:
              "Confirmation of Enrollment in B.Sc. Computer Science Program at LSBU",
          }}
        >
          <Form.Item
            name="mail"
            label="Mail"
            className="marginbtm"
          // rules={[
          //   {
          //     required: true,
          //     type: "email",
          //     message: "Please input a valid Mail !",
          //   },
          // ]}
          >
            <Input style={{ width: "100%" }} readOnly />
          </Form.Item>
          <Form.Item
            name="subject"
            label="Subject"
            className="marginbtm"
            rules={[
              {
                required: true,
                message: "this field is required",
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="Subject" />
          </Form.Item>
          <Form.Item name="body" className="marginbtm">
            <SunEditor
              setContents={contentBody}
              getSunEditorInstance={getSunEditorInstance}
              onBlur={(val: any, ed: any) => console.log(ed)}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["bold", "underline", "italic", "strike"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],
                  // ["table", "horizontalRule", "link", "image", "video"],
                  ["print"],
                  ["removeFormat"],
                ],
                defaultTag: "div",
                font: [
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "Tahoma",
                  "Trebuchet MS",
                  "Verdana",
                ],
                fontSize: [
                  8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 36,
                ],
                defaultStyle: "font-family: Arial; font-size: 14px;", // Set default style
                showPathLabel: false,
              }}
              // setOptions={{
              //   buttonList: [
              //     ["undo", "redo"],
              //     ["font", "fontSize"],
              //     ["bold", "underline", "italic", "strike"],
              //     ["fontColor", "hiliteColor"],
              //     ["align", "list", "lineHeight"],
              //     ["outdent", "indent"],
              //     /*  ["table", "horizontalRule", "link", "image", "video"],*/
              //     ["print"],
              //     ["removeFormat"],
              //   ],
              //   defaultTag: "div",
              //   defaultStyle: "font-family:Arial,font-size:14px",
              //   showPathLabel: false,
              // }}
              height="400px"
            />
          </Form.Item>
          <Row justify={"start"} style={{ marginBottom: "10px" }}>
            <Col>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  setShow("preview&DownloadReceipt");
                }}
              >
                Preview & Download Receipt
              </Button>
            </Col>
            <Col>
              <Button
                size="small"
                type="link"
                onClick={async () => {
                  await viewEnrollForm(props?.id, ViewType.Enroll);
                  if (viewLoading === false) setEnrollForm("enrollForm");
                }}
                loading={viewLoading}
              >
                Enroll Form
              </Button>
            </Col>
          </Row>
          <Form.Item
            name={"attachments"}
            label="Add Attachment(s)"
          // rules={[
          //   {
          //     required: true,
          //     message: "this field is required",
          //   },
          // ]}
          >
            <Dragger
              {...uploadProps}
            // onPreview={async (file: UploadFile) => {
            //     console.log("testtest = ", await getBase64(
            //       file.originFileObj as any
            //     ))
            // }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      {show === "preview&DownloadReceipt" && (
        <FeesSummary
          tableData={{
            student: {
              id: props?.editRecord?.id,
              name: props?.editRecord?.name,
            },
            id: "",
          }}
          onDismiss={() => {
            setShow("");
          }}
        />
      )}
      {show === "rejectionModel" && (
        <Rejection
          processStep={props?.editRecord?.processStep?.processStep}
          open={true}
          id={props.id}
          onCancel={props.onCancel}
        />
      )}
      {enrollForm === "enrollForm" && (
        <EnrollForm
          onDismiss={() => setEnrollForm("")}
          onDownload={onDownload}
          htmlContents={htmlContents}
          viewDownload={viewDownload}
        />
      )}
    </>
  );
};

export default EmailEnquiry;
