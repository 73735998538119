import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Modal, Space, Button, Checkbox, Typography } from "antd";

const PreviewForm: React.FC<{
  isVerify: boolean;
  onDismiss: any;
  previewData?: any;
  onVerify: (isVerify: boolean) => void;
}> = (props) => {
  const { Text } = Typography;
  // const [isChecked, setIsChecked] = React.useState<boolean>(false);
  return (
    <>
      <Modal
        width={"49vw"}
        title={"Verify Document"}
        style={{ top: 10 }}
        open={true}
        footer={() => (
          <>
            <Space>
              {/* <Button> */}
              <Space align="center">
                <Checkbox
                  defaultChecked={props?.isVerify}
                  onChange={(e: any) => {
                    props?.onVerify(e.target.checked);
                    props?.onDismiss();
                    // setIsChecked(e.target.checked);
                    console.log(e.target.checked, "testCase");
                  }}
                />
                <Text strong>Verify Document</Text>
              </Space>
              {/* </Button> */}
              <Button type="primary" ghost onClick={() => props.onDismiss()}>
                Cancel
              </Button>
            </Space>
          </>
        )}
        maskClosable={false}
        onCancel={() => props.onDismiss()}
      >
        <div style={{ height: "59vh" }}>
          <iframe
            title="error"
            src={props?.previewData}
            width={"100%"}
            height={"100%"}
            style={{ border: "none" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default PreviewForm;
