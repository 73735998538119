import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Button, Col, Row, Select, Spin, Table, Typography } from "antd";
import { EditFilled, LoadingOutlined, PrinterFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

const { Text, Title } = Typography;

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

const StudentAuditList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
    
  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      width: "5%",
    },
    {
      title: "Subjects Name",
      dataIndex: "",
      key: "",
      width: "13%",
      //render: (student: any) => <span>{student?.name}</span>,
    },
    {
      title: "Subjects Code",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      align: "end",
      // width: "200px",
      // width: "10%",
      render: (name: any) => <span>₹ {name}</span>,
    },
    {
      title: "Credits / Hours",
      dataIndex: "",
      align: "end",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (name: any) => <span>₹ {name}</span>,
      // width: "10%",
    },
    {
      align: "end",
      title: "Credits Earned",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (name: any) => <span>₹ {name}</span>,
      // width: "12%",
    },
    {
      align: "end",
      title: "Final Score",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (stream: any) => <span>{stream?.name ?? "-"}</span>,
      // width: "12%",
    },
    {
      title: "Status",
      align: "right",
      dataIndex: "",
      render: (data: any, record: any) => (
        <>
          {
            <EditFilled/>
          }
        </>
      ),
    },
  ];
  return (
    <>
      <Row gutter={[25, 10]}>
        <Col lg={4}>
          <Select
            showSearch
            placeholder="Choose Student"
            optionFilterProp="label"
            style={{ width: "100%" }}
          />
        </Col>
        <Col>
          <Button type="primary">Search</Button>
        </Col>
      </Row>
      <div className="roundedCornerSmall bg-white" style={{ padding: "15px", marginTop:'10px' , }}>
        <Row >
         <Col lg={20}>
         <Text strong>Amit Singh Bhati</Text>
         </Col>
         <Col lg={3}>
         <Select
            showSearch
           // placeholder="In Progress"
            optionFilterProp="label"
            style={{ width: "100%" }}
          />
         </Col>
         <Col lg={1}>
         <PrinterFilled style={{paddingLeft:'22px', paddingTop:'8px' , cursor:'pointer'}}/>
         </Col>
        </Row>
        <Row>
         <Col lg ={5}>
         <Text type="secondary">Required Credit</Text>
         </Col>
         <Col lg ={5}>
         <Text type="secondary">Completed</Text>
         </Col>
        </Row>
        <Row>
         <Col lg ={5}>
         <Text >35</Text>
         </Col>
         <Col lg ={5}>
         <Text>40</Text>
         </Col>
        </Row>
      </div>
      <Col span={24} style={{ paddingTop: "10px" }}>
        <Table
          size="small"
          className={"Tabel-style"}
          //dataSource={}
          loading={tableLoading}
          columns={columns}
          //   pagination={{
          //     showTotal: (total, range) =>
          //       `${range[0]}-${range[1]} of ${total} items`,
          //     selectPrefixCls: "custom-table-select",
          //     current: pagingItems.currentPage,
          //     pageSize: pagingItems.pageSize,
          //     showSizeChanger: true,
          //     total: pagingItems.totalRecords,
          //     pageSizeOptions: pageSizeOption,
          //   }}
          //   onChange={(paging, filter, sort: any) => {
          //     sort?.order &&
          //       setListParams({
          //         ...listParams,
          //         sortDir: sort?.order,
          //         sortCol: sort?.columnKey,
          //       });
          //     paging &&
          //       setPagingItems({
          //         ...pagingItems,
          //         currentPage: paging?.current ?? 1,
          //         pageSize: paging?.pageSize ?? 15,
          //       });
          //   }}
          scroll={{ y: `calc(100vh - 330px)` }}
        />
      </Col>
    </>
  );
};

export default StudentAuditList;
