import { Row, Typography, Col, Table, Spin } from "antd";
import * as React from "react";
import { useContext, useState, useEffect ,useImperativeHandle} from "react";
import { ForwardedRef } from 'react';
import type { TableProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;

interface I_Props {
    subject: { id: string, name: string }; 
    schema: { id: string, name: string }; 
    totalError: boolean;
    setTotalError: Function;
    AssessmentCategory: any;
    tabWithDetail:any
    categoryLoading: boolean;
    ref?: ForwardedRef<any>; 
  }

interface DataType {
    key: string;
    name: string;
  }

  const IndivitualTab: React.FC<I_Props> = React.forwardRef(({
    subject, 
    schema, 
    categoryLoading,
    AssessmentCategory,
    totalError,
    setTotalError,
    tabWithDetail
  }, ref) => {
    const [equalWeight, setEqualWeight] = useState<any>();
    const [totalWeight, setTotalWeight] = useState<any>(0);

    const weightColumns: TableProps<DataType>["columns"] = [
        {
          title: "Category Name",
          dataIndex: "name",
          key: "name",
          width: "70%",
          render: (text) => (
            <Typography.Link color="#0D61FF">{text}</Typography.Link>
          ),
        },
        {
          title: "Weight(%)",
          dataIndex: "equalWeight",
          key: "equalWeight",
          render: (text, record: any, index) => {
            return (
              <div>
                <Paragraph
                  style={{ marginBottom: 4, marginTop: 4 }}
                  className="weightText"
                  editable={{
                    tooltip: "click to edit text",
                    onChange: (str) => {
                      if (str !== "") {
                        record.equalWeight = str;
                        setEqualWeight([...equalWeight]);
                      }
                    },
                    triggerType: ["text"],
                  }}
                >
                  {text}
                </Paragraph>
              </div>
            );
          },
        },
      ];

      
  useEffect(() => {
    let category = AssessmentCategory.map((e: any, index: number) => {
      return {
        key: index,
        name: e?.name,
        equalWeight: 25,
      };
    });
    setEqualWeight(category);

    let totalEqualWeight = category.reduce(
      (total: number, currentCategory: any) => {
        return total + currentCategory.equalWeight;
      },
      0
    );

    setTotalWeight(totalEqualWeight);
  }, []);

  useImperativeHandle(ref, () => ({
    // getData: () => ({
    // schemas: {
    //     id: schema.id,
    //     name: schema.name
    //   },
    //   subjects: {
    //     id: subject.id,
    //     name: subject.name
    //   },
    //   weights: equalWeight
    // })
    getData: () => ({
      course:{
        id:tabWithDetail?.course.id,
        name:tabWithDetail?.course.name
      },      
      semester:{
        id:tabWithDetail?.semester?.id,
        name:tabWithDetail?.semester.name
      },    
      schemas:{
        id:tabWithDetail?.schema?.id,
        name:tabWithDetail?.schema?.name
      },
        subject: {
          id: subject.id,
          name: subject.name
        },
      weightArr: equalWeight.map((e:any)=>{
        return{
          id:AssessmentCategory[e.key].id,
          name:e.name,
          weight:e.equalWeight
        }})
    })
    
  }));
  console.log(equalWeight,"tab",AssessmentCategory);
  
  useEffect(() => {
    let totalEqualWeight = equalWeight?.reduce(
      (total: number, currentCategory: any) => {
        return total + Number(currentCategory.equalWeight);
      },
      0
    );
    setTotalWeight(totalEqualWeight);

    if (totalEqualWeight === 100) {
      setTotalError(false);
    } else {
      setTotalError(true);
    }

    console.log(equalWeight);
  }, [equalWeight]);

  return (
    <div>
                    <Table
                      columns={weightColumns}
                      dataSource={
                        equalWeight ? equalWeight : AssessmentCategory
                      }
                      style={{ marginTop: 14 }}
                      loading={{
                        spinning: categoryLoading,
                        indicator: <Spin indicator={<LoadingOutlined />} />,
                      }}
                      pagination={false}
                    />
                    <Row justify={"space-between"} style={{ padding: 10 }}>
                      <Col span={17} style={{ fontWeight: "bolder" }}>
                        Total:
                      </Col>
                      <Col span={7} style={{ fontWeight: "bolder" }}>
                        <Typography.Text> {totalWeight}/100</Typography.Text>
                        {totalError && (
                          <Typography.Text
                            style={{ marginLeft: 10, color: "red" }}
                          >
                            *The Percentages should be 100 %
                          </Typography.Text>
                        )}
                      </Col>
                    </Row>
                  </div>
  )
})

export default IndivitualTab
