import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  Checkbox,
  Select,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { HttpMethods } from "../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../Services/HTTPCalls";

interface AddEditECardIn {
  eCardId: string | undefined;
  onDismiss: (rec: boolean) => void;
}

const AddEditECard = ({eCardId,onDismiss}: AddEditECardIn) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [option, setOption] = useState<any>([]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      const val = {
        ...values,
        bioMachines: values?.bioMachines?.map((itm: any) => {
          return {
            machineTitle: itm?.label,
            machineNo: itm?.value,
            isActive: false,
            isRegisterToBio: true,
            isAttSync: false,
          };
        }),
      };
      await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_ECARD,{},{...val}).then((val)=>{
        if(val.status){
          onDismiss(true)
        }else{
          message.error(val?.message);
        }
        
      })
      setBtnLoading(false)
    });
  };
  const getDeviceList = async () => {
    await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_BIOMATRIC_DEVICE_LIST + "?",
      {}
    ).then((val) => {
      setOption(
        val?.result?.map((itm: any) => {
          return { label: itm?.machineTitle, value: itm?.machineNo };
        })
      );
    });
  };
  useEffect(() => {
    getDeviceList();
  }, []);


  return (
    <>
      <Drawer
        title={eCardId ? "Edit ECard" : "Add ECard"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={()=>onDismiss(false)}
        closeIcon={false}
        open
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={()=>onDismiss(false)}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={()=>onDismiss(false)}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {eCardId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="ECard-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Card No"
              name="cardNo"
              rules={[{ required: true, message: "Please enter card no." }]}
            >
              <Input autoFocus placeholder="Enter Card Name" size="middle" />
            </Form.Item>
            <Form.Item label="Machines" name="bioMachines"
              rules={[{ required: true, message: "Please select machine" }]}
            
            >
              <Select
                mode="multiple"
                options={option}
                labelInValue
                allowClear
                size="middle"
              />
            </Form.Item>
            <Form.Item
              label="Deactive"
              name="isCardDeActive"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditECard;
