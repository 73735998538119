import * as React from "react";
import {  useState, useEffect } from "react";
import { Row, Drawer, Button, Form, Input, Spin, message } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { SubjectTypeMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import { queryClient } from "../../../App";

interface AddEditSubjectTypesIn {
  setTrigger: any;
  openDrawer: any;
  subjectId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(SubjectTypeMaster.endPoints, SubjectTypeMaster.prefix);

const AddEditSubjectTypes = (props: AddEditSubjectTypesIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = async () => {
    try {
      const values = await form.validateFields();
      console.log("Validated values:", values);
      
      setBtnLoading(true);
      
      let val = {
        ...values,
        masterType: MasterTypes.SubjectTypes,
        id: props?.subjectId,
        name: values?.name,
      };
  
      console.log("Posting data:", val);
  
      const res = await api.POST(SubjectTypeMaster.endPoints.save, null, val);
      console.log("API response:", res);
  
      if (res.status) {
        onClose();
        openNotification(
          "success",
          props?.subjectId
            ? "Subject updated successfully"
            : "Subject saved successfully"
        );
            queryClient.invalidateQueries({
      queryKey:["GetSubjectTypes"]
    });
        props?.setTrigger((x: boolean) => !x);
      } else {
        openNotification("error", res.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error during saveData:", error);
      openNotification("error", "An error occurred. Please try again.");
    } finally {
      setBtnLoading(false);
    }
  };
  
  

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.subjectId) {
        setLoading(true);
        getSubjectsById(props?.subjectId);
      }
    }
  }, [props?.openDrawer]);

  const getSubjectsById = async (id: any) => {
    setLoading(true);
    await api
      .GET(SubjectTypeMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.SubjectTypes,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            name: res?.name,
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.subjectId ? "Edit Subject types" : "Add Subject types"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.subjectId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="class-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter subject type!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter Subject Type"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditSubjectTypes;
