import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import "./EnquiryLead.css";
import {
  CaretRightOutlined,
  DeleteOutlined,
  DownOutlined,
  EnterOutlined,
  EyeFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  LoadingOutlined,
  PaperClipOutlined,
  PlusOutlined,
  RightOutlined,
  UploadOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  AttachmentsCategory,
  AttachmentsInnerCategory,
  CandidateRequestType,
  MasterTypes,
  ProcessStepsEnum,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import Upload, { RcFile } from "antd/es/upload";
import { EnquiryEndPoint } from "../../ApiUrls/URLS";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import { adminContext } from "../../Common/PageRoute";
import { ApiUtility, HttpMethods } from "../../Utilities/ApiUtility.axios";
import { IEnquiryModelType } from "../../Enquiry/EnquiryLead";
import CVForm from "../../Enquiry/CVForm";

enum ViewType {
  Undefined,
  CV,
  Enroll,
}
interface EnquiryLead {
  outerStep: ProcessStepsEnum;
  open: boolean;
  id: string;
  type: IEnquiryModelType;
  onAdd: (
    rec: boolean,
    isCV: boolean,
    isReceivedStudyContact: boolean,
    isReceivedCredentials: boolean
  ) => void;
  onCancel: React.Dispatch<{}>;
  apiData: any;
}
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");
let x: any = null;
let y: any = null;
const LSBUSteps = ({
  open,
  type,
  onCancel,
  apiData,
  id,
  onAdd,
  outerStep,
}: EnquiryLead) => {
  const [form] = Form.useForm();
  const [viewLoading, setViewLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [viewDownload, setViewDownload] = React.useState<boolean>(false);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [masterLoading, setMasterLoading] = React.useState<boolean>(false);
  const [viewCv, setViewCv] = React.useState<string>("");
  const [htmlContents, setHtmlContents] = React.useState<string>("");
  const [masterData, setMasterData] = React.useState<{
    document: any;
  }>({
    document: [],
  });
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);

  const getMaster = async () => {
    setMasterLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setMasterLoading(false);
      let obj: {
        document: any;
      } = {
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };

  //console.log("props", props);

  React.useEffect(() => {
    getMaster();
  }, []);

  //-----------Cv endPoint--------------//

  const viewCvForm = async (enquiryId: any ,type:any) => {
    setViewLoading(true);
    enquiryId = apiData?.id;
    type = ViewType.CV
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.EnrollForm_GET + "?", {
      enqId: enquiryId,
      type: ViewType.CV,
    });
    if (res?.result) {
      setViewLoading(false);
      setHtmlContents(res?.result);
      setViewCv("viewCv");
    } else {
      setViewLoading(false);
      setHtmlContents("");
      setViewCv("");
    }
  };

  
  //-----------View-Download endPoint--------------//
  const onDownload = async () => {
    setViewDownload(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {enqId: apiData?.id,
      type: ViewType.CV,}
    );
    if (res?.status) {
      setViewDownload(false);
    } else {
      setViewDownload(false);
    }
  };
  // useEffect(()=>{

  // },[refresh])
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (val: any) => {
        console.log(val, "wertyuio");
        console.log(form.getFieldValue("attachments"), "att")
        console.log(form.getFieldValue("cvAtt"), "cvATt")
        console.log(form.getFieldValue("studyAtt"), "studyAtt")
        let attList =
          form.getFieldValue("attachments")?.map((item: any, index: number) => {
            return {
              ...item,
              fileType: {
                id: item?.fileType?.value,
                name: item?.fileType?.label,
              },
            };
          });

        let cvList =
          type === IEnquiryModelType.lsbuAddCV
            ?
            await Promise.all(
              val?.cvAtt?.fileList?.map(async (itm: any, index: number) => {
                let currentFileBase64 = await getBase64(
                  itm?.originFileObj as RcFile
                );
                let fileInfo = {
                  fileType: null,
                  fileName: {
                    id: "",
                    name: itm?.name,
                    contentType: itm?.type,
                    length: itm?.size,
                    path: currentFileBase64,
                  },
                  description: null,
                  category: AttachmentsCategory.Lsbu,
                  innerCategory: AttachmentsInnerCategory.CV,
                };
                return fileInfo;
              })
            )
            :
            [form.getFieldValue("cvAtt")]

        let studyAttList =
          type === IEnquiryModelType.lsbuAddReceivedStudyContact
            ?
            await Promise.all(
              val?.studyAtt?.fileList?.map(async (itm: any, index: number) => {
                let currentFileBase64 = await getBase64(
                  itm?.originFileObj as RcFile
                );
                let fileInfo = {
                  fileType: null,
                  fileName: {
                    id: "",
                    name: itm?.name,
                    contentType: itm?.type,
                    length: itm?.size,
                    path: currentFileBase64,
                  },
                  description: null,
                  category: AttachmentsCategory.Lsbu,
                  innerCategory: AttachmentsInnerCategory.StudyContact,
                };
                return fileInfo;
              })
            )
            :
            [form.getFieldValue("studyAtt")]

        // console.log("otherAttVal", otherAttVal);
        let finalVal = null;

        console.log("attachmentsList = ", attList)
        console.log("cvList = ", cvList)
        console.log("studyAttList = ", studyAttList)
        if (attList) {
          if (finalVal) {

            finalVal = [...finalVal, ...attList];

            console.log("IF attachmentsList", finalVal)
          }
          else {

            finalVal = [...attList];
            console.log("Else attachmentsList", finalVal)

          }
        }
        if (cvList) {
          if (finalVal) {

            finalVal = [...finalVal, ...cvList];

            console.log("IF cvList", finalVal)
          }
          else {

            finalVal = [...cvList];
            console.log("Else cvList", finalVal)

          }
        }
        if (studyAttList) {
          if (finalVal) {

            finalVal = [...finalVal, ...studyAttList];

            console.log("IF studyAttList", finalVal)
          }
          else {

            finalVal = [...studyAttList];
            console.log("Else studyAttList", finalVal)

          }
        }
        let list = finalVal?.filter((x:any)=>!!x);
        console.log("finalVal = ", typeof finalVal, finalVal);

        setLoading(true);
        setModelLoading(true);
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.ENQUIRY_UPDATE_LIST + "?",
          {
            id: apiData?.id,
            fieldName: "Attachments",
            valueType: "",
          },
          list
        );
        setLoading(false);
        if (response?.status) {
          message.success("Attachment Submitted");
          onAdd(
            true,
            type === IEnquiryModelType.lsbuAddCV,
            type === IEnquiryModelType.lsbuAddReceivedStudyContact,
            type === IEnquiryModelType.lsbuReceivedCredentials
          );
        } else {
          message.error(response?.message);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, index: number) => {
    let prevAtt = [...attachmentList];

    let currentFileBase64 = await getBase64(file as RcFile);

    let fileInfo = {
      id: "",
      name: file?.name,
      contentType: file?.type,
      length: file?.size,
      path: currentFileBase64,
    };

    prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

    setAttachmentList(prevAtt);
  };


  React.useEffect(() => {
    form.resetFields();
    if (apiData) {
      x = {
        attachments: apiData?.attachments
          ?.filter(
            (itm: any) => itm?.category === AttachmentsCategory.StudentDocuments
          )
          ?.map((itm: any, indx: any) => {
            return {
              key: indx,
              ...itm,
              fileType: {
                label: itm?.fileType?.name,
                value: itm?.fileType?.id,
              },
            };
          }),
        cvAtt: apiData?.attachments
          ?.filter(
            (itm: any) =>
              itm?.category === AttachmentsCategory.Lsbu &&
              itm?.innerCategory === AttachmentsInnerCategory.CV
          )
          ?.map((itm: any, indx: any) => {
            return {
              key: indx,
              ...itm,
              // fileName: {
              //   id: itm?.fileName?.id,
              //   name: itm?.fileName?.name,
              //   contentType: itm?.fileName?.contentType,
              //   length: itm?.fileName?.length,
              //   path: itm?.fileName?.path,
              // },
              category: AttachmentsCategory.Lsbu,
              innerCategory: AttachmentsInnerCategory.CV,
            };
          }),
        studyAtt: apiData?.attachments
          ?.filter(
            (itm: any) =>
              itm?.category === AttachmentsCategory.Lsbu &&
              itm?.innerCategory === AttachmentsInnerCategory.StudyContact
          )
          ?.map((itm: any, indx: any) => {
            return {
              key: indx,
              ...itm,
              // fileName: {
              //   id: itm?.fileName?.id,
              //   name: itm?.fileName?.name,
              //   contentType: itm?.fileName?.contentType,
              //   length: itm?.fileName?.length,
              //   path: itm?.fileName?.path,
              // },
              category: AttachmentsCategory.Lsbu,
              innerCategory: AttachmentsInnerCategory.StudyContact,
            };
          }),
      };
      y = {
        ...x,
        cvAtt: x?.cvAtt ? x?.cvAtt[0] : null,
        studyAtt: x?.studyAtt ? x?.studyAtt[0] : null,
      }

      form.setFieldsValue({ ...y });

      setModelLoading(false);
    }
  }, []);

  console.log("yyyyy =  ", y);

  const collapseItems: any = [
    {
      key: "1",
      label: "LSBU - Add CV",
      children: (
        <Card>
          <Form form={form} requiredMark={false}>
            <Form.Item name={"cvAtt"}>
              <Upload beforeUpload={() => false} maxCount={1}>
                {
                  !(y?.cvAtt?.fileName?.path) ?
                    <Tooltip title="Upload CV">
                      <Button icon={<UploadOutlined />}>{"Upload CV"}</Button>
                    </Tooltip>
                    :
                    null
                }

                {
                  y?.cvAtt?.fileName?.id ? (
                    <Button
                      type="link"
                      onClick={() =>
                        window.open(y?.cvAtt?.fileName?.path, "_blank")
                      }
                      icon={<PaperClipOutlined />}
                    >{y?.cvAtt?.fileName?.name}</Button>
                  ) : null}

              </Upload>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      key: "2",
      label: "LSBU - Add Received Study Contact",
      children: (
        <Card>
          <Form form={form} requiredMark={false}>
            <Form.Item name={"studyAtt"}>
              <Upload beforeUpload={() => false} maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload Study Contact</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      key: "3",
      label: "Basic Detail Attachments",
      children: (
        <div>
          <Card style={{ marginTop: "10px" }} size="small">
            <Form form={form} requiredMark={false}>
              <Form.Item>
                <Form.List name={"attachments"}>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(
                        ({ key, name, ...restField }, index, attachments) => {
                          return (
                            <div key={index}>
                              <Row gutter={[5, 4]} key={index}>
                                <Col lg={10} xl={10}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "description"]}
                                    label={index === 0 ? "" : ""}
                                  >
                                    <Input.TextArea
                                      readOnly
                                      placeholder="Description"
                                      style={{ width: "100%" }}
                                      autoSize={{
                                        minRows: 1,
                                        maxRows: 6,
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={6} xl={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "fileType"]}
                                    label={index === 0 ? "" : ""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select Doc Type",
                                      },
                                    ]}
                                  >
                                    <Select
                                      disabled
                                      placeholder="Select Doc Type"
                                      options={masterData?.document}
                                      labelInValue
                                      allowClear
                                      popupMatchSelectWidth={true}
                                      filterOption={(input, option) =>
                                        (option?.label?.toString() ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={5} xl={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "fileName"]}
                                    label={index === 0 ? "" : ""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Choose Doc",
                                      },
                                    ]}
                                  >
                                    <Upload
                                      disabled
                                      defaultFileList={[]}
                                      beforeUpload={(file: any) => {
                                        handelFile(file, index);
                                        return false;
                                      }}
                                      maxCount={1}
                                      style={{ width: "100%" }}
                                    >
                                      <Tooltip
                                        title={
                                          form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.name
                                        }
                                      >
                                        <Button
                                          icon={<UploadOutlined />}
                                          style={{ width: "140px" }}
                                        >
                                          {form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.id
                                            ? form.getFieldsValue([
                                              "attachments",
                                            ])?.attachments[name]?.fileName
                                              ?.name?.length > 10
                                              ? form
                                                .getFieldsValue([
                                                  "attachments",
                                                ])
                                                ?.attachments[
                                                name
                                              ]?.fileName?.name?.substr(
                                                0,
                                                5
                                              ) + "..."
                                              : form.getFieldsValue([
                                                "attachments",
                                              ])?.attachments[name]?.fileName
                                                ?.name
                                            : "Upload"}
                                        </Button>
                                      </Tooltip>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <Space.Compact>
                                    {/* <Tooltip title="Delete">
                                      <Button
                                        disabled
                                        onClick={() => {
                                          remove(name);
                                        }}
                                        icon={<DeleteOutlined />}
                                      />
                                    </Tooltip> */}
                                    {form.getFieldsValue(["attachments"])
                                      ?.attachments[name]?.fileName?.id ? (
                                      <Tooltip title="Preview">
                                        <Button
                                          onClick={() =>
                                            window.open(
                                              `${form.getFieldsValue([
                                                "attachments",
                                              ])?.attachments[name]?.fileName
                                                ?.path
                                              }`,
                                              "_blank"
                                            )
                                          }
                                          icon={<EyeOutlined />}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Space.Compact>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                      {/* <Form.Item className="m-0 pb-4">
                        <Button
                          type="primary"
                          ghost
                          icon={<PlusOutlined />}
                          onClick={() => add()}
                        >
                          Attachment
                        </Button>
                      </Form.Item> */}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Form>
          </Card>
        </div>
      ),
      // style: panelStyle,
    },
  ];
  return (
    <>
      <Modal
        open={open}
        maskClosable={false}
        onCancel={onCancel}
        width={850}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        }
      >
        <Spin
          spinning={modelLoading || masterLoading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
           <Row>
          <Col style={{paddingLeft:'20px'}}>
            <Button
              size="small"
              type="default"
              onClick={async () => {
                await viewCvForm(apiData?.id, ViewType.CV)
                if(viewLoading===false)
                  setViewCv("viewCv");
              }}
              loading={viewLoading}
              icon={<EyeFilled/>}
            >
              View CV
            </Button>
          </Col>
        </Row>
          <div
            style={{
              maxHeight: "68vh",
              overflowY: "auto",
            }}
          >
            <Collapse
              bordered={false}
              defaultActiveKey={
                type === IEnquiryModelType.lsbuAddCV ? "1" : "2"
              }
              expandIcon={({ isActive }) => (
                <RightOutlined
                  rotate={isActive ? 90 : 0}
                  style={{ color: "#1677FF" }}
                />
              )}
              ghost
              items={
                type === IEnquiryModelType.lsbuAddCV
                  ? collapseItems?.filter((item: any) => item?.key !== "2")
                  : collapseItems
              }
            />
          </div>
        </Spin>
      </Modal>
      {viewCv === "viewCv" && (
        <CVForm onDismiss={() => setViewCv("")} htmlContents={htmlContents}  onDownload={onDownload} viewDownload={viewDownload} />
      )}
    </>
  );
};

export default LSBUSteps;
