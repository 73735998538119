import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Drawer, Button, Form, Input, Spin, message, Checkbox } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { RoleMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

interface AddEditRoleIn {
    setTrigger: any;
    openDrawer: any;
    roleId: string | undefined;
    SetDrawerClose: any;
}
const api = new ApiCalls(RoleMaster.endPoints, RoleMaster.prefix)

const EditRole = (props: AddEditRoleIn) => {
    const [form] = Form.useForm();
    const { openNotification } = React.useContext(adminContext);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            lg: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            lg: { span: 24 },
        },
    };
    const saveData = () => {
        form.validateFields().then(async (values: any) => {
            setBtnLoading(true);
            let val = {
                ...values,
                masterType: MasterTypes.Role,
                id: props?.roleId,
                name: values?.name,
            };
            await api.POST(RoleMaster.endPoints.save, null, val).then((res: any) => {
                if (res.status) {
                    onClose();
                    openNotification(
                        "success",
                        props?.roleId
                            ? "Role updated successfully"
                            : "Role saved successfully"
                    );
                    props?.setTrigger((x: boolean) => !x);
                } else {
                    openNotification("error", res.message);
                }
                setBtnLoading(false);
            });
        });
    };

    useEffect(() => {
        if (props?.openDrawer) {
            if (props?.roleId) {
                setLoading(true);
                getGenderById(props?.roleId);
            }
        }
    }, [props?.openDrawer]);

    const getGenderById = async (id: any) => {
        setLoading(true);
        await api.GET(RoleMaster.endPoints.getById + "/" + id + "?", { masterType: MasterTypes.Role }).then((data: IApiResponse) => {
            if ( data?.result) {
                let res = data?.result
                form.setFieldsValue({
                    name: res?.name,
                });
                setLoading(false);
            }
            else {
                message.error(data?.message)
                setLoading(false);
            }
        });
    };
    const onClose = () => {
        props?.SetDrawerClose(false);
        form.resetFields();
    };

    return (
        <>
            <Drawer
                title={props?.roleId ? "Edit Role" : "Add Role"}
                placement="right"
                width={500}
                maskClosable={false}
                onClose={onClose}
                closeIcon={false}
                open={props?.openDrawer}
                destroyOnClose={true}
                extra={
                    <CloseOutlined
                        style={{ fontSize: "16px" }}
                        className=""
                        onClick={onClose}
                    />
                }
                footer={
                    <Row justify="end">
                        <Button
                            onClick={onClose}
                            disabled={btnLoading}
                            className="ttp-btn-light btn-m-sm"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="btn-m-sm"
                            loading={btnLoading}
                            onClick={() => saveData()}
                        >
                            {props?.roleId ? "Update" : "Save"}
                        </Button>
                    </Row>
                }
            >
                <Spin
                    spinning={loading}
                    indicator={<LoadingOutlined />}
                    style={{ height: "100%" }}
                >
                    <Form
                        form={form}
                        name="gender-form"
                        className="DrawerPadding"
                        colon={false}
                        autoComplete="off"
                        style={{ padding: 16 }}
                        {...formItemLayout}
                        labelAlign="left"
                        requiredMark={false}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Please enter role" }]}
                        >
                            <Input
                                autoFocus
                                placeholder="Enter Role"
                                readOnly={props?.roleId ? true : false}
                            // onInput={(e: any) =>
                            // (e.target.value =
                            //     e.target.value.length > 1
                            //         ? e.target.value
                            //         : e.target.value.toUpperCase())
                            // }
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
};

export default EditRole;
