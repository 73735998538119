import React, { useEffect, useState } from "react";
import { CloseOutlined, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Space, Form, Select, Input, message } from "antd";
import HTTPSCalls from "../../../Services/HTTPCalls";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 24 },
  },
};

const SubjectAllocationAdd: React.FC<{
  openDrawer: boolean;
  setOpenDrawer: Function;
}> = (props) => {
  const [form] = Form.useForm();
  const [departMentId, setDepartMentId] = useState<string>("");
  const [courseId, setCourseId] = useState<string>("");
  const [batchId, setBatchId] = useState<string>("");
  const [semesterId, setSemesterId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [departMentData, setDepartMentData] = useState<any[]>([]);
  const [courseData, setCourseData] = useState<any[]>([]);
  const [batchData, setBatchData] = useState<any[]>([]);
  const [sectionData, setSectionData] = useState<any[]>([]);
  const [semesterData, setSemesterData] = useState<any[]>([]);
  const [teacherData, setTeacherData] = useState<any[]>([]);
  const [subjectData, setSubjectData] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);


  const getDetails = async () => {
    setLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_LIST,
      {},
      {
        departMentId,
        courseId,
        batchId,
        semesterId
      }
    );
    if (res?.result) {
      setLoading(true);

      let departmentData = res?.result?.departments?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      setDepartMentData(departmentData);
      setLoading(false);
      let courseData = res?.result?.courses?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      setCourseData(courseData);
      setLoading(false);
      let batchData = res?.result?.batches?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setBatchData(batchData)
      setLoading(false);
      let sectionData = res?.result?.sections?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setSectionData(sectionData);
      setLoading(false);

      let teacherData = res?.result?.teachers?.map((itm: any) => {
        return {
          value: itm?.teacher?.id,
          label: itm?.teacher?.name
        }
      })
      setTeacherData(teacherData);
      setLoading(false);
      let semesterData = res?.result?.semesters?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setSemesterData(semesterData);
      let subjectData = res?.result?.subjects?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }

      })
      setSubjectData(subjectData);
      setLoading(false);
    } else {
      console.log(res.errors);
    }
  };


  useEffect(() => {
    getDetails();

  }, [departMentId, courseId, batchId, semesterId]);

  //console.log("TeacherDAta", userList);

  const onhandleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        let formVal = {
          ...values,
          department: {
            id: values?.department?.value,
            name: values?.department?.label
          },
          course: {
            id: values?.course?.value,
            name: values?.course?.label
          },
          batch: {
            id: values?.batch?.value,
            name: values?.batch?.label
          },
          teacher: {
            id: values?.teacher?.value,
            name: values?.teacher?.label
          },
          details: values?.details?.length > 0 ? values?.details?.map((item: any) => {
            return {
              section: {
                id: item?.section?.value,
                name: item?.section?.label
              },
              semester: {
                id: item?.semester?.value,
                name: item?.semester?.label
              },
              subjects: item?.subjects.map((el: any) => ({
                id: el?.value,
                name: el?.label
              }))
            }
          }) : []
        }
        console.log(formVal, 'ValueTest');
        setButtonLoading(true);
        let res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.SUBJECTALLOCATION,
          {},
          formVal
        );
        if (res.status) {
          form.resetFields();
          message.success("SubjectAllocate")
          setButtonLoading(false);
          props.setOpenDrawer(false);
        }
        else {
          message.error(res.message);
          setButtonLoading(false)
        }
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  };
  return (
    <>
      <Drawer
        title="Subject Alloction"
        onClose={() => props.setOpenDrawer(false)}
        open={props.openDrawer}
        width={700}
        maskClosable={false}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className="cusror"
            onClick={() => props.setOpenDrawer(false)}
          />
        }
        footer={
          <Row justify="end">
            <Space size={10}>
              <Button onClick={() => props.setOpenDrawer(false)}>Cancel</Button>
              <Button
                type="primary"
                className="btn-m-sm"
                onClick={onhandleSubmit}
                loading={buttonLoading}
              >
                {/* {props?.batchId ? "Update" : "Save"} */}
                Save
              </Button>
            </Space>
          </Row>
        }
      >
        <Form
          form={form}
          name="batch-form"
          className="DrawerPadding"
          colon={false}
          autoComplete="off"
          style={{ padding: 16 }}
          {...formItemLayout}
          labelAlign="left"
          requiredMark={false}
        >
          <Form.Item
            label="Department"
            name="department"
            rules={[{ required: true, message: "Please select Department!" }]}
          >
            <Select
              placeholder="Choose Department"
              optionFilterProp="children"
              labelInValue={true}
              // labelInValue

              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={loading}
              options={departMentData}
              onSelect={(e: any) => {
                setDepartMentId(e.value);
                console.log(courseId);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Course"
            name="course"
            rules={[{ required: true, message: "Please select Course!" }]}
          >
            <Select
              placeholder="Choose Course"
              optionFilterProp="children"
              labelInValue={true}
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={loading}
              options={courseData}
              onSelect={(e: any) => {
                setCourseId(e.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Batch"
            name="batch"
            rules={[{ required: true, message: "Please select Semester!" }]}
          >
            <Select
              placeholder="Choose Batch"
              optionFilterProp="children"
              labelInValue={true}
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={batchData}
              onSelect={(e: any) => {
                setBatchId(e.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Teacher"
            name="teacher"
            rules={[{ required: true, message: "Please select Semester!" }]}
          >
            <Select
              placeholder="Select Teacher"
              optionFilterProp="children"
              maxTagCount={"responsive"}
              labelInValue={true}
              allowClear
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={teacherData}
            />
          </Form.Item>

          <Form.List name="details">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Row gutter={[10, 10]}>
                      <Col lg={7}>
                        <Form.Item
                          {...restField}
                          name={[name, "section"]}
                        // rules={[
                        //   { required: true, message: "Missing Section" },
                        // ]}
                        >
                          <Select
                            placeholder="Select Section"
                            optionFilterProp="children"
                            labelInValue={true}

                            popupMatchSelectWidth={true}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={sectionData}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7}>
                        <Form.Item
                          {...restField}
                          name={[name, "semester"]}
                        // rules={[
                        //   { required: true, message: "Missing Semester" },
                        // ]}
                        >
                          <Select
                            placeholder="Select Semester"
                            optionFilterProp="children"
                            labelInValue={true}
                            popupMatchSelectWidth={true}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={semesterData}
                            onSelect={(e: any) => {
                              setSemesterId(e.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7}>
                        <Form.Item
                          {...restField}
                          name={[name, "subjects"]}
                        // rules={[
                        //   { required: true, message: "Missing Subject" },
                        // ]}
                        >
                          <Select
                            placeholder="Select Subject"
                            optionFilterProp="children"
                            mode="multiple"
                            labelInValue={true}
                            loading={loading}
                            popupMatchSelectWidth={true}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={subjectData}

                          />
                        </Form.Item>
                      </Col>
                      <Col lg={2}>
                        {" "}
                        <DeleteTwoTone onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="link"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Section Semester & Subject
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

export default SubjectAllocationAdd;
