import { Button, Drawer, Form, Row, Select, Space, Spin, message } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import "../../../../Common/Common.css";
import { CloseOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { EnquiryType } from "../../../../Constant/Enums";
type Props = {};

const ApplicationActions = (props: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [outerStepsOptions, setOuterStepsOptions] = useState([]);
  const [innerStepsOptions, setInnerStepsOptions] = useState([]);
  const [pageLoader, setPageLoader] = useState<boolean>(false);

  const [form] = Form.useForm();
  useEffect(() => {
    getList();
  }, []);
  const onClose = () => {
    form.resetFields();
    setOpenDrawer(false);
  };
  const getList = async () => {
    const res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_PROCESS_STEPS + "?",
      { processType: EnquiryType.Application }
    );
    if (/*res?.errors === null &&*/ res?.result) {
      setOuterStepsOptions(
        res?.result?.items.map((r: any, i: any) => ({
          label: r?.name,
          value: r?.id,
          opt: r?.steps,
          //   key: i,
          //   sno: r?.sno,
          //   id: r?.id,
          //   name: r?.name,
          //   orderNumber:r?.orderNumber,
          //   description:r?.description,
          //   color:r?.color
          // c: r?.createdBy.date
        }))
      );
      setPageLoader(false);
    } else {
      message.error(res?.message);
      setPageLoader(false);
    }
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      // setBtnLoading(true);
      // let val = {
      //     ...values,
      //     masterType: MasterTypes.Role,
      //     id: props?.roleId,
      //     name: values?.name,
      // };
      // await api.POST(RoleMaster.endPoints.save, null, val).then((res: any) => {
      //     if (res.status) {
      //         onClose();
      //         openNotification(
      //             "success",
      //             props?.roleId
      //                 ? "Role updated successfully"
      //                 : "Role saved successfully"
      //         );
      //         props?.setTrigger((x: boolean) => !x);
      //     } else {
      //         openNotification("error", res.message);
      //     }
      //     setBtnLoading(false);
      // });
    });
  };
  return (
    <Spin spinning={pageLoader}>
      <Space>
        <Button
          type="link"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          Make Enquiry Candidate to Student
        </Button>

        <Drawer
          title={"Make Enquiry Candidate to Student"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          className="CitDetailsDrawer"
          placement="right"
          width={500}
          maskClosable={false}
          closeIcon={false}
          destroyOnClose={true}
          extra={
            <CloseOutlined
              style={{ fontSize: "16px" }}
              className=""
              onClick={onClose}
            />
          }
          footer={
            <Row justify="end">
              <Button
                onClick={onClose}
                disabled={btnLoading}
                className="ttp-btn-light btn-m-sm"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="btn-m-sm"
                loading={btnLoading}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </Row>
          }
        >
          <Form name="form" form={form}>
            <Form.Item
              name={"outerStepId"}
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Select
                labelInValue
                options={outerStepsOptions}
                placeholder={"Select Outer Step"}
                onSelect={(val: any, options: any) => {
                  setInnerStepsOptions(
                    options?.opt?.map((itm: any) => ({
                      label: itm?.name,
                      value: itm?.id,
                    }))
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              name={"innerStepId"}
              rules={[
                {
                  required: !!innerStepsOptions,
                  message: "this field is required",
                },
              ]}
            >
              <Select
                options={innerStepsOptions}
                placeholder={"Select Outer Step"}
              />
            </Form.Item>
          </Form>
        </Drawer>
      </Space>
    </Spin>
  );
};
export default ApplicationActions;
