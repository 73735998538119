import { DownloadOutlined } from '@ant-design/icons'
import { Modal, Space, Button } from 'antd'
import * as React from "react";
import { useContext, useState, useEffect } from "react";

const FeesViewPdf = ({onDownloadLoadingBulk, onDownload,onDismiss, htmlContents,onDownloadBulk }: any) => {
  return (
    <>
      <Modal
        width={"40vw"}
        title={"Preview"}
        style={{top:10}}
        open={true}
        footer={() => <>
          <Space>
            <Button type="primary" ghost onClick={()=>onDismiss()}>Cancel</Button>
            <Button icon={<DownloadOutlined />} type="primary" loading={onDownloadLoadingBulk} ghost onClick={()=>onDownloadBulk()} >Download</Button>
          </Space>
        </>}
        maskClosable={false}
        onCancel={()=>onDismiss()}
      >
        < >

          <div
            // style={{ maxHeight: "750px", overflowY: "auto" }}
            dangerouslySetInnerHTML={{ __html: htmlContents }} />
        </>
      </Modal>

    </>
  )
}

export default FeesViewPdf