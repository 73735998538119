import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Typography,
  Col,
  Button,
  Table,
  Space,
  Dropdown,
  Drawer,
  Form,
  Spin,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { GetProp, TableProps, UploadFile, UploadProps } from "antd";
import type { MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddAssessments from "./AddAssessments";
import { useForm } from "antd/es/form/Form";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { adminContext } from "../../../../Common/PageRoute";
import { RcFile } from "antd/es/upload";
import { useQuery } from "@tanstack/react-query";

//#region And table
interface DataType {
  key: string;
  name: string;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <Typography.Link>Edit</Typography.Link>,
  },
  {
    key: "2",
    label: <Typography.Link>Delete</Typography.Link>,
  },
];

//#endregion

const DisplayAssements: React.FC = () => {
  const { openNotification } = React.useContext(adminContext);
  const [open, setOpen] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);
  const [assessmentsId, setAssessmentsId] = useState<any>();
  const [currectPath, setCurrectPath] = useState<any>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });

  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [start, setStart] = useState<any>(0);
  const [length, setLength] = useState<any>(15);
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setAssessmentsId(null);
  };

  const inviditualAssessment = (item: any) => {
    // handlePreview(item.attachments.fileName)
    showDrawer();
    setAssessmentsId(item.id);
    form.setFieldsValue({
      assessmentName: item.assessmentName,
      category: {
        label: item.category.name,
        value: item.category.id,
      },
      marksIn: item.marksIn,
      totalMarks: item.totalMarks,
      passingMarks: item.passingMarks,
      countScore: item.countScore,
      gradingScale: {
        label: item.gradingScale,
        value: item.gradingScale,
      },
      appliedTo: item.appliedTo,
      onlineSubmission: item.onlineSubmission,
      description: item.description,
      attachments: item?.attachments
    });
    // item?.attachments?.length >0 &&
    // setPreviewImage(item?.attachments[0]?.fileName?.path)
    console.log('item',item?.attachments[0].fileName)
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Assessment Name",
      dataIndex: "assessmentName",
      key: "assessmentName",

      // width: "70%",
      render: (text, record) => (
        <Typography.Link
          onClick={(e) => {
            console.log(record, "record");
            inviditualAssessment(record);
          }}
        >
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Applied to",
      dataIndex: "appliedTo",
      key: "applied",
    },
    {
      title: "Grade Scale Type",
      dataIndex: "gradingScale",
      key: "gradeScaleType",
    },
    {
      title: "Count in Overall",
      dataIndex: "countScore",
      key: "countInOverall",
    },
    {
      title: "Total Marks",
      dataIndex: "totalMarks",
      key: "totalMarks",
    },
    {
      title: "Passing Marks",
      dataIndex: "passingMarks",
      key: "passingMarks",
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_) => (
        <Space size="middle">
          <Dropdown menu={{ items }} placement="bottomRight">
            <BsThreeDotsVertical />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const {
    data: assessment,
    isLoading: assessmentLoading,
    refetch,
  } = useQuery({
    queryKey: ["Assessments", start, length],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_ASSESSMENTS_DATA + "?",
          {
            start: start,
            length: length,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          console.log(res?.result?.items, "assessment");
          return res?.result?.items.map((item: any) => ({
            ...item,
            categoryName: item.category.name,
            key: item.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, index: number) => {
    let currentFileBase64 = await getBase64(file as RcFile);
    setCurrectPath(currentFileBase64);
  };

  const handleSubmit = () => {
    console.log("id",assessmentsId)
    form.validateFields().then(async (val: any) => {
      setBtnLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_ASSESSMENTS_DATA,
        {},
        {
          ...(assessmentsId && { id: assessmentsId }),
          category: {
            id: val.category.value,
            name: val.category.label,
          },
          assessmentName: val.assessmentName,
          marksIn: val.marksIn,
          totalMarks: val.totalMarks,
          passingMarks: val.passingMarks,
          countScore: val.countScore,
          gradingScale: val.gradingScale.value,
          appliedTo: val.appliedTo,
          onlineSubmission: val.onlineSubmission,
          description: val.description,
          attachments:
            val?.attachments?.length > 0
              ? val?.attachments?.map((item: any, index: number) => {
                return {
                    fileName: {
                      // id: item?.uid ? item?.uid : "",
                      // id: item?.fileName?.id || "",
                      ...(assessmentsId ? { id: item?.fileName?.id } : {id:""}),
                      name: item?.name ? item?.name : "",
                      contentType: item?.type ? item?.type : "",
                      length: item?.size ? item?.size : 0,
                      path: currectPath ? currectPath : "",
                    },
                  };
                })
              : [],
        }
      );

      if (res.result) {
        openNotification(
          "success",
          assessmentsId
            ? "Assessments category updated successfully"
            : "Assessments Category saved successfully"
        );
        setBtnLoading(false);
        onClose();
        refetch();
      } else {
        openNotification("error", "Some thing is wrong");
      }
      console.log(val, "AssessmentInput");
    });
  };

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <>
      <div>
        <Row justify={"space-between"} style={{ alignItems: "baseline" }}>
          <Col>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Assessments
            </Typography.Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ fontWeight: 500 }}
              onClick={showDrawer}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={assessment}
          style={{ marginTop: 14 ,minHeight:705}}
          loading={{
            spinning: assessmentLoading,
            indicator: <Spin indicator={<LoadingOutlined />} />,
          }}
        />
      </div>

      <Drawer
        title="Add Assessment"
        open={open}
        onClose={onClose}
        destroyOnClose
        size="large"
        footer={
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              style={{ marginRight: 10 }}
              onClick={handleSubmit}
            >
              {assessmentsId ? "Update" : "Save"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Row>
        }
      >
        <AddAssessments
          onClose={onClose}
          form={form}
          handelFile={handelFile}
          handlePreview={handlePreview}
          previewImage={previewImage}
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
          setPreviewImage={setPreviewImage}
        />
      </Drawer>
    </>
  );
};

export default DisplayAssements;