import * as React from "react";
import { useContext, useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import { Button, Col, Image, Modal, Row, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

interface proptype {
  defaultImages: UploadFile[];
  setFileUrls: React.Dispatch<React.SetStateAction<any>>;
  limit: number;
  shouldPreview: boolean;
  children: React.ReactNode;
  shouldCrop: boolean;
  counselorSignature?: any;
  studentSignature?: any;
  attachmentList?: any;
  source?: any;
  clearImage?: any;
  readonly?: boolean
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageUploader: React.FC<proptype> = ({
  defaultImages = [],
  setFileUrls,
  limit = 1,
  shouldPreview = false,
  children,
  shouldCrop = false,
  attachmentList,
  source,
  clearImage,
  readonly
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [changeImgOpen, setChangeImgOpen] = useState(false);
  //used to add existing files to show format should be as below
  /*{
        uid: string,
        name: string,
        status: string,
        url: string,
    }*/
  const [fileList, setFileList] = useState<UploadFile[]>(defaultImages);

  const handleCancel = () => setPreviewOpen(false);
  const handleChangeCancel = () => setChangeImgOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // console.log(newFileList, "handle");

    setFileList(newFileList);
    setFileUrls(newFileList);
  };

  const uploadButton = children ? (
    children
  ) : (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // console.log("res = ", source);
  // console.log("firstList = ", fileList);
  useEffect(() => {
    // if (clearImage) {
    setFileList([]);
    setPreviewOpen(false);
    setPreviewImage("");
    setPreviewTitle("");
    // }
  }, [clearImage]);
  return (
    <>
      {
        readonly ?
          <>
            {fileList.length >= limit ? null : (
              <Image preview={false} src={source} />
            )}
          </>
          :
          <>
            {source ? (
              <>
                {/* <Image
            preview={false}
            src={source}
            onClick={() => {
              setChangeImgOpen(true);
              console.log("resssssssssssssssssssssssssssssssss");
            }}
          /> */}
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={shouldPreview ? handlePreview : () => { }}
                  onChange={handleChange}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {fileList.length >= limit ? null : (
                    <Image preview={false} src={source} />
                  )}
                </Upload>
              </>
            ) : (
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={shouldPreview ? handlePreview : () => { }}
                onChange={handleChange}
                beforeUpload={() => {
                  return false;
                }}
              >
                {fileList.length >= limit ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            )}
            {shouldCrop ? (
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={shouldPreview ? handlePreview : () => { }}
                  onChange={handleChange}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {fileList.length >= limit ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            ) : null}

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}

            >
              <Image

                crossOrigin="anonymous"
                alt="example"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </>
      }

    </>
  );
};

export default ImageUploader;
