import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Flex,
  Input,
  Modal,
  Progress,
  ProgressProps,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useState, useEffect, useMemo } from "react";
import {
  useBatchAttendance,
  useGetMaster,
  useGetStudentStatus,
} from "../../Utilities/tanStackQueries";
import {
  AttandanceStatusType,
  CandidateRequestType,
  FeesType,
  MasterTypes,
} from "../../Constant/Enums";
import dayjs from "dayjs";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  ExclamationOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AddStudentAtt } from "../../Store/Slice/studentAttSlice";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import EChartsReact from "echarts-for-react";
import { color } from "echarts";
import FeesViewModal from "./FeesViewModal";
import { selectBatchState } from "../../Store/Slice/batchSlice";
import HolidaysTable from "./HolidaysTable";
import paid from "../../../src/images/currency1.svg";
import due from "../../../src/images/currency3.svg";
import EnquiryViewModal from "./EnquiryViewModal";
import { Utils } from "../../Utilities/Utils";
import StreamViewModal from "./StreamViewModal";
import SourcesViewModal from "./SourcesViewModal";

const StudentAttendance = () => {
  const [selectedStatus, setSelectedStatus] = useState<AttandanceStatusType>(
    AttandanceStatusType.All
  );
  const navigate = useNavigate();
  const [totalSourceCount, setTotalSourceCount] = useState(0);
  const [search, setSearch] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openEnquiryModal, setOpenEnquiryModal] = useState<boolean>(false);
  const [openStreamModal, setOpenStreamModal] = useState<boolean>(false);
  const [openSourcesModal, setOpenSourcesModal] = useState<boolean>(false);
  const [totalPresent, setTotalPresent] = useState<any>(0);
  const [totalAbsent, setTotalAbsent] = useState<any>(0);
  const [sendFeesType, setsendFeesType] = useState("");
  const [stream, setStream] = useState("");
  const [sendCandidateReqType, setsendCandidateReqType] = useState<any>("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sourceIdName, setSourceIdName] = useState();
  const total = totalPresent + totalAbsent;
  const percent = (totalPresent * 100) / total;
  const { data: batchMaster, isLoading: isMasterLoading } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.Batch,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { data: studentStatus, isLoading: studentLoading } =
    useGetStudentStatus();

  useEffect(() => {
    setTotalSourceCount(
      studentStatus?.result?.sourcesGroupBy?.reduce(
        (acc: any, current: any) => acc + current.Count,
        0
      )
    );
  }, [studentStatus]);

  const screenWidth = window.innerWidth;
  const isSmallScreen = screenWidth < 1564;

  const showEnquiryModal = () => {
    setOpenEnquiryModal(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancelEnquiry = () => {
    setOpenEnquiryModal(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const showSourcesModal = () => {
    setOpenSourcesModal(true);
  };
  // useEffect(() => {
  //   const streamNames = studentStatus?.result?.streamGroupBy?.map((item:any) => item.Stream?.Name);
  //   console.log(streamNames, "studentStatus");
  // }, [studentStatus]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
    },
    textStyle: {
      fontSize: isSmallScreen ? 9 : 14,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "45%"],
        startAngle: 0,
        endAngle: 360,
        verticalAlign: "bottom",
        data: studentStatus?.result?.streamGroupBy
          .filter((item: any) => item?.Stream !== null)
          .map((item: any) => ({
            value: item.Count,
            name: item.Stream ? item.Stream.Name : "Unknown",
          })),
        label: {
          fontSize: 10,
          overflow: "truncate",
          formatter: (params: any) => {
            const maxLength = 10;
            return params.name.length > maxLength
              ? `${params.name.slice(0, maxLength)}...`
              : params.name;
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        legend: {
          show: false,
        },
      },
    ],
  };

  const getBatchOptions = () => {
    return (
      batchMaster?.result?.items.map((b: any) => {
        return {
          label: b?.name,
          value: b?.id,
        };
      }) || []
    );
  };

  const navigation = useNavigate();
  const batchState = useSelector(selectBatchState);
  const {
    data: batchStudent,
    isLoading: batchStudentLoading,
    isFetched,
  } = useBatchAttendance({
    batchId: batchState,
    date: selectedMonth,
    name: search,
    // attandanceFilter:selectedStatus
  });
  // const filteredBatchStudent = batchStudent?.filter((student:any) => {
  //   if ( selectedStatus === AttandanceStatusType.All) {
  //     return true;
  //   }
  //   // return student.status || student?.status===1 || student?.dayTrans?.length >0 ? === selectedStatus;

  //   else if( selectedStatus === AttandanceStatusType.Present){
  //     return
  //   }
  //   return false
  // });

  const filteredBatchStudent = batchStudent?.filter((student: any) => {
    if (selectedStatus === AttandanceStatusType.All) {
      return true; 
    } else if (selectedStatus === AttandanceStatusType.Present) {
      return student.status === AttandanceStatusType.Present;
    } else if (selectedStatus === AttandanceStatusType.Absent) {
      return (student?.dayTrans?.length||0) === 0;
    }
    else if (selectedStatus === AttandanceStatusType.Leave){
      return student?.isLeave ? true : false
    }
 
    return false;
  });
  
  // record?.status ||
  // record?.status === 1 ||
  // record?.dayTrans?.length > 0
  //   ? "rgb(97, 200, 85)"
  //   : record.isHoliday
  //   ? "gray"
  //   : record.isLeave
  //   ? "#b029bc"
  //   : "rgb(255, 115, 115)",
  useEffect(() => {
    if (isFetched) {
      setTotalPresent(studentStatus?.result?.presentStudent);
      setTotalAbsent(studentStatus?.result?.absentStudent);
    }
  }, [isFetched, studentStatus]);

  const countPresent = async () => {
    let presentCount = 0;
    let absentCount = 0;
    await batchStudent?.forEach((item: any) => {
      if (item?.status === 1) {
        presentCount++;
      } else {
        absentCount++;
      }
    });
    setTotalPresent(presentCount);
    setTotalAbsent(absentCount);
  };
  const dispatch = useDispatch();

  const sourceColumns: any[] = [
    {
      title: "S.No",
      dataIndex: "",
      width: "2%",
      key: "",
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "sources",
      key: "sources",
      width: "30%",
      render: (record: any, index: number) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            showSourcesModal();
            console.log(sourceIdName, "sourcessss");
            setSourceIdName(record?._id || "none");
          }}
        >
          {record?.Name || "Other"}
        </span>
      ),
    },
    {
      title: "Progress",
      dataIndex: "",
      key: "",
      render: (_: any, record: any, index: number) => (
        <>
          <Flex vertical gap="small">
            <Progress
              strokeLinecap="butt"
              status="active"
              percent={parseFloat(
                ((record?.count / totalSourceCount) * 100).toFixed(0)
              )}
            />
          </Flex>
        </>
      ),
    },
    {
      title: <span style={{ paddingRight: 15 }}>Total</span>,
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (count: any) => <span style={{ paddingRight: 15 }}>{count}</span>,
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width:"48%",
      ellipsis: true,  
      showSorterTooltip: true,
   
      render: (text: any, record: any) => (
        <div style={{ display: "flex" }}>
          <span
            style={{
              display: "inline-block",
              height: "8px",
              backgroundColor:
                record?.status ||
                record?.status === 1 ||
                record?.dayTrans?.length > 0
                  ? "rgb(97, 200, 85)"
                  : record.isHoliday
                  ? "gray"
                  : record.isLeave
                  ? "#b029bc"
                  : "rgb(255, 115, 115)",
              borderRadius: "50%",
              flex: "0 0 8px",
              lineHeight: 1.2,
              alignSelf: "center",
              marginRight: "8px",
            }}
          ></span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                AddStudentAtt({
                  batch: {
                    id: record?.Batch?.id,
                    name: record?.Batch?.name,
                  },
                  student: {
                    id: record?.id,
                    name: record?.name,
                  },
                  date: dayjs.utc(selectedMonth),
                })
              );
              navigation("/attendance");
            }}
          >
            <span  style={{ fontSize: "13px" }}>
              {text}
              {/* -{" "}
            <span style={{ color: "gray", fontSize: 12 }}>
              {record?.studentId || ""}
            </span> */}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: "In Time",
      dataIndex: "in",
      key: "in",
      render: (text: any, record: any) => {
        return (
          <Tooltip
            destroyTooltipOnHide
            placement="top"
            overlayStyle={{ minWidth: 160 }}
            title={record?.dayTrans?.map((item: any, index: number) => (
              <Row key={index} justify="space-between">
                <Col className="fs12" span={12}>
                  {dayjs.utc(item?.time).format("hh:mm:ss A")}
                </Col>
                <Col className="fs12" span={11}>
                  {item?.isManual && "Manual"}
                  {console.log("record", record)}
                </Col>
              </Row>
            ))}
          >
            <span
              style={{
                textAlign: "center",
                fontSize: "13px",
                color: text
                  ? record?.batchDayIn < record?.dayTrans?.[0].time
                    ? "rgb(255, 115, 115)"
                    : "rgb(97, 200, 85)"
                  : "black",
                fontWeight: 500,
              }}
            >
              {/* {record)} */}
              {text ? dayjs.utc(text).format("hh:mm A") : "-"}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Out Time",
      dataIndex: "out",
      key: "out",
      render: (text: any, record: any) => {
        const dayTransLength = record?.dayTrans?.length || 0;
        return (
          <>
            <Tooltip
              destroyTooltipOnHide
              placement="top"
              overlayStyle={{ minWidth: 160 }}
              title={record?.dayTrans?.map((item: any, index: number) => (
                <Row key={index} justify="space-between">
                  <Col className="fs12" span={12}>
                    {dayjs.utc(item?.time).format("hh:mm:ss A")}
                  </Col>
                  <Col className="fs12" span={11}>
                    {item?.isManual && "Manual"}
                  </Col>
                </Row>
              ))}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  color:
                    dayTransLength % 2 === 0 && dayTransLength !== 0
                      ? record?.batchDayOut >
                        record?.dayTrans?.[dayTransLength - 1]?.time
                        ? "rgb(255, 115, 115)"
                        : "rgb(97, 200, 85)"
                      : "black",
                  fontWeight: 500,
                }}
              >
                {dayTransLength % 2 === 0 && dayTransLength !== 0
                  ? dayjs
                      .utc(record?.dayTrans[dayTransLength - 1]?.time)
                      .format("hh:mm A")
                  : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const onChartClick = (params: any) => {
    setOpenStreamModal((pre: any) => !pre);
    setStream(params?.data?.name);
    console.log("Chart clicked:", params);
    // You can handle the click event here
  };

  return (
    <>
      <Spin
        spinning={studentLoading || batchStudentLoading}
        indicator={<LoadingOutlined />}
        fullscreen
      />
      <Row gutter={18}>
        <Col lg={16} md={24} span={24}>
          <Row gutter={18}>
            <Col md={8} span={24}>
              <Card className="dashboardCards" style={{ height: 204 }}>
                <Typography
                  className="fs17 fw600"
                  style={{
                    color: "rgb(54, 70, 99)",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Enquiry
                </Typography>
                <Row style={{ lineHeight: 3 }} className="mt-3">
                  <Col lg={24}>
                    <Row>
                      <Col
                        style={{ cursor: "pointer" }}
                        xl={12}
                        xxl={12}
                        lg={12}
                        onClick={() => {
                          showEnquiryModal();
                          setsendCandidateReqType(
                            CandidateRequestType?.Walkins
                          );
                        }}
                      >
                        <Badge color="blue" text={<>Walkins</>} />
                      </Col>
                      <Col xl={12} xxl={12} lg={12}>
                        <p
                          style={{
                            fontWeight: 500,
                            textAlign: "end",
                            paddingRight: 12,
                          }}
                        >
                          {" "}
                          {studentStatus?.result?.centerVisitedCount}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={12}
                        xxl={12}
                        lg={12}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          showEnquiryModal();
                          setsendCandidateReqType(
                            CandidateRequestType?.Student
                          );
                        }}
                      >
                        <Badge color="green" text={<>Enrolled</>} />
                      </Col>
                      <Col xl={12} xxl={12} lg={12}>
                        <p
                          style={{
                            fontWeight: 500,
                            textAlign: "end",
                            paddingRight: 12,
                          }}
                        >
                          {" "}
                          {studentStatus?.result?.enroll}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={12}
                        xxl={12}
                        lg={12}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          showEnquiryModal();
                          setsendCandidateReqType(
                            CandidateRequestType?.Rejected
                          );
                        }}
                      >
                        <Badge color="red" text={<>Rejected</>} />
                      </Col>
                      <Col xl={12} xxl={12} lg={12}>
                        {" "}
                        <p
                          style={{
                            fontWeight: 500,
                            textAlign: "end",
                            paddingRight: 12,
                          }}
                        >
                          {studentStatus?.result?.rejected}
                        </p>{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            {/* <Col md={8}span={24}>
              {/* <Card
                className="dashboardCards"
                style={{
                  height: 204,
                }}
              >
                <Typography
                  className="fs17 fw600"
                  style={{
                    color: "rgb(54, 70, 99)",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Student Type
                </Typography>
                <EChartsReact
                  option={option2}
                  style={{ height: "140px", width: "100%" }}
                />
              </Card> */}
            {/* </Col> */}
            <Col md={8} span={24}>
              <Card className="dashboardCards" style={{ height: 204 }}>
                <Typography
                  className="fs17 fw600"
                  style={{
                    color: "rgb(54, 70, 99)",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Stream-Students
                </Typography>
                <div style={{ width: "100%" }}>
                  <EChartsReact
                    className="mt-2"
                    option={option}
                    onEvents={{
                      click: onChartClick,
                    }}
                    style={{ height: "140px", width: "100%" }}
                  />
                </div>
              </Card>
            </Col>
            <Col md={8} span={24}>
              <Card
                className="dashboardCards"
                style={{ height: 204, padding: "2px 15px 0 4px" }}
              >
                <Row justify={"space-between"}>
                  <Col lg={16}>
                    <Typography
                      className="fs17 fw600"
                      style={{
                        color: "rgb(54, 70, 99)",
                        fontFamily: "Open Sans, sans-serif",
                      }}
                    >
                      Total Students
                    </Typography>
                  </Col>
                  {/* <Divider
                      style={{ marginBottom: "2px", marginTop: "15px" }}
                      /> */}
                </Row>
                <Row style={{ lineHeight: 3, padding: 4, paddingRight: 12 }}>
                  <Col xl={16} xxl={16} lg={16} span={16}>
                    <Progress
                      status="active"
                      // style={{ margin: "6px 0"}}
                      percent={100}
                      success={{
                        percent: percent,
                        strokeColor: undefined,
                      }}
                      strokeColor="#f85768"
                      format={() => ""}
                    />
                  </Col>
                  <Col
                    xl={2}
                    xxl={2}
                    lg={2}
                    span={2}
                    style={{ textAlign: "right" }}
                  >
                    <span>{total}</span>
                  </Col>
                  <Col
                    xl={6}
                    xxl={6}
                    lg={6}
                    span={6}
                    style={{ textAlign: "right" }}
                  >
                    <span>{100}%</span>
                  </Col>
                </Row>
                <Row style={{ lineHeight: 3, padding: 4, paddingRight: 12 }}>
                  <Col xl={16} xxl={16} lg={16} span={16}>
                    <Badge color="green" text="Present" />
                  </Col>
                  <Col
                    xl={2}
                    xxl={2}
                    lg={2}
                    span={2}
                    style={{ textAlign: "right" }}
                  >
                    <p style={{ fontWeight: 500 }}> {totalPresent}</p>
                  </Col>
                  <Col
                    xl={6}
                    xxl={6}
                    lg={6}
                    span={6}
                    style={{ textAlign: "right" }}
                  >
                    <p style={{ fontWeight: 500 }}>
                      {totalPresent && totalPresent
                        ? (
                            (totalPresent * 100) /
                            (totalPresent + totalAbsent)
                          ).toFixed(0)
                        : 0}
                      %
                    </p>
                  </Col>
                </Row>
                <Row style={{ padding: 4, paddingRight: 12 }}>
                  <Col xl={16} xxl={16} lg={16} span={16}>
                    <Badge color="#f85768" text="Absent" />
                  </Col>
                  <Col
                    xl={2}
                    xxl={2}
                    lg={2}
                    span={2}
                    style={{ textAlign: "right" }}
                  >
                    <p style={{ fontWeight: 500 }}>{totalAbsent}</p>
                  </Col>
                  <Col
                    xl={6}
                    xxl={6}
                    lg={6}
                    span={6}
                    style={{ textAlign: "right" }}
                  >
                    <p style={{ fontWeight: 500 }}>
                      {totalAbsent
                        ? (
                            (totalAbsent * 100) /
                            (totalPresent + totalAbsent)
                          ).toFixed(0)
                        : 0}
                      %
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={18} className="mt-3">
            <Col md={13}>
              <Card
                className="dashboardCards"
                style={{
                  height: windowWidth < 1000 ? "" : 204,
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <Typography
                  className="fs17 fw600 "
                  style={{
                    color: "rgb(54, 70, 99)",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Total Fees
                </Typography>
                <Row gutter={18} className="mt-3">
                  <Col lg={8}>
                    <Card
                      className="cardHover pt-0 mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        showModal();
                        setsendFeesType(FeesType.Paid);
                      }}
                    >
                      <Row className="mt-3" gutter={18}>
                        {/* <Col>
                          <img src={paid} />
                        </Col> */}
                        <Col>
                          <Space style={{ fontWeight: 500 }}>
                            Paid Fees{" "}
                            <p style={{ color: "green", fontWeight: 600 }}>
                              {" "}
                              {studentStatus?.result?.paidFees || 0}{" "}
                            </p>
                          </Space>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={24}>
                          <Typography
                            className="fs14 fw500"
                            style={{
                              color: "rgb(54, 70, 99)",
                              fontFamily: "Open Sans, sans-serif",
                            }}
                          >
                            ₹
                            {Utils.getFormattedNumber(
                              studentStatus?.result?.total?.totalPaidAmount,
                              0
                            )}
                          </Typography>
                        </Col>
                        {/* <Col md={8}>
                          {" "}
                          <img
                            src={paid}
                            alt=""
                            style={{ width: "65px", height: "20px" }}
                          />
                        </Col> */}
                      </Row>
                    </Card>{" "}
                  </Col>
                  <Col lg={8}>
                    <Card
                      className="cardHover pt-0 mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        showModal();
                        setsendFeesType(FeesType.Due);
                      }}
                    >
                      <Row className="mt-3" gutter={18}>
                        {/* <Col>
                          <img src={due} />
                        </Col> */}
                        <Col>
                          <Space style={{ fontWeight: 500 }}>
                            Due Fees{" "}
                            <p style={{ color: "#f62d38", fontWeight: 600 }}>
                              {studentStatus?.result?.dueStudentFees || 0}
                            </p>
                          </Space>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={24}>
                          <Typography
                            className="fs14 fw500"
                            style={{
                              color: "rgb(54, 70, 99)",
                              fontFamily: "Open Sans, sans-serif",
                            }}
                          >
                            ₹
                            {Utils.getFormattedNumber(
                              studentStatus?.result?.total
                                ?.totalRemainingAmount,
                              0
                            )}
                          </Typography>
                        </Col>
                        {/* <Col md={8}>
                          {" "}
                          <img
                            src={due}
                            alt=""
                            style={{ width: "65px", height: "20px" }}
                          />
                        </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col lg={8}>
                    <Card
                      className="pt-0 mt-3"
                      onClick={() => {
                        // showModal();
                        setsendFeesType(FeesType.Due);
                      }}
                    >
                      <Row className="mt-3" gutter={18}>
                        {/* <Col>
                          <img src={due} />
                        </Col> */}
                        <Col>
                          <Space style={{ fontWeight: 500 }}>
                            Total Received{" "}
                            <p style={{ color: "#f62d38", fontWeight: 600 }}>
                              {/* {studentStatus?.result?.dueStudentFees || 0} */}
                            </p>
                          </Space>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={24}>
                          <Typography
                            className="fs14 fw500"
                            style={{
                              color: "rgb(54, 70, 99)",
                              fontFamily: "Open Sans, sans-serif",
                            }}
                          >
                            ₹
                            {Utils.getFormattedNumber(
                              studentStatus?.result?.totalPaidAmountByStudent,
                              0 || 0
                            )}
                          </Typography>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <Card className="mt-3">
                <Row>
                  <Col lg={21}>
                    <Typography
                      className="fs17 fw600 "
                      style={{
                        color: "rgb(54, 70, 99)",
                        fontFamily: "Open Sans, sans-serif",
                      }}
                    >
                      Upcoming Holidays
                    </Typography>
                  </Col>
                  <Col lg={3}>
                    <Button
                      size="small"
                      type="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/Holiday")}
                    >
                      View all
                    </Button>
                  </Col>
                </Row>

                <HolidaysTable data={studentStatus?.result?.upCommingHoliday} />
              </Card>
              {isModalOpen && (
                <FeesViewModal
                  sendFeesType={sendFeesType}
                  isModalOpen={isModalOpen}
                  handleCancel={handleCancel}
                  setIsModelOpen={setOpenEnquiryModal}
                />
              )}
              {openEnquiryModal && (
                <EnquiryViewModal
                  sendCandidateReqType={sendCandidateReqType}
                  openEnquiryModal={openEnquiryModal}
                  handleCancelEnquiry={handleCancelEnquiry}
                  setIsModelOpen={setOpenEnquiryModal}
                />
              )}
              {openStreamModal && (
                <StreamViewModal
                  setOpenStreamModal={setOpenStreamModal}
                  isModalOpen={openStreamModal}
                  stream={stream}
                />
              )}
              {openSourcesModal && (
                <SourcesViewModal
                  sourceIdName={sourceIdName}
                  setOpenSourcesModal={setOpenSourcesModal}
                  openSourcesModal={openSourcesModal}
                />
              )}
            </Col>
            <Col md={11}>
              <Card
                className="dashboardCards"
                style={{
                  height: "100%", //windowWidth < 1000 ? "" : "",
                }}
              >
                <Typography
                  className="fs17 fw600 "
                  style={{
                    color: "rgb(54, 70, 99)",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  Sources
                </Typography>
                <Table
                  columns={sourceColumns}
                  dataSource={studentStatus?.result?.sourcesGroupBy?.map(
                    (item: any) => ({
                      count: item?.Count,
                      sources: item?.Sources ? item.Sources : "Unknown",
                    })
                  )}
                  style={{
                    width: "100vw",
                    borderBottom: "none",
                    // maxHeight: 730,
                    fontSize: "13px",
                    overflow: "auto",
                    minHeight: "500px",
                  }}
                  size="small"
                  pagination={false}
                ></Table>
              </Card>
            </Col>
          </Row>
          <Row gutter={18} className="mt-3">
            <Col lg={12}></Col>
            <Col lg={12}></Col>
          </Row>
        </Col>
        <Col lg={8}>
          <div className="py-2 roundedCornerMedium bg-white">
            <Row gutter={18} style={{ padding: 12 }}>
              <Col lg={8} xxl={8} xl={8} md={8}>
                <Select
                  // loading={isMasterLoading}
                  popupClassName="cit-select-box"
                  placeholder={"Select Status"}
                  // allowClear
                  // onClear={() => {
                  //   setSelectedStatus("");
                  // }}
                  options={[
                    { label: "All", value: AttandanceStatusType.All },
                    { label: "Present", value: AttandanceStatusType.Present },
                    { label: "Absent", value: AttandanceStatusType.Absent },
                    { label: "Leave", value: AttandanceStatusType.Leave },
                  ]}
                  showSearch
                  labelInValue
                  value={selectedStatus}
                  // options={getBatchOptions()}
                  style={{ width: "100%" }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input?.trim().toLowerCase())
                  }
                  onSelect={(val: any) => {
                    setSelectedStatus(val?.value);
                  }}
                />
              </Col>
              <Col lg={8} xxl={8} xl={8} md={8}>
                <DatePicker
                  allowClear={false}
                  value={selectedMonth}
                  style={{ width: "100%" }}
                  // picker="month"
                  format="DD/MM/YYYY"
                  disabledDate={(current: any) => {
                    return current && current > dayjs().endOf("month");
                  }}
                  onChange={(val: any) => {
                    setSelectedMonth(val);
                  }}
                />
              </Col>

              <Col lg={8} xxl={8} xl={8} md={8}>
                <Search
                  size="middle"
                  placeholder="Search..."
                  allowClear
                  className="att-search-input"
                  onSearch={(val: string) => {
                    setSearch(val);
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Table
            className="studentList"
              // loading={{
              //   spinning: batchStudentLoading,
              //   indicator: <Spin indicator={<LoadingOutlined />} />,
              // }}
              style={{
                width: "100vw",
                borderBottom: "none",
                maxHeight: 712,
                overflow: "auto",
                minHeight: 712,
              }}
              size="small"
              pagination={false}
              scroll={{y:800}}
              dataSource={filteredBatchStudent}
              columns={columns}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "rgb(97, 200, 85)",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Present</span>
              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "rgb(255, 115, 115)",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Absent</span>

              {/* <span
              style={{
                display: "inline-block",
                height: "8px",
                backgroundColor: "purple",
                borderRadius: "50%",
                flex: "0 0 8px",
                alignSelf: "center",
                marginRight: "5px",
              }}
            ></span>
    <span style={{marginRight:10}}>OnLeave</span> */}

              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "#b029bc",
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 10 }}>Leave</span>
              <span
                style={{
                  display: "inline-block",
                  height: "8px",
                  backgroundColor: "gray", //#f0e015
                  borderRadius: "50%",
                  flex: "0 0 8px",
                  alignSelf: "center",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ marginRight: 20 }}>Holiday,</span>
            </div>
          </div>
          {/* </Card> */}
        </Col>
        {/* <Card > */}
      </Row>
    </>
  );
};
export default StudentAttendance;
