import { Form, FormInstance, Input, message, Select } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface IAddEditCourse {
  data: any;
  form: FormInstance<any>;
}


const AddEditFaculty = ({ data, form }: IAddEditCourse) => {
    const [loading, setLoading] = useState<any>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource, setDataSource] = useState<any>([]);
    const [listParams, setListParams] = useState({
        roleSearch:"TEACHER",
        status:0,
        start: 0,
        length: 500
    });
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };

  
  const getUserList = async () => {
    setLoading(true)
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?", listParams)
    if (res?.result?.items?.length > 0) {
        setDataSource(res?.result?.items?.map((item: any) => ({
            value: item?.id,
            label: item?.name?.first,
            role: item?.role,
            email: item?.email,
            status: item?.status,
            totalRecord:res?.result?.totalRecords
        })))
        setLoading(false)
    }
    else {
        setLoading(false)
        messageApi.error(res?.message)
    }
}
  useEffect(() => {
    if (!!data) form.setFieldsValue({ ...data });
    getUserList();
  }, []);

  return (
    <Form
      form={form}
      name="department-form"
      className="DrawerPadding"
      colon={false}
      autoComplete="off"
      style={{ padding: 16 }}
      labelAlign="left"
      requiredMark={false}
      {...formItemLayout}
    >
      <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item label="Faculty Name" name="facultyName"
      rules={[{ required: true, message: "field is required" }]}
      >
        <Select
          labelInValue={true}
          placeholder="Select section"
          options={dataSource}
          filterOption={(input: any, option: any) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
    </Form>
  );
};

export default AddEditFaculty;
