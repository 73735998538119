import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Drawer, Form, Input, Row, message } from "antd";
import React, { useEffect } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface IAddEditLeaveTypes {
  id: string;
  detail: any;
  open: boolean;
  editDetails: any;
  onDismiss: (rec: boolean) => void;
}

const AddEditLeaveTypes = (props: IAddEditLeaveTypes) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (props?.id && props?.detail) {
      form.setFieldsValue({
        leaveType: props?.detail?.leaveType,
        isAttachmentRequired: props?.detail?.isAttachmentRequired,
      });
    }
  }, [props?.id, props?.detail, form]);

  const saveData = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        LeaveType: values.leaveType,
        IsAttachmentRequired: values.isAttachmentRequired || false, 
        ...(props.id ? { id: props.id } : {}), // Include id in payload for update
      };

      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.CREATE_UPDATE_LEAVE,
        {},
        payload
      );

      if (res.result) {
        message.success(props?.id ? "Leave Type Updated Successfully" : "Leave Type Created Successfully" );
        form.resetFields();
        props.onDismiss(true); 
      } else {
        message.error("Error creating Leave Type");
        console.log("Error creating Leave Type", res.errors);
      }
    } catch (err) {
      message.error("Error creating Leave Type");
      console.error("Form validation or request error", err);
    }
  };

  return (
    <Drawer
      title={props?.id ? "Edit Leave Types" : "Add Leave Types"}
      placement="right"
      width={500}
      maskClosable={false}
      onClose={() => props.onDismiss(false)}
      closeIcon={false}
      open={props.open}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => props.onDismiss(false)}
        />
      }
      footer={
        <Row justify="end">
          <Button onClick={() => props.onDismiss(false)} className="ttp-btn-light btn-m-sm">
            Cancel
          </Button>
          <Button
            type="primary"
            className="btn-m-sm"
            onClick={saveData} // Ensure saveData is called on click only
          >
            {props?.id ? "Update" : "Save"}
          </Button>
        </Row>
      }
    >
      <Form
        form={form}
        className="DrawerPadding"
        colon={false}
        autoComplete="off"
        style={{ padding: 16 }}
        labelAlign="left"
        requiredMark={false}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Add Leave Type"
              name="leaveType"
              rules={[{ required: true, message: "Please enter leave type" }]}
            >
              <Input onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Is Attachment Required" name="isAttachmentRequired" valuePropName="checked">
              <Checkbox className="pt-1"  />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddEditLeaveTypes;
