import { CounselingSubSteps, DocumentsSubSteps, EmailInvoiceSubSteps, LsbuSubSteps, NewSubSteps, ProcessStepsEnum, RegistrationSubSteps, VerificationSubSteps } from "../Constant/Enums";

const wait = "wait";
const error = "error";
const process = "process";
const finish = "finish";

export const getNewInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.New) {
        if (!!reject) {
            if (!!reject && current_sub_step === 2)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === NewSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getCounselingInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {
    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Counseling) {
        if (!!reject) {
            if (!!reject && current_sub_step === 2)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === CounselingSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getRegistrationInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Registration) {
        if (!!reject) {
            if (!!reject && current_sub_step === 2)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === RegistrationSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getDocumentsInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Documents) {
        if (!!reject) {
            if (!!reject && current_sub_step === 3)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === DocumentsSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getEmailInvoiceInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.EmailInvoice) {
        if (!!reject) {
            if (!!reject && current_sub_step === 2)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === EmailInvoiceSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getVerificationInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Verification) {
        if (!!reject) {
            if (!!reject && current_sub_step === 4)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === VerificationSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

export const getLSBUInnerStepStatus = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any) => {

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);

    if (apiProcessStep === ProcessStepsEnum.LSBU) {
        if (!!reject) {
            if (!!reject && current_sub_step === 4)
                return error;
            else
                return wait;
        }
        else {
            if (api_sub_step < current_sub_step)
                return wait;
            else if (api_sub_step > current_sub_step)
                return finish
            else return process;
        }
    }
    else {
        if (current_sub_step === LsbuSubSteps.Reject) {
            return wait;
        }
        else {
            return finish;
        }
    }
}

const getModifyStep = (apiProcessStep: ProcessStepsEnum, step: number): number => {

    let modifiedStep: number = 0;

    if (apiProcessStep === ProcessStepsEnum.New) {

        if (step === NewSubSteps.Waiting)
            modifiedStep = 0

        else if (step === NewSubSteps.Confirm)
            modifiedStep = 1;

        else if (step === NewSubSteps.Reject)
            modifiedStep = 2;
    }
    else if (apiProcessStep === ProcessStepsEnum.Counseling) {

        if (step === CounselingSubSteps.Waiting)
            modifiedStep = 0

        else if (step === CounselingSubSteps.Confirm)
            modifiedStep = 1;

        else if (step === CounselingSubSteps.Reject)
            modifiedStep = 2;
    }
    else if (apiProcessStep === ProcessStepsEnum.Registration) {

        if (step === RegistrationSubSteps.Waiting)
            modifiedStep = 0

        else if (step === RegistrationSubSteps.Proceed)
            modifiedStep = 1;

        else if (step === RegistrationSubSteps.Reject)
            modifiedStep = 2;
    }
    else if (apiProcessStep === ProcessStepsEnum.Documents) {

        if (step === DocumentsSubSteps.Submission)
            modifiedStep = 0

        else if (step === DocumentsSubSteps.Acknowledgement)
            modifiedStep = 1;

        else if (step === DocumentsSubSteps.VerifyDocuments)
            modifiedStep = 2;

        else if (step === DocumentsSubSteps.Reject)
            modifiedStep = 3;
    }
    else if (apiProcessStep === ProcessStepsEnum.EmailInvoice) {
        if (step === EmailInvoiceSubSteps.Waiting)
            modifiedStep = 0
        else if (step === EmailInvoiceSubSteps.SendInvoice)
            modifiedStep = 1;
        else if (step === EmailInvoiceSubSteps.Reject)
            modifiedStep = 2;
    }
    else if (apiProcessStep === ProcessStepsEnum.Verification) {

        if (step === VerificationSubSteps.Waiting)
            modifiedStep = 0

        else if (step === VerificationSubSteps.Acknowledgement)
            modifiedStep = 1;

        else if (step === VerificationSubSteps.VerifyDocuments)
            modifiedStep = 2;

        else if (step === VerificationSubSteps.Reject)
            modifiedStep = 3;
    }
    else if (apiProcessStep === ProcessStepsEnum.LSBU) {

        if (step === LsbuSubSteps.Waiting)
            modifiedStep = 0

        else if (step === LsbuSubSteps.ShareCVDocs)
            modifiedStep = 1;

        else if (step === LsbuSubSteps.ReceivedStudyContact)
            modifiedStep = 2;

        else if (step === LsbuSubSteps.ReceivedCredentials)
            modifiedStep = 3;

        else if (step === LsbuSubSteps.Reject)
            modifiedStep = 4;
    }
    return modifiedStep;
}

export const getNewInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.New) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===2)
                return false;
            else if(current_sub_step===api_sub_step)
                return false;
            else if(current_sub_step-1!==api_sub_step)
                return true;
            else return false;
        }
    }
}

export const getCounselingInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Counseling) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===2)
                return false;
            else if(current_sub_step===api_sub_step)
                return false;
            else if(current_sub_step-1!==api_sub_step)
                return true;
            else return false;
        }
    }
}


export const getRegistrationInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    
    if (apiProcessStep === ProcessStepsEnum.Registration) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===2)
                return false;
            else if(current_sub_step===api_sub_step)
                return false;
            else if(current_sub_step-1!==api_sub_step)
                return true;
            else return false;
        }
    }
}

export const getDocumentsInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    if (apiProcessStep === ProcessStepsEnum.Documents) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===3) // match for last step which is reject
                return false;
            else if(current_sub_step===api_sub_step) // match current step
                return false;
            else if(current_sub_step-1!==api_sub_step) // before and after-1 steps
                return true;
            else return false;
        }
    }
}

export const getEmailInvoiceInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    if (apiProcessStep === ProcessStepsEnum.EmailInvoice) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===2) // match for last step which is reject
                return false;
            else if(current_sub_step===api_sub_step) // match current step
                return false;
            else if(current_sub_step-1!==api_sub_step) // before and after-1 steps
                return true;
            else return false;
        }
    }
}

export const getVerificationInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    if (apiProcessStep === ProcessStepsEnum.Verification) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===3) // match for last step which is reject
                return false;
            else if(current_sub_step===api_sub_step) // match current step
                return false;
            else if(current_sub_step-1!==api_sub_step) // before and after-1 steps
                return true;
            else return false;
        }
    }
}

export const getLSBUInnerStepDisable = (apiProcessStep: ProcessStepsEnum, apiSubSteps: number, currentSubSteps: number, reject: any)=>{

    let current_sub_step = getModifyStep(apiProcessStep, currentSubSteps);
    let api_sub_step = getModifyStep(apiProcessStep, apiSubSteps);
    if (apiProcessStep === ProcessStepsEnum.LSBU) {
        if (!!reject) {
            return true;
        }
        else {
            if(current_sub_step===4) // match for last step which is reject
                return false;
            else if(current_sub_step===api_sub_step) // match current step
                return false;
            else if(current_sub_step-1!==api_sub_step) // before and after-1 steps
                return true;
            else return false;
        }
    }
}