import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Typography, Col, Table, Radio, Spin, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import { MasterTypes } from "../../../../Constant/Enums";
import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import ".././ManageWeight/Weigth.css";
import IndivitualWeight from "./IndivitualWeight";
import { adminContext } from "../../../../Common/PageRoute";

const { Paragraph } = Typography;

interface DataType {
  key: string;
  name: string;
}

const DisplayWeight = () => {
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const { openNotification } = useContext(adminContext);
  const [start, setStart] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [length, setLength] = useState<any>(15);
  const [equalWeight, setEqualWeight] = useState<any>();
  const [defaultSetup, setDefaultSetup] = useState<any>();
  const [totalWeight, setTotalWeight] = useState<any>(0);
  const [totalError, setTotalError] = useState<any>(false);
  const [indivitual, setindivitual] = useState<any>(false);

  const weightColumns: TableProps<DataType>["columns"] = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (text) => (
        <Typography.Link color="#0D61FF">{text}</Typography.Link>
      ),
    },
    {
      title: "Weight(%)",
      dataIndex: "weight",
      key: "weight",
      render: (text, record: any, index) => {
        return (
          <div>
            <Paragraph
              style={{ marginBottom: 4, marginTop: 4 }}
              className="weightText"
              editable={{
                tooltip: "click to edit text",
                onChange: (str) => {
                  if (str !== "") {
                    record.weight = str;
                    setEqualWeight([...equalWeight]);
                  }
                },
                triggerType: ["text"],
              }}
            >
              {text}
            </Paragraph>
          </div>
        );
      },
    },
  ];
    const { data: AssessmentCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ["assessmentCategory", start, length],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: start,
            length: length,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          return res?.result?.items.map((item: any) => ({
            ...item,
            key: item.id,
          })); // Add key prop to each item
        } else {
          console.log("error");
          return []; // return an empty array when API call fails or no data
        }
      } catch (error) {
        console.error(error);
        return []; // return an empty array when API call fails
      }
    },
  });

  const {
    data: commonData,
    isLoading: commonLoading,
    refetch:commonRefech
  } = useQuery({
    queryKey: ["commonWeight"],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_COMMON_WEIGHT + "?",
          {
            IsCommon:true
          });
        if (res?.result?.items?.length > 0) {
          // setPagingItems((p) => {
          //   p.totalRecords = res?.result?.totalRecords;
          //   return p;
          // });
          setEqualWeight(res?.result?.items?.[0].weightArr)
          return res?.result?.items?.[0].weightArr
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const setupChange = (change: any) => {
    if (change.target.value === "yes"&&!commonData) {
      let category = AssessmentCategory.map((e: any, index: number) => {
        return {
          key: index,
          name: e?.name,
          weight: 0,
        };
      });
      setEqualWeight(category);

      let totalEqualWeight = category.reduce(
        (total: number, currentCategory: any) => {
          return total + currentCategory.weight;
        },
        0
      );

      setTotalWeight(totalEqualWeight);
      setindivitual(false);
    } else {
      setindivitual(!indivitual);
    }
  };


  useEffect(() => {
    let totalEqualWeight = equalWeight?.reduce(
      (total: number, currentCategory: any) => {
        return total + Number(currentCategory.weight);
      },
      0
    );
    setTotalWeight(totalEqualWeight);

    if (totalEqualWeight === 100) {
      setTotalError(false);
    } else {
      setTotalError(true);
    }
  }, [equalWeight]);

  useEffect(() => {
    setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
    setLength(pagingItems?.pageSize);
  }, [pagingItems]);

  useEffect(()=>{
    if (!commonData) {
      console.log("done");
      
      let category = AssessmentCategory?.map((e: any, index: number) => {
        return {
          key: index,
          name: e?.name,
          weight: 0,
        };
      });
      setEqualWeight(category);

      let totalEqualWeight = category?.reduce(
        (total: number, currentCategory: any) => {
          return total + currentCategory.weight;
        },
        0
      );

      setTotalWeight(totalEqualWeight);
      setindivitual(false);
    }
  },[commonData,AssessmentCategory])


  const handleSubmit = async ()=>{
    try {
    let data = AssessmentCategory?.map((ele:any,index:number)=>{
      return {
        Id:ele?.id,
        Name:ele?.name,
        Weight:equalWeight[index]?.weight
      }
    })
    var IsCommon=true;
      setLoading(true)
      await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_ASSESSMENTS_WEIGHT+"?",{IsCommon},data)
      openNotification("success", "Weight Save SuccessFully");
    } catch (error) {
      openNotification("warning", error);
    }finally{
      setLoading(false);
    }
    
  }

  return (
    <>
    {indivitual ? (
      <IndivitualWeight
        totalError={totalError}
        setTotalError={setTotalError}
        setupChange={setupChange}
      />
    ) : (
    <div>
      <Row justify={"space-between"}>
        <Col span={12}>
          <Typography.Text
            style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
          >
            Setup Weight:
          </Typography.Text>
          <Radio.Group name="setupGroup" onChange={setupChange} defaultValue={"yes"}>
            <Radio value={"yes"}>Yes</Radio>
            <Radio value={"no"}>No</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ fontWeight: 500 }}
            disabled={totalError ? true : false}
            loading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>

      <Table
        columns={weightColumns}
        dataSource={equalWeight || AssessmentCategory}
        style={{ marginTop: 14 ,minHeight:665}}
        loading={{
          spinning: categoryLoading,
          indicator: <Spin indicator={<LoadingOutlined />} />,
        }}
        pagination={false}
        // pagination={{
        //   showTotal: (total, range) =>
        //     `${range[0]}-${range[1]} of ${total} items`,
        //   selectPrefixCls: "custom-table-select",
        //   current: pagingItems.currentPage,
        //   pageSize: pagingItems.pageSize,
        //   showSizeChanger: true,
        //   total: pagingItems.totalRecords,
        //   pageSizeOptions: ["15", "25", "50", "100"],
        // }}
        // onChange={(paging, filter, sort: any) => {
        //   paging &&
        //     setPagingItems({
        //       ...pagingItems,
        //       currentPage: paging?.current ?? 1,
        //       pageSize: paging?.pageSize ?? 15,
        //     });
        // }}
      />
      <Row justify={"space-between"} style={{ padding: 10 }}>
        <Col span={17} style={{ fontWeight: "bolder" }}>
          Total:
        </Col>
        <Col span={7} style={{ fontWeight: "bolder" }}>
          <Typography.Text> {totalWeight}/100</Typography.Text>
          {totalError && (
            <Typography.Text style={{ marginLeft: 10, color: "red" }}>
              *The Percentages should be 100 %
            </Typography.Text>
          )}
        </Col>
      </Row>
    </div>)}
    </>
  );
};

export default DisplayWeight;
