import * as React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Radio,
  Image,
  message,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import type { GetProp, UploadFile, UploadProps } from "antd";
import {  UploadOutlined } from "@ant-design/icons";
import type { SelectProps } from "antd";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { GradingScaleOptions, MasterTypes } from "../../../../Constant/Enums";

interface I_Props {
  onClose: Function;
  form: any;
  handelFile: any;
  handlePreview:any;
  previewImage:any;
  previewOpen:any;
  setPreviewImage:any;
  setPreviewOpen:any;
}

const AddAssessments: React.FC<I_Props> = (props) => {
  const [marksPoint, setMarksPoint] = React.useState<any>("");
  const [passValidation,setPassValidation]= React.useState<number|undefined>();

  React.useEffect(() => {
    setPassValidation(props.form.getFieldValue("totalMarks"))
  }, [])
  

  React.useEffect(()=>{
    let temp = props.form.getFieldValue("marksIn") || ""
    setMarksPoint(temp)
  },[marksPoint])

  const { data: AssessmentCategory } = useQuery({
    queryKey: ["assessmentCategory"],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: 0,
            length: 50,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          console.log(res?.result?.items, "categoryOption");
          return res?.result?.items.map((item: any) => ({
            key: item.id,
            label: item.name,
            value: item.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
  };

  const upload: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];



  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div>
      <Row style={{ marginLeft: 20, marginTop: 20 }}>
        <Col span={23}>
          {/* <Form form={props.form} layout="vertical"> */}
          <Form form={props.form}>
            <div
              style={
                {
                  // maxHeight: `calc(100vh - 115px)` ,
                  // overflowY: "auto",
                }
              }
            >
              <Row gutter={[20, 0]}>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    label="Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please select Category!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Choose Category"
                      optionFilterProp="children"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={AssessmentCategory}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    name="assessmentName"
                    label="Assessment Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Assessments Name!",
                      },
                      // {
                      //   whitespace: true,
                      //   message: "Invalid name",
                      // },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Enter Name"
                      onInput={(e: any) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value
                            : e.target.value.toUpperCase();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  name={"marksIn"}
                  label="Marks in :"
                  rules={[
                    { required: true, message: "Please input Maks Group!" },
                  ]}
                >
                  {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Marks in:
                </Typography.Text> */}
                  <Radio.Group
                    name="marksGroup"
                    onChange={(e)=>{
                      setMarksPoint(e.target.value)
                    }}
                    style={{ marginTop: 5, marginLeft: 10 }}
                  >
                    <Radio value={"points"}>Points</Radio>
                    <Radio value={"marks"}>Marks</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>

              <Row gutter={[20, 0]}>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    name="totalMarks"
                    label= {`Total ${marksPoint}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input Total Marks!",
                      },
                      // {
                      //   validator(rule: any, val: any, callback) {
                      //     if (val > 100) {
                      //       callback("less than and equal to 100")
                      //     }
                      //     else{
                      //       callback()
                      //     }
                      //   }
                      // }
                    ]}
                  >
                    <Input placeholder="400" style={{ width: "100%" }} 
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    onChange={(element)=>{
                      setPassValidation(Number(element.target.value))
                    }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    name="passingMarks"
                    label={`Passing ${marksPoint}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input Assessments Name!",
                      },
                      // {
                      //   whitespace: true,
                      //   message: "Invalid name",
                      // },
                      {
                        validator(rule: any, val: any, callback) {
                          if ( passValidation !==undefined && val > passValidation) {
                            callback(`passing ${marksPoint} should be less than Total ${marksPoint}`)
                          }
                          else{
                            callback()
                          }
                        }
                      }
                    ]}
                  >
                    <Input
                      placeholder="200"
                      // style={{ width: "88%", marginLeft: 24 }}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                      onChange={()=>{
                        console.log(passValidation,"nn");
                        // setPassValidation(props.form.getFieldValue("totalMarks"))
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  name={"countScore"}
                  label="Counts towards Overall Score : "
                  rules={[
                    {
                      required: true,
                      message: "Please Select Field!",
                    },
                  ]}
                >
                  <Radio.Group name="ScoreGroup" style={{ marginTop: 5 }}>
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Counts towards Overall Score:
                </Typography.Text> */}
              </Row>

              <Row>
                <Col sm={12} lg={24} xs={24}>
                  <Form.Item
                    label="Grading Scale"
                    name="gradingScale"
                    rules={[
                      {
                        required: true,
                        message: "Please select Grade!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Grades/ Rubric/ Percentages/ Actual Points or Marks"
                      optionFilterProp="children"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={GradingScaleOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Applied To"
                    name={"appliedTo"}
                    rules={[
                      {
                        required: true,
                        message: "Please select Applied To!",
                      },
                    ]}
                  >
                    <Radio.Group name="applyGroup" style={{ marginTop: 5 }}>
                      <Radio value={"all"}>All</Radio>
                      <Radio value={"particularSession"}>
                        Particular Session
                      </Radio>
                      <Radio value={"particularCourseSem"}>
                        Particular Course/semester
                      </Radio>
                      <Radio value={"particularSubject"}>
                        Particular Subject
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row>
                <Col span={24}>
                  <Form.Item label="Select Course/semester"
                  name={"semesterVal"}
                  rules={[{
                    required: true,
                    message: "Please select Course/semester!",
                  }]}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      onChange={handleChange}
                      options={options}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Form.Item
                  name={"onlineSubmission"}
                  label="Online submission:"
                  rules={[
                    {
                      required: true,
                      message: "Please select Online submission!",
                    },
                  ]}
                >
                  {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Online submission:
                </Typography.Text> */}
                  <Radio.Group name="submissionGroup" style={{ marginTop: 5 }}>
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      // placeholder="Other"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row>
                <Upload {...upload}>
                  <Button icon={<UploadOutlined />}>Add Attachment</Button>
                </Upload>
              </Row> */}

              <Row>
                <Form.Item
                  name="attachments"
                  label="Upload"
                  
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    action="/upload.do"
                    listType="picture"
                    onPreview={props.handlePreview}
                    beforeUpload={(file: any) => {
                      props.handelFile(file);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Add Attachment</Button>
                  </Upload>
                </Form.Item>
              {  console.log(props.previewImage)}
                
                {props.previewImage && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: props.previewOpen,
                      onVisibleChange: (visible) => props.setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && props.setPreviewImage(""),
                    }}
                    src={props.previewImage}
                  />
                )}
                {/* <Upload {...upload}>
                    <Button icon={<UploadOutlined />}>Add Attachment</Button>
                  </Upload> */}
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddAssessments;
