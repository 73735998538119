import "../../../Common/Common.css";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ContainerOutlined,
  ControlOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, List, Row, Space } from "antd";
import "../../../Custom.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { EnquiryType } from "../../../Constant/Enums";

interface IProcessStepSetting
{
    processType:EnquiryType
}
const ProcessStepSetting = (props:IProcessStepSetting) => {
  const navigate = useNavigate();
  const data = [
    {
      icon: <SaveOutlined style={{ fontSize: "19px" }}/>,
      name: "Add Edit Process Steps",
      onClick: () => {
        navigate("addEditProcessSteps");
      },
    },
    {
      icon: <ControlOutlined style={{ fontSize: "19px" }} />,
      name: "Action Configurations for Steps",
      onClick: () => {
        navigate("configActions");
      },
    },
  ];

  return (
    <>
      {/* <p style={{ textAlign: "end", margin: "2px 2px" }}>Fees</p> */}
      <Row justify={'space-between'} align="middle" style={{ marginBottom: "6px" }}>
      <ArrowLeftOutlined  style={{color:'#1677ff'}}  onClick={() => {
        navigate('/settings')
      }}/>
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Process Steps for Website ",
            },
          ]}
        ></Breadcrumb>
      </Row>

      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <List
          header={
            <span style={{ fontWeight: 550, backgroundColor: "#e0e4e938" }}>
              Process Steps for {props?.processType===EnquiryType.Website?"Website":"Application"}
            </span>
          }
          style={{ width: "100%" }}
          bordered
          dataSource={data}
          size="small"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
              <ArrowRightOutlined  onClick={item?.onClick}/>
              </span>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default ProcessStepSetting;
