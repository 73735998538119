import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Button, Modal, Space } from "antd";

const DNCModel: React.FC<{
  open: boolean;
  onOk: any;
  onCancel: any;
  text: any;
}> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <>
      <Modal
        title={"Confirmation"}
        open={props.open}
        onCancel={props.onCancel}
        width={350}
        style={{ marginTop: '200px' }}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={props.onOk} type="default">
              NO
            </Button>
            <Button type="primary" danger
              style={{
                border: "2px solid black",
                width: "80px",
              }}
              loading={loading}
              onClick={props.onCancel}
            >
              Yes
            </Button>
          </Space>
        }
      >
        <p style={{ color: "gray" }}>{props.text}</p>
      </Modal>
    </>
  );
};

export default DNCModel;
