import * as React from "react";
import { useContext, useState, useEffect } from "react";
import StudentAttendance from "./StudentAttendance";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  return (
    <div>
        <StudentAttendance/>
    </div>
  );
};

export default Dashboard;
