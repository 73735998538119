import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  InputNumber,
  Checkbox,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { FeesTypeMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

interface AddEditFeesTypeIn {
  setTrigger: any;
  openDrawer: any;
  feesTypeId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(FeesTypeMaster.endPoints, FeesTypeMaster.prefix);

const AddEditFeesType = (props: AddEditFeesTypeIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };

  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.FeesType,
        id: props?.feesTypeId,
        name: values?.name,
        isRefundable: values?.isRefundable,
        isPaidToParul: values?.isPaidToParul,
      };
      await api
        .POST(FeesTypeMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.feesTypeId
                ? "FeesType updated successfully"
                : "FeesType saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.feesTypeId) {
        setLoading(true);
        getFeesTypeById(props?.feesTypeId);
      }
    }
  }, [props?.openDrawer]);

  const getFeesTypeById = async (id: any) => {
    setLoading(true);
    await api
      .GET(FeesTypeMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.FeesType,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            name: res?.name,
            amount: res?.amount,
            isRefundable: res?.isRefundable || false,
            isPaidToParul: res?.isPaidToParul || false,
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };

  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.feesTypeId ? "Edit Fees Type" : "Add Fees Type"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.feesTypeId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="feesType-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter  name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter  Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Amount"
              name="amount"
              //   rules={[{ required: true, message: "Please enter amount!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                autoFocus
                placeholder="Enter Amount"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Refundable"
              name="isRefundable"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label="Paid to Parul"
              name="isPaidToParul"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditFeesType;
