import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Upload,
} from "antd";
import "./EnquiryLead.css";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import HTTPSCalls from "../../Services/HTTPCalls";
import { AttachmentsCategory, CommunicationType } from "../../Constant/Enums";
import Dragger from "antd/es/upload/Dragger";

const NoteModel: React.FC<{
  open: boolean;
  id: string;
  type: number;
  apiData: any;
  onCancel: (rec: boolean, isUploadAtt: boolean) => void;
}> = (props) => {
  const [form] = Form.useForm();
  const [textValue, setTextValue] = React.useState<string>("");
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);

  const onSubmit = () => {
    form.validateFields().then(async (values: any) => {
      let formVal = {
        ...values,
        type: CommunicationType.Note,
        attachmentsCategory: AttachmentsCategory.Note,
        attachments: values?.attachments
          ? await Promise.all(
            values?.attachments?.fileList?.map(
              async (itm: any, index: number) => {
                let currentFileBase64 = await getBase64(
                  itm?.originFileObj as RcFile
                );
                let fileInfo = {
                  fileName: {
                    id: "",
                    name: itm?.name,
                    contentType: itm?.type,
                    length: itm?.size,
                    path: currentFileBase64,
                  },
                  category: AttachmentsCategory.Note,
                };
                return fileInfo;
              }
            )
          )
          : [],
        enquiryId: props?.apiData?.id,
      };
      console.log("formVal = ", formVal);
      setButtonLoading(true);
      setAddEditLoading(true)
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.EnquiryCommunication,
        {},
        formVal
      );
      if (response.status) {
        setAddEditLoading(false)
        form.resetFields();
        message.success("Note Submitted");
        setButtonLoading(false);
        props.onCancel(true, !!formVal?.attachments);
      } else {
        setAddEditLoading(false)
        message.error(response.message);
        setButtonLoading(false);
      }
    });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    });
  };
  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                    {
                      file.name
                    }
  
                  </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };
  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) { },
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };
  console.log("props = ", props?.apiData);
  return (
    <>
      <Modal
        title={`Add Note [${props?.apiData?.fullName}]`}
        open={props.open}
        maskClosable={false}
        onCancel={() => props.onCancel(false, false)}
        width={1080}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={() => props.onCancel(false, false)}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={buttonLoading}>
              {props?.id === "" ? "Save" : "Update"}
            </Button>
          </Space>
        }
      >
        <Divider className="marginDivider" />
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          initialValues={{ name: props.apiData?.name }}
        >
          {/* <Form.Item name="name" label="Name" className="marginbtm">
            <Input disabled={true} />
          </Form.Item> */}
          <Form.Item name="note" label="Note" className="marginbtm">
            <Input.TextArea
              rows={4}
              showCount
              placeholder="Add Note Here....."
              maxLength={100}
              onChange={(e: any) => {
                setTextValue(e.target.value);
                //console.log(textValue, "textValue");
              }}
            />
          </Form.Item>
          <Form.Item
            name={"attachments"}
            label="Add Attachment(s)"
          // rules={[
          //   {
          //     required: true,
          //     message: "this field is required",
          //   },
          // ]}
          >
            <Dragger
              {...uploadProps}
            // onPreview={async (file: UploadFile) => {
            //     console.log("testtest = ", await getBase64(
            //       file.originFileObj as any
            //     ))
            // }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NoteModel;
