import { PlusOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Divider, Input, Row, Select, Space, Spin, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Search, useNavigate } from 'react-router-dom';
import HTTPSCalls from '../../../Services/HTTPCalls';
import AddEditSubjects from '../Subjects/AddEditSubjects';

interface ICourseDetails {
    courseDetails: {
        defaultSchema?: any,
        schemas: any[],
        currentSchema: { id: string, name: string },
        department: { id: string, name: string },
        alias: string,
        course: { id: string, name: string },
    }
    onDismiss: any
}

const CourseDetails = (props: ICourseDetails) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [subjectsId, setSubjectsId] = useState<string | undefined>();
    const [courseId, setCourseId] = useState<string | undefined>();
    const [courseDetailId, setCourseDetailId] = useState(null)
    const [pageLoader, setPageLoader] = useState<boolean>(false);
    const [trigger, setTrigger] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>();
    const [schemaList, setSchemaList] = useState<any[]>();
    // const [pagingItems, setPagingItems] = useState<{
    //     totalRecords: number;
    //     currentPage: number;
    //     pageSize: number;
    // }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
    // const [listParams, setListParams] = useState({
    //     search: "",
    //     sortCol: "Name",
    //     sortDir: "ascend",
    // });
    useEffect(() => {
        let currentSchema = props?.courseDetails?.currentSchema
        if (currentSchema) {
            get_semester_with_subjects(currentSchema?.id)
        }
        else if (props?.courseDetails?.schemas?.length > 0) {
            let initialSchema = props?.courseDetails?.schemas?.[0]
            get_semester_with_subjects(initialSchema?.id)
        }
        else {
            message.error("No Schema(s)")
        }
        setSchemaList(props?.courseDetails?.schemas?.map((item: any) => ({ label: item?.name, value: item?.id })))
    }, [])
    const get_semester_with_subjects = async (schemaId: any) => {
        setPageLoader(true)
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_SEMESTERS_WITH_SUBJECTS + "?", {
            courseId: props?.courseDetails?.course?.id,
            schemaId: schemaId
        })
        if (res?.message === null && res?.result) {
            let data = res?.result?.tableData
            setDataSource(data?.map((item: any, index: any) => ({
                key: index,
                sno: item?.sno,
                name: item?.semesterName,
                subjects: item?.subjects
            })))
            setPageLoader(false)
        }
        else {
            setPageLoader(false)
            message.error(res?.message)
        }
    }
    const columns: ColumnsType<any> = [
        {
            title: "S. No",
            dataIndex: "sno",
            width: "5%",
            render: (index: any) => <p>{index}</p>,
        },
        {
            title: "Semester",
            dataIndex: "name",
            showSorterTooltip: false,
            // sorter: true,
            key: "Name",
            render: (text: any, record: any) => (
                // <span
                //     onClick={() => {
                //         setCourseId(record?.id);
                //         setOpenDrawer(true);
                //     }}
                //     style={{ cursor: "pointer", color: "#4096FF" }}
                // >
                <>
                    {text}

                </>
                // </span>
            ),
        },
        {
            title: "Subjects",
            dataIndex: "subjects",
            showSorterTooltip: false,
            // sorter: true,
            key: "subjects",
            render: (subjects: any, record: any) => (
                <>

                    {
                        subjects?.map((sub: any, index: number) => (
                            <Space size={"small"} direction='horizontal' key={sub?.id}>
                                <Typography.Link key={sub?.id} onClick={() => {
                                    setTrigger(true)
                                    setOpenDrawer(true)
                                    setSubjectsId(sub?.id)

                                }}>
                                    {sub?.name}
                                </Typography.Link>
                                {
                                    subjects?.length === index + 1
                                        ?
                                        null :
                                        <Divider type='vertical' />
                                }
                            </Space>
                        ))
                    }
                </>
            ),
        },
    ];
    return (
        <>
            {
                subjectsId &&
                <AddEditSubjects
                    setTrigger={setTrigger}
                    openDrawer={openDrawer}
                    subjectsId={subjectsId}
                    SetDrawerClose={setOpenDrawer}
                />
            }
            <Row justify="end" align="middle" style={{ marginBottom: "6px" }}>
                <Breadcrumb
                    style={{ color: "#172B4D" }}
                    items={[
                        {
                            title: "Courses",
                        },
                        // {
                        //     title: "Details",
                        //     onClick: () => {
                        //         navigate("../Course")
                        //     }
                        // },
                    ]}
                ></Breadcrumb>
            </Row>

            <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
                <Col span={24}>
                    <Row className="roundedCornerSmall bg-white" justify={'center'}>
                        <Col span={1}>
                            <Row justify="space-between">
                                <Button
                                    size='small'
                                    type="text"
                                    className="cit-add-btn mb-1"
                                    onClick={() => {
                                        props?.onDismiss()
                                    }}
                                >
                                    <ArrowLeftOutlined />

                                </Button>
                            </Row>
                        </Col>

                        <Col span={23}>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", fontWeight: 700, fontSize: 15 }}>
                                {props?.courseDetails?.department?.name}
                            </div>
                            <div style={{ display: "flex", marginTop: 10, justifyContent: "center", width: "100%", fontWeight: 500, fontSize: 14 }}>
                                {props?.courseDetails?.course?.name}({props?.courseDetails?.alias})
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ marginTop: 10 }}>
                                    <Select
                                        labelInValue
                                        defaultValue={props?.courseDetails?.defaultSchema}
                                        placeholder="Select schema" size='small'
                                        style={{ width: 200 }}
                                        options={schemaList}
                                        onSelect={(val: any) => {
                                            get_semester_with_subjects(val?.value)
                                        }} />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Input readOnly value={props?.courseDetails?.currentSchema?.name} style={{ width: 200 }} size='small' placeholder='Current schema' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col span={24} style={{ paddingTop: "12px" }}>
                    <Table
                        className="cit-table"
                        loading={{
                            spinning: pageLoader,
                            indicator: <Spin indicator={<LoadingOutlined />} />,
                        }}
                        size="small"
                        scroll={{ x: 800 }}
                        columns={columns}
                        dataSource={dataSource}
                    // pagination={{
                    //     showTotal: (total, range) =>
                    //         `${range[0]}-${range[1]} of ${total} items`,
                    //     selectPrefixCls: "custom-table-select",
                    //     current: pagingItems.currentPage,
                    //     pageSize: pagingItems.pageSize,
                    //     showSizeChanger: true,
                    //     total: pagingItems.totalRecords,
                    //     pageSizeOptions: ["15", "25", "50", "100"],
                    // }}
                    // onChange={(paging, filter, sort: any) => {
                    //     sort?.order &&
                    //         setListParams({
                    //             ...listParams,
                    //             sortDir: sort?.order,
                    //             sortCol: sort?.columnKey,
                    //         });
                    //     paging &&
                    //         setPagingItems({
                    //             ...pagingItems,
                    //             currentPage: paging?.current ?? 1,
                    //             pageSize: paging?.pageSize ?? 15,
                    //         });
                    // }}
                    />
                </Col>
            </Row>

        </>
    )
}

export default CourseDetails