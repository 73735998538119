import { combineReducers, configureStore } from "@reduxjs/toolkit";
import studentAttSlice from "./Slice/studentAttSlice";
import batchSlice from "./Slice/batchSlice";
import userSlice from "./Slice/userSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
const userPersistConfig = {
  version: 0,
  key: "user",
  storage,
};

// Wrap only the user slice with persistReducer
const persistedUserReducer = persistReducer(userPersistConfig, userSlice);

const rootReducer = combineReducers({
  student: studentAttSlice,
  batch: batchSlice,
  user: persistedUserReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
