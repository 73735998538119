import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Drawer, Button, Form, Input, Spin, message } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { CategoryMaster, CredentialMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

interface AddEditCredentialsIn {
  setTrigger: any;
  openDrawer: any;
  credentialsId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(CredentialMaster.endPoints, CredentialMaster.prefix)

const AddEditCredentials = (props: AddEditCredentialsIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = () => {
    // form.validateFields().then(async (values: any) => {
    //   setBtnLoading(true);
    //   let val = {
    //     ...values,
    //     id: props?.credentialsId,
    //     name: values?.name,
    //   };
    //   await MasterService.add_update_credentials(val).then((res) => {
    //     if (res.status) {
    //       onClose();
    //       openNotification(
    //         "success",
    //         props?.credentialsId
    //           ? "Credentials updated successfully"
    //           : "Credentials saved successfully"
    //       );
    //       props?.setTrigger((x: boolean) => !x);
    //     } else {
    //       openNotification("error", res.message);
    //     }
    //     setBtnLoading(false);
    //   });
    // });
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Credential,
        id: props?.credentialsId,
        name: values?.name,
      };
      await api.POST(CredentialMaster.endPoints.save, null, val).then((res: any) => {
        if (res.status) {
          onClose();
          openNotification(
            "success",
            props?.credentialsId
              ? "Credential updated successfully"
              : "Credential saved successfully"
          );
          props?.setTrigger((x: boolean) => !x);
        } else {
          openNotification("error", res.message);
        }
        setBtnLoading(false);
      });
    });

  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.credentialsId) {
        setLoading(true);
        getCredentialsById(props?.credentialsId);
      }
    }
  }, [props?.openDrawer]);

  const getCredentialsById = async (id: any) => {
    setLoading(true);
    await api.GET(CredentialMaster.endPoints.getById + "/" + id + "?", { masterType: MasterTypes.Credential }).then((data: IApiResponse) => {
      if (data?.result) {
        let res = data?.result
        form.setFieldsValue({
          name: res?.name,
        });
        setLoading(false);
      }
      else {
        message.error(data?.message)
        setLoading(false);
      }
    });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.credentialsId ? "Edit Credential" : "Add Credential"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.credentialsId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="credentials-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter credentials name!" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Credential Name"
                size="middle"
                onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditCredentials;
