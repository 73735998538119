import { IApisCalls } from '../ApiUrls/URLS';
import { ApiUtility, IApiResponse } from "../Utilities/ApiUtility.axios";

export interface IApisCaller {
    endPoints: IApisCalls,
    prefix?: string,
    postfix?: string,
    GET: (URL_BASE: string, URLParameters: any) => Promise<IApiResponse<any>>,
    POST: (URL_BASE: string, URLParameters: any, requestBody: any) => Promise<IApiResponse<any>>,
    POST_FROM: (URL_BASE: string, URLParameters: any, requestBody: any) => Promise<IApiResponse<any>>
}

export class ApiCalls implements IApisCaller {
    endPoints: IApisCalls;
    prefix?: string | undefined;
    postfix?: string | undefined;
    constructor(initialItem: IApisCalls, prefix: string = "", postfix = "") {
        this.endPoints = initialItem;
        this.prefix = prefix;
        this.postfix = postfix;
    }
    GET = async (URL_BASE: string, URLParameters: any) => {
        return await ApiUtility.get(
            (this.prefix ?? "") + URL_BASE + new URLSearchParams({ ...URLParameters }).toString()
        );
    };

    POST = async (
        URL_BASE: string,
        URLParameters: any = {},
        requestBody: any
    ) => {
        return await ApiUtility.post(
            (this.prefix ?? "") + URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
            requestBody
        );
    };
    POST_FROM = async (
        URL_BASE: string,
        URLParameters: any = {},
        requestBody: any
    ) => {
        return await ApiUtility.postForm(
            this.prefix ?? "" + URL_BASE + new URLSearchParams({ ...URLParameters }).toString(),
            requestBody
        );
    };
}
