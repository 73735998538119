import * as React from "react";
import { useContext, useState, useEffect } from "react";
import LoginImg from "../../images/New_Login_Img.png";
import newLogoImg from "../../images/CITLogoDash.svg";
import { Button, Col, Divider, Form, Input, Row, message } from "antd";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../Services/AuthService";
import { useUser } from "../../Context/UserContext";
import { UserOutlined } from "@ant-design/icons";
import { setUserData } from "../../Store/Slice/userSlice";
import { useDispatch } from "react-redux";

const Login: React.FC = () => {
  const { setCurrentUser } = useUser();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UserLogin = () => {
    form
      .validateFields()
      .then(async (val) => {
        setBtnLoading(true);
        await AuthService.login(val.username.trim(), val.password.trim()).then(
          (user: any) => {
            if (user?.token != null) {
              //update usercontext value before switching to dashboard
              setCurrentUser(user);
              dispatch(setUserData(user))
              navigate("/dashboard");
            }
            if (user == null) {
              setBtnLoading(false);
              throw message.error("Invalid username or password");
            }
          }
        );
      })
      .catch(() => {
        return false;
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      UserLogin();
    }
  };
  

  return (
    <Row align="middle" className="h100vh">
      <Col
        span={12}
        style={
          window.innerWidth < 992 ? { display: "none" } : { display: "block" }
        }
      >
        <Row style={{ borderRight: "1px solid #ff000091" }}>
          <Col xl={20} lg={23}>
            <Row justify="end" >
              <img src={LoginImg} width={550} alt="loginImg" />
            </Row>
          </Col>
        </Row>
      </Col >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        style={{ backgroundColor: "#fff", height: "100%" }}
      >
        <Row className="h100" align="middle">
          <Col xxl={16} xl={18} lg={20} md={17} sm={18} xs={21}>
            <Row align="middle" justify="end">
              <Col
                xl={14}
                lg={16}
                md={13}
                sm={15}
                xs={21}
                className="pb-5 border-bottom"
              >
                <img
                  src={newLogoImg}
                  height="80px"
                  className="cursor"
                  alt="Logo"
                />
              </Col>
              <Col
                xl={14}
                lg={16}
                md={13}
                sm={15}
                xs={21}
                className="pt-4 pb-5"
              >
                <span
                  style={{ color: "#393939", fontSize: 20, fontWeight: 600 }}
                >
                  Log in
                </span>
              </Col>
              <Col xl={14} lg={16} md={13} sm={15} xs={21}>
                <Form
                  form={form}
                  name="login"
                  initialValues={{ remember: true }}
                  className="login-form"
                  onKeyDown={handleKeyDown}
                >
                  <Row>
                    <Col
                      style={{
                        color: "#686868",
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                      span={24}
                      className="loginnames mb-1"
                    >
                      Email
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="username"
                        rules={[
                          { required: true, message: "Please enter username!" },
                        ]}
                      >
                        <Input
                          suffix={<UserOutlined style={{ color: "white" }} />}
                          className="loginInput"
                          autoComplete="on"
                          autoFocus
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        color: "#686868",
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                      span={24}
                      className="loginnames mb-1"
                    >
                      Password
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: "Please enter password!" },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          className="loginInput"
                          autoComplete="on"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ margin: "0px 0px 20px 0px" }} justify="end">
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item name="remember" valuePropName="checked" noStyle>*/}
                    {/*        <Checkbox className="lgname">Remember me</Checkbox>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col>*/}
                    {/*    <Link to="/forgot-password">Forgot password?</Link>*/}
                    {/*</Col>*/}
                  </Row>
                  <Form.Item>
                    <Row justify="center" className="pb-5 border-bottom">
                      <Col>
                        <Button
                          style={{ backgroundColor: "#de2a1b", width: "324px" }}
                          type="primary"
                          loading={btnLoading}
                          onClick={() => UserLogin()}
                        >
                          LOG IN
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row >
  );
};

export default Login;
