import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  Select,
  DatePicker,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { PlatformMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface AddEditSessionIn {
  setTrigger: any;
  openDrawer: any;
  sessionId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(PlatformMaster.endPoints, PlatformMaster.prefix);

const AddEditSession = (props: AddEditSessionIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [courseData, setCourseData] = useState<any[]>([]);
  const { RangePicker } = DatePicker;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      setCourseData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Course
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Session,
        id: props?.sessionId,
        name: values?.name,
        sessionCode: values?.sessionCode,
        sessionCourse: {
          id: values?.sessionCourse?.value,
          name: values?.sessionCourse?.label,
        },
      };
      await api
        .POST(PlatformMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.sessionId
                ? "Session updated successfully"
                : "Session saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
      //  console.log(val,"SessionValues");
    });
  };

  useEffect(() => {
    getMaster();
    if (props?.openDrawer) {
      if (props?.sessionId) {
        setLoading(true);
        getPlatformById(props?.sessionId);
      }
    }
  }, [props?.openDrawer]);

  const getPlatformById = async (id: any) => {
    setLoading(true);
    await api
      .GET(PlatformMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Session,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            name: res?.name,
            sessionCode: res?.sessionCode,
            sessionCourse: {
              value: res?.sessionCourse?.id,
              label: res?.sessionCourse?.name,
            },
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.sessionId ? "Edit Session" : "Add Session"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.sessionId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="Session-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Choose Course"
              name="sessionCourse"
              rules={[{ required: true, message: "Please select course!" }]}
            >
              <Select
                placeholder="Choose Course"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={courseData}
              />
            </Form.Item>
            <Form.Item
              label="Select Duration"
              rules={[
                {
                  required: true,
                  message: "Select duration",
                },
              ]}
              name="duration"
            >
              <RangePicker
                // picker="year"
                size="middle"
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                onChange={(date:any) => {
                  if (date) {
                    form.setFieldValue("name", 
                      `Session ${date[0]?.format('YYYY')}- ${date[1]?.format('YYYY')}`
                    )
                  }
                  else{
                    form.setFieldValue("name", " ")
                  }
                 console.log(date,"durationTime")
                }}
              />
            </Form.Item>
            <Form.Item
              label="Session Name"
              name="name"
              // rules={[
              //   { required: true, message: "Please enter session name!" },
              // ]}
            >
              <Input
                autoFocus
                placeholder="Session name auto filled when Select duration"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Session Code"
              name="sessionCode"
              rules={[
                { required: true, message: "Please enter session code!" },
              ]}
            >
              <Input placeholder="Enter Session Code" />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditSession;
