import Axios from 'axios';
import { ApiUtility, IApiResponse } from '../Utilities/ApiUtility.axios';
import { Utils } from '../Utilities/Utils';
import { InMemoryJWT, LocalStorageKeyToken } from '../Utilities/InMemoryJWT';
import { message } from 'antd';

export interface IUserLoginResponse {
  name?: string;
  token: string;
  tokenExpiry: number;
}

export const AuthService = {
  login: async (username: string, password: string): Promise<IUserLoginResponse | null> => {

    //console.log(process.env.REACT_APP_API_BASE_URL)
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      //`${process.env.REACT_APP_API_BASE_URL}/Auth/Login`,
      `Auth/Login`,
      { username, password } as any,
      ApiUtility._axiosOptions({ withCredentials: true })
    );
    if (response.status === 200) {
      //debugger
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);
        Utils.setLocal('tp-user', result);
        // Utils.setSession('tp-user', result);     


        return response.data.result;
      }
      //else if (response.data && response.data.message) throw message.error(response.data.message);
    }
    return null;
  },

  loginAs: async (pid: string, id: string) => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      '/auth/' + pid + '/loginas/' + id,
      {} as any,
      ApiUtility._axiosOptions({
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        withCredentials: true,
      })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);

        return response.data.result;
      } else if (response.data && response.data.message) throw message.error(response.data.message);

      return null;
    }
  },


  forgotPassword: async (email: string) => {
    const response = await Axios.post<IApiResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/Auth/ForgotPassword`,
      { email },
      ApiUtility._axiosOptions()
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        return response.data;
      } else if (response.data && response.data.message) {
        return response.data;
      }
    }
  },

  resetPassword: async (
    userId: string | undefined,
    password: string,
    confirmpassword: string,
    code: string | undefined
  ) => {
    const response = await Axios.post<IApiResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/Auth/ResetPassword`,
      { userId, password, confirmpassword, code },
      ApiUtility._axiosOptions()
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        return response.data;
      } else if (response.data && response.data.message) {
        return response.data;
      }
    }
  },

  logout: async () => {
    // remove user from local storage to log user out
    InMemoryJWT.ereaseToken(true);
    localStorage.clear();
    return Promise.resolve();
  },

  getAuthToken: (): string | null =>
    InMemoryJWT.getToken() || Utils.getLocal(LocalStorageKeyToken),

  isAuthenticated: (): boolean | false => AuthService.getAuthToken() !== null,

  userRole() {
    if (AuthService.getAuthToken() != null) {
      let user = Utils.getLocal('tp-user');
      return user.role;
    }
  },

};

export const Roles = {
  SuperAdmin: 'SUPERADMIN',
  Admin: 'ADMIN',
  HrManager: 'HRMANAGER',
  HrExecutive: 'HREXECUTIVE',
  Employee: 'EMPLOYEE',
  Candidate: 'CANDIDATE',
  TeamLeader: 'TEAMLEADER',
  Interviewer: 'INTERVIEWER',
};
