import { hookstate, State, useHookstate } from '@hookstate/core';
import { devtools } from '@hookstate/devtools';
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { ICompany, IFeatures} from '../Types/CommonTypes';
import { ApiUtility } from '../Utilities/ApiUtility.axios';
import { Utils } from '../Utilities/Utils';
import { InMemoryJWT } from '../Utilities/InMemoryJWT';
import { FullPageSpinner } from '../Common/FullPageSpinner';
import { useUser } from './UserContext';



export enum AppFeatures {
    Resume = 1,
    Attendance = 2,
    Payroll = 3,
    Fixed = 4,
    Accured = 5
}

interface ICompanyContextState {
    loaded: boolean;
    company: Partial<ICompany>;    
}


export const stateUserContext = hookstate<ICompanyContextState>(
    {
        loaded: false,
        company: {},
    },
    devtools({ key: 'company-context-state' })
);


export const userId = hookstate<string>("");



const wrapCompanyContextState = (s: State<ICompanyContextState>) => {
  
    // debugger
    return {
        hasCompany: () => (s.company.get() ? true : false),
        getCompany: () => s.company.get(),
        getCompanyId: () => s.company.id.get() || 0,
        getCompanyName: () => s.company.name.get() || '',
        getPSlipFormat: () => s.company.pSlipformat.get() || 0,
        getProbationDays: () => s.company.probationDays.get() || 0,
        isFeatureExists: (feature: AppFeatures) =>
            (s.company.features.value && s.company.features.value.some((v) => v?.fId === feature)) || false,
        getAppFeatures: () => (s.company.features.value || []).map((f) => f.fId),
        stateUserContext,
        userId
    };
};
export const useCompany = () =>
    wrapCompanyContextState(useHookstate(stateUserContext));

interface ICompanyContextProps {
    getCompanyDetailsFromServer: () => Promise<void>;
}

export const CompanyContext = React.createContext<ICompanyContextProps>({} as any);
export function CompanyProvider(props: { children?: any }) {
    const state = useHookstate(stateUserContext);
    const { getCompanyId, isAuthenticated } = useUser();
    const getCompanyDetailsFromServer = async () => {        

        let company = Utils.getLocal('tp-company');        
        //let company = Utils.getSession('tp-company');        
        //commented by geeta to see the updated payslip format detail
        //if (!company) {
            company = await ApiUtility.getResult(`${process.env.REACT_APP_API_BASE_URL}/Company/GetById/${getCompanyId()}`);                  
        Utils.setLocal('tp-company', company);
        //Utils.setSession('tp-company', company);
        //}
        if (company) {
            //debugger
            state.merge({
                company: company,                
                loaded: true,
            });            
        } else 
        state.merge({ loaded: true, company: {} });
    };

    React.useEffect(() => {
        const fetchData = async () => {            
            if (isAuthenticated()) {
                getCompanyDetailsFromServer();
            } else {
                state.merge({ loaded: true });
            }
        };
        fetchData();
        return () => {
            InMemoryJWT.abortRefreshToken();
            console.debug('User context unloaded');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.loaded.value) {
        return <FullPageSpinner label='Loading...' />;
    }
    //console.debug('user-context rendered');
    return (
        <CompanyContext.Provider
            value={{
                getCompanyDetailsFromServer,
            }}
            {...props}
        />
    );
}

export function useCompanyContext() {
    const context = React.useContext(CompanyContext);
    if (context === undefined) {
        throw new Error(`useUserContext must be used within a UserProvider`);
    }
    return context;
}

