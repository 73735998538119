import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { MasterTypes } from '../../../Constant/Enums';
import { CloseOutlined, MinusOutlined } from '@ant-design/icons';

interface IAddEditSubject{
    data:any,
    form:FormInstance<any>
}
const AddEditSections = ({data,form}:IAddEditSubject) => {
const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  useEffect(()=>{
    if(!!data)
    {
        form.setFieldsValue({...data})
        console.log(data,"data");
    }
    
  },[])

  return (
    <Form
    form={form}
    name="Subjects-form"
    className="DrawerPadding"
    colon={false}
    autoComplete="off"
    {...formItemLayout}
    labelAlign="left"
    labelCol={{ span: 4 }}
    requiredMark={false}
  >
          <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item
        label="Course"
        name="course"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Course"} disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name="schema"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Schema"} disabled />
      </Form.Item>
      <Form.Item
        label="Batch"
        name="batch"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Batch"} disabled />
      </Form.Item>
    <Form.Item
      label="Name"
      name="name"
      rules={[
        { required: true, message: "field is required" },
      ]}
    >
      <Input
        autoFocus
        placeholder="Enter Name"
        onInput={(e: any) =>
          (e.target.value =
            e.target.value.length > 1
              ? e.target.value
              : e.target.value.toUpperCase())
        }
      />
    </Form.Item>
  </Form>
  )
}

export default AddEditSections