import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Page404 = () => {
    const navigate=useNavigate()
    return (
        <>
            <div style={{display:"flex",justifyContent:"center",height:`calc(100vh-10px)`,alignItems:"center"}}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={()=>navigate(-1)}>Back Home</Button>}
                />
            </div>
        </>
    )
}

export default Page404