import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import SubjectAllocationAdd from "./SubjectAllocationAdd";

const SubjectAllocationList: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);


  return (
    <>
      <Row>
        <Col span={24}>
          <Button type="primary" onClick={() =>{
            setOpenDrawer(true);     
          }}>
           SubjectAllocation
          </Button>
        </Col>
      </Row>
      {openDrawer && (
        <>
        <SubjectAllocationAdd openDrawer= {openDrawer} setOpenDrawer={setOpenDrawer}/>
        </>
      )}
    </>
  );
};

export default SubjectAllocationList;
