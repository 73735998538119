import {
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Row,
  Space,
  Tooltip,
  Upload,
} from "antd";
import { RcFile } from "antd/es/upload";
import axios from "axios";
import React, { useEffect } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";

const AttachmentsDownload = (props: any) => {
  const [form] = Form.useForm();
  useEffect(() => {
    let attachments = props?.propsData?.attachments?.map((e: any) => {
      return {
        ...e,
        name: e?.fileName,
        thumbUrl: e?.path,
      };
    });
    let data = {
      attachments,
    };

    form.setFieldsValue(data);
    form.setFieldValue("attachments", attachments);
    console.log(props?.propsData, "InsideFees", attachments);
  }, [props?.propsData]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const handleBeforeUpload = async (file: any) => {
    if (file.type === "application/pdf") {
      let base64 = (await getBase64(file as RcFile)).toString();
      file.thumbUrl = base64;
      console.log("base64", base64);
    } else {
      handelFile(file);
    }
    return false;
  };
  const handlePreview = async (file: any) => {
    let url: any = null;
    try {
      if (!file.url && !file.preview) {
        const fileToPreview = file.originFileObj || file;
        url = await handelFile(fileToPreview);
      }
    } catch (error) {
      console.error("Error getting base64:", error);
      url = null;
    }

    console.log("onPreview", url);
    window.open(url || file?.path, "_blank");
  };

  const handelFile = async (file: any) => {
    let currentFileBase64 = await getBase64(file as RcFile);
    console.log("currentFileBase", currentFileBase64);
    if (file instanceof Blob) {
      const blob = new Blob([file], { type: file.type });
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      console.error("File is not a valid Blob or File type");
      return null;
    }
  };
  const handleDownload = (file: any) => {
    // Handle the download logic here
    const link = document.createElement("a");
    link.href = file.url || "https://your-download-url.com/path/to/file";
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Drawer
        title="Attachments"
        width={500}
        onClose={props?.onClose}
        open={true}
        extra={
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              props?.onClose();
            }}
          ></Button>
        }
        footer={
          <div style={{ textAlign: "end" }}>
            <Space>
              <Button
                onClick={() => {
                  props?.onClose();
                }}
              >
                Cancel
              </Button>
              <Button type="primary">Submit</Button>
            </Space>
          </div>
        }
      >
        <Form form={form}>
          <Form.Item name="attachments" valuePropName="fileList">
            <Upload
              name="logo"
              action="/upload.do"
              listType="picture"
              onPreview={handlePreview}
              beforeUpload={(file) => {
                handleBeforeUpload(file);
                return false;
              }}
              itemRender={(originNode, file:any) => {
                // const downloadFile = () => {
                //   console.log("File object properties:", file);

                //   let fileURL =
                //     file.url || (file.response && file.response.url);

                //   if (!fileURL && file.originFileObj instanceof File) {
                //     fileURL = URL.createObjectURL(file.originFileObj);
                //   }

                //   if (fileURL) {
                //     const link = document.createElement("a");
                //     link.href = fileURL;
                //     link.download = file.name || "downloaded_file";
                //     document.body.appendChild(link);
                //     link.click();
                //     document.body.removeChild(link);

                //     if (!file.url && file.originFileObj instanceof File) {
                //       URL.revokeObjectURL(fileURL);
                //     }
                //   } else {
                //     message.error(
                //       "Download failed: File URL is missing or invalid."
                //     );
                //   }
                // };
                const downloadFile = async (fileUrl:any) => {
                  try {
                      const response = await axios.get(`/Payment/DownloadFile/download?url=${encodeURIComponent(file?.path)}`, {
                          responseType: 'blob',
                          baseURL: process.env.REACT_APP_BASE_URL
                      });
              
                      const fileName = file.path.split('/').pop() || 'downloaded_file';
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fileName);
              
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);  // Clean up the DOM
                      window.URL.revokeObjectURL(url);  // Free up memory
                  } catch (error) {
                      console.error('Error downloading the file', error);
                  }
              };
              
                return (
                  <Row gutter={20} style={{ marginLeft: "25px" }}>
                    <Col span={20}>{originNode}</Col>
                    <Col span={4}>
                      <Button
                        style={{ marginTop: 20, borderRadius: "50%" }}
                        icon={
                          <Tooltip title="Download">
                            <DownloadOutlined style={{ cursor: "pointer" }} />
                          </Tooltip>
                        }
                        onClick={downloadFile}
                      />
                    </Col>
                  </Row>
                );
              }}
              showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AttachmentsDownload;
