import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  TimePicker,
  Select,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { PlatformMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import dayjs from "dayjs";

enum ReminderTiming {
  Undefined,
  Before,
  After,
}
interface AddEditReminderIn {
  setTrigger: any;
  openDrawer: any;
  reminderId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(PlatformMaster.endPoints, PlatformMaster.prefix);

const AddEditReminder = (props: AddEditReminderIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Reminder,
        id: props?.reminderId,
        time: values?.time,
        reminderTiming: values?.reminderTiming
      };
      await api
        .POST(PlatformMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res?.status) {
            onClose();
            openNotification(
              "success",
              props?.reminderId
                ? "Reminder updated successfully"
                : "Reminder saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
      console.log("testVal",val);
      
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.reminderId) {
        setLoading(true);
        getPlatformById(props?.reminderId);
      }
    }
  }, [props?.openDrawer]);

  const getPlatformById = async (id: any) => {
    setLoading(true);
    await api
      .GET(PlatformMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Reminder,
      })
      .then((data: IApiResponse) => {
        debugger;
        if ( data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            time: dayjs(res?.time).format("h:mm A"),
            reminderTiming: res?.reminderTiming === 2 ? "After" : "Before"
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.reminderId ? "Edit Reminder" : "Add Reminder"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.reminderId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="Reminder-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            // size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
            
          >
            <Form.Item
              label="Time"
              name="time"
              rules={[
                { required: true, message: "Please enter Time!" },
              ]}
            >
             <TimePicker use12Hours format="h:mm a"  style={{width:'100%'}} />
            </Form.Item>
            <Form.Item
             label="Reminder Timing"
              name="reminderTiming"
              rules={[
                { required: true, message: "Please enter Reminder name!" },
              ]}
            >
              <Select
                placeholder="Choose Reminder Timing"
                options={[
                  {
                    value: ReminderTiming.Before,
                    label: "Before",
                  },
                  {
                    value: ReminderTiming.After,
                    label: "After ",
                  },
                ]}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditReminder;
