import * as React from "react";
import { useContext, useState, useEffect,useRef } from "react";
import { Row, Col, Button, Select, Tabs, Typography, Radio } from "antd";
import { MasterTypes } from "../../../../Constant/Enums";
import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import ".././ManageWeight/Weigth.css";
import IndivitualTab from "./IndivitualTab";
import { adminContext } from "../../../../Common/PageRoute";

const { Option } = Select;

interface I_Props {
  totalError: boolean;
  setTotalError: Function;
  setupChange: Function;
}

const IndivitualWeight: React.FC<I_Props> = ({
  totalError,
  setTotalError,
  setupChange,
}) => {
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const { openNotification } = useContext(adminContext);
  const [start, setStart] = useState<any>(0);
  const [length, setLength] = useState<any>(15);

  const [courseId, setCourseId] = useState<string>("string");
  const [schemaId, setSchemaId] = useState<string>("string");
  const[loading,setLoading] = useState<boolean>(false)
  const [semesterId, setSemesterId] = useState<string>("string");
  const [semesterName, setSemesterName]: any = useState("string");
  const [tabWithDetail,setTabWithDetail]= useState({
    course:{
      id:"",
      name:""
    },
    semester:{
      id:"",
      name:""
    },
    schema:{
      id:"",
      name:""
    }
  })
  const [showTab, setShowTab]: any = useState(false);

  const tabRefs = useRef<any[]>([]);

  const { data: masterDetail, isLoading: masterLoading } = useQuery({
    queryKey: ["MasterDetail", courseId, schemaId, semesterId],
    queryFn: async () => {
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS,
        {},
        {
          courseId,
          schemaId,
          semesterId,
        }
      );
      return {
        courses: res?.result?.courses?.map((itm: any) => ({
          value: itm?.id,
          label: itm?.name,
        })),
        schemas: res?.result?.schemas?.map((itm: any) => ({
          value: itm?.id,
          label: itm?.name,
        })),
        semesters: res?.result?.semesters?.map((itm: any) => ({
          value: itm?.id,
          label: itm?.name,
        })),
        subjects: res?.result?.subjects?.map((itm: any) => ({
          value: itm?.id,
          label: itm?.name,
        })),
      };
    },
  });

  useEffect(() => {
    setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
    setLength(pagingItems?.pageSize);
  }, [pagingItems]);

  const { data: AssessmentCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ["assessmentCategory", start, length],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: start,
            length: length,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          return res?.result?.items.map((item: any) => ({
            ...item,
            key: item.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });

  const handleSave = async () => {
    const allTabData = tabRefs.current.map((tabRef) => tabRef?.getData());
    try {
      var IsCommon=false;
        setLoading(true)
        await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_ASSESSMENTS_WEIGHT_DETAILS+"?",{IsCommon},allTabData)
        openNotification("success", "Weight Save SuccessFully");
      } catch (error) {
        setLoading(false);
        openNotification("warning", error);
      }finally{ 
        setLoading(false);
      }
    console.log("All Tab Data: ", allTabData);
  };

  return (
    <>
    <div style={{minHeight:755}}>
      <Row justify={"space-between"} >
        <Col span={12}>
          <Typography.Text
            style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
          >
            Setup Weight:
          </Typography.Text>
          <Radio.Group
            name="setupGroup"
            defaultValue={"no"}
            onChange={(e) => setupChange(e)}
          >
            <Radio value={"yes"}>Yes</Radio>
            <Radio value={"no"}>No</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ fontWeight: 500 }}
            disabled={totalError ? true : false}
            onClick={handleSave}
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select Course"
            optionFilterProp="label"
            allowClear
            loading={masterLoading}
            onSelect={(e: any,op:any) => {
              setCourseId(e)
              
              setTabWithDetail({...tabWithDetail,course:{id:op?.value,name:op?.children}})
            }}
          >
            {masterDetail?.courses?.map((course: any) => (
              <Option key={course.value} value={course.value}>
                {course.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            showSearch
            style={{ width: 200, marginLeft: 10 }}
            placeholder="Select Schema"
            optionFilterProp="label"
            allowClear
            loading={masterLoading}
            onSelect={(e: any,op:any) => {
              setSchemaId(e)
              setTabWithDetail({...tabWithDetail,schema:{id:op?.value,name:op?.children}})
            }}
          >
            {masterDetail?.schemas?.map((schema: any) => (
              <Option key={schema.value} value={schema.value}>
                {schema.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            showSearch
            style={{ width: 200, marginLeft: 10 }}
            placeholder="Select Semester"
            optionFilterProp="label"
            allowClear
            loading={masterLoading}
            onSelect={(e: any,op:any) => {
              setSemesterName(op.children);
              setSemesterId(e)
              setTabWithDetail({...tabWithDetail,semester:{id:op?.value,name:op?.children}})
            }}
            
          >
            {masterDetail?.semesters?.map((semester: any) => (
              <Option key={semester.value} value={semester.value}>
                {semester.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={
              masterDetail?.subjects && masterDetail?.subjects.length > 0
                ? false
                : true
            }
            style={{ fontWeight: 500, marginLeft: 20 }}
            onClick={() => {
              setShowTab(true);
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        {showTab && (
          <Col span={24}>
            <Tabs
              size="small"
              type="card"
              items={masterDetail?.subjects?.map((value: any, i: number) => {
                const id = String(i + 1);
                return {
                  label: `${value?.label}`,
                  key: id,
                  children: (
                    <IndivitualTab
                      ref={(el: any) => (tabRefs.current[i] = el)}
                      subject={{ id: value.value, name: value.label }}  
                      schema={{ id: semesterId, name: semesterName }}  
                      totalError={totalError}
                      tabWithDetail={tabWithDetail}
                      setTotalError={setTotalError}
                      AssessmentCategory={AssessmentCategory}
                      categoryLoading={categoryLoading}
                    />
                  ),
                };
              })}
            />
          </Col>
        )}
      </Row>
      </div>
    </>
  );
};

export default IndivitualWeight;
