import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Spin, Button, Col, Row, Table, Breadcrumb, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ArrowLeftOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import MasterService from "../../../Services/MasterService";
import AddEditCourse from "./AddEditCourse";
import dayjs from "dayjs";
import "../.../../../../Custom.css";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { CourseMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import CourseDetails from "./CourseDetails";
import { Utils } from "../../../Utilities/Utils";
interface ICourse {
  key: string;
  sNo: number;
  id: string;
  name: string;
}
const api = new ApiCalls(CourseMaster.endPoints, CourseMaster.prefix);

const Course = () => {
  const navigate = useNavigate();
  const columns: ColumnsType<ICourse> = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: "5%",
      render: (index: any) => <p>{index}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      showSorterTooltip: false,
      // sorter: true,
      key: "Name",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setCourseId(record?.id);
            setOpenDrawer(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "Credit",
      render: (text: any) => Utils.getFormattedNumber(text),
    },
    {
      title: "CGPA",
      dataIndex: "cgpa",
      key: "CGPA",
      render: (text: any) => Utils.getFormattedNumber(text),
    },
    // {
    //   title: "Alias",
    //   dataIndex: "alias",
    //   showSorterTooltip: false,
    //   // sorter: true,
    //   key: "Alias",
    //   render: (text: any) => text,

    //   // render:
    // },
    // {
    //   title: "Department",
    //   dataIndex: "department",
    //   showSorterTooltip: false,
    //   // sorter: true,
    //   key: "department",
    //   render: (department: any) => department?.name,

    //   // render:
    // },
    // {
    //   title: " ",
    //   dataIndex: "details",
    //   showSorterTooltip: false,
    //   // sorter: true,
    //   key: "details",
    //   render: (text: any, record: any) => (
    //     <span
    //       onClick={() => {
    //         setCourseDetails({
    //           ...record,
    //           course: { id: record?.id, name: record?.name },
    //           defaultSchema:
    //             // check current schema
    //             record?.currentSchema
    //               ? {
    //                   label: record?.currentSchema?.name,
    //                   value: record?.currentSchema?.id,
    //                 }
    //               : // check schemas list and initial schema
    //               record?.schemas?.length > 0
    //               ? {
    //                   label: record?.schemas[0]?.name,
    //                   value: record?.schemas[0]?.id,
    //                 }
    //               : null,
    //         });
    //       }}
    //       style={{ cursor: "pointer", color: "#4096FF" }}
    //     >
    //       Details
    //     </span>
    //   ),
    // },
    {
      title: " ",
      width: "5%",
      render: (_: any, record: any) => (
        <Row justify="end">
          <FormOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd" }}
            onClick={() => {
              setCourseId(record?.id);
              setOpenDrawer(true);
            }}
          />
        </Row>
      ),
    },
  ];
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<string | undefined>();
  const [courseDetail, setCourseDetails] = useState(null);
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [allCourse, setAllCourse] = useState<ICourse[]>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  useEffect(() => {
    setPageLoader(true);
    get_course(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems]);

  const get_course = async (start: any, length: any) => {
    await api
      .GET(CourseMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Course,
        start: start,
        length: length,
        search: listParams.search,
        sortCol: listParams.sortCol,
        sortDir: listParams.sortDir,
      })
      .then((res: IApiResponse) => {
        if (/*res?.errors === null &&*/ res?.result) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          setAllCourse(
            res?.result?.items.map((r: any, i: any) => ({
              key: i,
              sno: r?.sno,
              id: r?.id,
              name: r?.name,
              credit:r?.credit,
              cgpa:r?.cgpa,
              alias: r?.alias,
              department: r?.department,
              currentSchema: r?.currentSchema,
              schemas: r?.schemas,
              // c: r?.createdBy.date
            }))
          );
          setPageLoader(false);
        } else {
          message.error(res?.message);
          setPageLoader(false);
        }
      });
  };

  const setListParamsAndRefresh = (value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams({ ...listParams, search: value });
  };

  return (
    <>
      {courseDetail ? (
        <CourseDetails
          courseDetails={courseDetail}
          onDismiss={() => {
            setCourseDetails(null);
          }}
        />
      ) : (
        <>
          <AddEditCourse
            setTrigger={setTrigger}
            openDrawer={openDrawer}
            courseId={courseId}
            SetDrawerClose={setOpenDrawer}
          />
          <Row
            justify={"space-between"}
            align="middle"
            style={{ marginBottom: "6px" }}
          >
            <ArrowLeftOutlined
              style={{ color: "#1677ff" }}
              onClick={() => {
                navigate("/settings");
              }}
            />
            <Breadcrumb
              style={{ color: "#172B4D" }}
              items={[
                {
                  title: "Course",
                },
              ]}
            ></Breadcrumb>
          </Row>
          <Row
            className="roundedCornerSmall bg-white"
            style={{ padding: "16px" }}
          >
            <Col span={24}>
              <Row justify="space-between">
                <Button
                  type="primary"
                  className="cit-add-btn mb-1"
                  onClick={() => {
                    setCourseId(undefined);
                    setOpenDrawer(true);
                  }}
                >
                  <PlusOutlined />
                  Course
                </Button>

                <Search
                  size="middle"
                  placeholder="Search..."
                  allowClear
                  className="att-search-input mb-1"
                  onSearch={(val: string) => setListParamsAndRefresh(val)}
                  onChange={(e: any) =>
                    e.target.value === "" ? setListParamsAndRefresh("") : null
                  }
                  style={{ width: 170 }}
                />
              </Row>
            </Col>

            <Col span={24} style={{ paddingTop: "12px" }}>
              <Table
                className="cit-table"
                loading={{
                  spinning: pageLoader,
                  indicator: <Spin indicator={<LoadingOutlined />} />,
                }}
                size="small"
                scroll={{ x: 800 }}
                columns={columns}
                dataSource={allCourse}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  selectPrefixCls: "custom-table-select",
                  current: pagingItems.currentPage,
                  pageSize: pagingItems.pageSize,
                  showSizeChanger: true,
                  total: pagingItems.totalRecords,
                  pageSizeOptions: ["15", "25", "50", "100"],
                }}
                onChange={(paging, filter, sort: any) => {
                  sort?.order &&
                    setListParams({
                      ...listParams,
                      sortDir: sort?.order,
                      sortCol: sort?.columnKey,
                    });
                  paging &&
                    setPagingItems({
                      ...pagingItems,
                      currentPage: paging?.current ?? 1,
                      pageSize: paging?.pageSize ?? 15,
                    });
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Course;
