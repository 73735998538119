import * as React from "react";
import { useContext, useState, useEffect } from "react";
import type { TableColumnsType ,TableProps } from "antd";
import { ConfigProvider, Select, Spin, Table } from "antd";
import Search from "antd/es/input/Search";
import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { pageSizeOption } from "../Users/UserList";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
import '../EnquiryLeadModel/EnquiryLead.css'

interface NoteDataType {
  key: string;
  createdBy: any;
  date:any;
  note:any;
}

const NoteTable: React.FC<{
  type: number;
  apiData: any;
  noteContentRefresh: boolean;
}> = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [lodading, setLoading] = useState<boolean>(false);
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [approveRequestList, setApproveRequestList]: any = useState([]);
  const [showIsApproveAll, setShowIsApproveAll] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });

  const [listParams, setListParams] = useState({
    EnquiryId: props?.apiData?.id,
    type: props?.type,
    start: 0,
    length: 10,
    sortCol: "",
    sortDir: "",
    note:"",
    phoneNo:"",
    callType:0,
    outComeType:0,
    meetingType:0
  });

  console.log("listParams = ", listParams)
  console.log("apiData = ", props?.apiData)
  const tableLoading = {
    spinning: lodading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  changeListParams(inputName, value);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  changeListParams(inputName);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
              // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className="CitSearchInput"
                allowClear
                
                onChange={(e) => changeListParams(inputName, e.target?.value)}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };

  const columns: TableColumnsType<NoteDataType> = [
    {
      title: (
        <THeader
          title={"Note"}
          inputName={"note"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "note",
      key: "",
      width: "30%",
    },
    {
      title: (
        <THeader
          title={"Created by"}
          inputName={"createdBy"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "createdBy",
      key: "",
      width: "25%",
    },
    /*{
      title: "Date",
      dataIndex: "date",
      key: "",
      width: "25%",
      sorter: (a, b) => a.date.length - b.date.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (_, record: any) => <MoreOutlined />,
    },*/
  ];
  const onChange: TableProps<NoteDataType>['onChange'] = (pagination, sorter:any) => {
    setListParams((p)=>{
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p
    })  
    if (pagination.current !== undefined && pagination.pageSize !== undefined) {
      changeListParams("start", (pagination.current - 1) * pagination.pageSize);
      changeListParams("length", pagination.pageSize);
     // setExpandedRowKeys([]);
    }
   // setShouldRefresh((x) => !x);
  };

  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];
      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item);
      });
      setApproveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsApproveAll(true)
        : setShowIsApproveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };

  const getList = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.EnquiryCommunication_GETALL + "?",
      {
        EnquiryId: listParams?.EnquiryId,
        type: listParams?.type,
        start: listParams?.start,
        length: listParams?.length,
        sortCol: listParams?.sortCol,
        sortDir: listParams?.sortDir,
        note: listParams?.note,
        phoneNo:listParams?.phoneNo,
        callType:listParams?.callType,
        outComeType:listParams?.outComeType,
        meetingType:listParams?.meetingType
      }
    );
    setTotalNoOfRecords(res?.result?.totalRecords); 
    setDataSource(
      res?.result?.map((itm: any, indx: number) => ({
        ...itm,
        key: indx,
        createdBy: dayjs(itm?._createdBy?.date).format('DD MMM YYYY'),
        date: dayjs(itm?.date).format("DD MMM YYYY"),
      }))
    );
    console.log(dataSource, "dataTask");
    if (res?.status) {
      setLoading(false);
    } else {
      setLoading(false);
    }
    console.log(res, "listAllTask");
  };

  React.useEffect(() => {
    getList();
  }, [props?.noteContentRefresh, refresh]);
  return (
    <>
      <Table
        className={"Tabel-style"}
        columns={columns}
        dataSource={dataSource}
        size="small"
        // dataSource={dataSource}
        loading={tableLoading}
        // rowSelection={rowSelection}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            changeListParams("start", (page - 1) * pageSize);
            changeListParams("length", pageSize);
            setRefresh((x) => !x);
          },
          current: listParams.start / listParams.length + 1,
          pageSize: listParams.length,
          showSizeChanger: true,
          total: totalNoOfRecords,
          pageSizeOptions: pageSizeOption,
          position: ["bottomRight"],
        }}
        onChange={onChange} 
        scroll={{ y: `calc(100vh - 330px)` }}
      />
    </>
  );
};

export default NoteTable;
