import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { EditFilled, LoadingOutlined } from "@ant-design/icons";
import { Col, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }
const DegreeList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      width: "5%",
    },
    {
      title: "Degree Name",
      dataIndex: "",
      key: "",
      width: "13%",
      //render: (student: any) => <span>{student?.name}</span>,
    },
    {
      title: "Required Credits",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      align: "end",
      // width: "200px",
      // width: "10%",
      render: (name: any) => <span>₹ {name}</span>,
    },
    {
      title: "Students Assigned",
      dataIndex: "",
      align: "end",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (name: any) => <span>₹ {name}</span>,
      // width: "10%",
    },
    {
      align: "end",
      title: "In progress",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (name: any) => <span>₹ {name}</span>,
      // width: "12%",
    },
    {
      align: "end",
      title: "Completed",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (stream: any) => <span>{stream?.name ?? "-"}</span>,
      // width: "12%",
    },
    {
      align: "end",
      title: "withdraw",
      dataIndex: "",
      key: "",
      // filterSearch: true,
      // width: "200px",
      render: (batch: any) => <span>{batch?.name ?? "-"}</span>,
      // width: "12%",
    },
    {
      title: "Action",
      align: "right",
      dataIndex: "",
      render: (data: any, record: any) => (
        <>
          {
            <EditFilled/>
          }
        </>
      ),
    },
  ];
  return (
    <>
      <Col span={24} style={{ paddingTop: "10px" }}>
        <Table
          size="small"
          className={"Tabel-style"}
          //dataSource={}
          loading={tableLoading}
          columns={columns}
          //   pagination={{
          //     showTotal: (total, range) =>
          //       `${range[0]}-${range[1]} of ${total} items`,
          //     selectPrefixCls: "custom-table-select",
          //     current: pagingItems.currentPage,
          //     pageSize: pagingItems.pageSize,
          //     showSizeChanger: true,
          //     total: pagingItems.totalRecords,
          //     pageSizeOptions: pageSizeOption,
          //   }}
          //   onChange={(paging, filter, sort: any) => {
          //     sort?.order &&
          //       setListParams({
          //         ...listParams,
          //         sortDir: sort?.order,
          //         sortCol: sort?.columnKey,
          //       });
          //     paging &&
          //       setPagingItems({
          //         ...pagingItems,
          //         currentPage: paging?.current ?? 1,
          //         pageSize: paging?.pageSize ?? 15,
          //       });
          //   }}
          scroll={{ y: `calc(100vh - 330px)` }}
        />
      </Col>
    </>
  );
};

export default DegreeList;
