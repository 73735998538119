import * as React from "react";
import { useContext, useState, useEffect } from "react";
import ApplicationContext from "./CreateApplicationContext";
import HTTPSCalls from "../Services/HTTPCalls";

const ApplicationState = (props: any) => {
  // const [batchOption, setBatchOption] = useState<any>([]);
  // const [studentOption, setStudentOption] = useState<any>([]);
  // const getBatchOption = async () => {
  //   await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.MASTERS_GET_BATCH, {}).then(
  //     (res: any) => {
  //       setBatchOption(
  //         res?.result?.map((itm: any) => ({
  //           ...itm,
  //           label: itm?.name,
  //           value: itm?.id,
  //         }))
  //       );
  //     }
  //   );
  // };

  // const getStudentOptions = async () => {
  //   await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.ENQUIRY_STUDENTS, {}).then(
  //     (res: any) => {
  //       setStudentOption(
  //         res?.result?.items?.map((itm: any) => ({
  //           ...itm,
  //           label: itm?.name,
  //           value: itm?.id,
  //         }))
  //       );
  //     }
  //   );
  // };
  // console.log(batchOption, "444");
  // console.log(setBatchOption, "55");

  return (
    <ApplicationContext.Provider
      value={{
        // batchOption,
        // studentOption,
        // setBatchOption,
        // getBatchOption,
        // setStudentOption,
        // getStudentOptions,
      }}
    >
      {props?.children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationState;
