import { Menu } from "antd";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "./MainLayout.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { useUser } from "../Context/UserContext";
import Frame from "../images/Frame.svg";
import calendarmonth from "../images/calendar_month.svg";
import accountcircle from "../images/accountcircle.svg";
import Contactsicon from "../images/Contactsicon.svg";
import SettingsImg from "../images/settingsImg.svg";
import Space_dashbaord from "../images/space_dashboard.svg";
import shield_person from "../images/shield_person.svg";
import local_library from "../images/local_library.svg";
import analytics from "../images/analytics.svg";
import workspace_premium from "../images/workspace_premium.svg";
import wallet from "../images/wallet.svg";
import assignment from "../images/assignment.svg";
import callIcon from "../images/callIcon.svg";
import {
  AppstoreOutlined,
  BarChartOutlined,
  BellOutlined,
  CalendarOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  WalletOutlined,
  UsergroupDeleteOutlined,
  PullRequestOutlined,
  CreditCardOutlined,
  MailOutlined,
  UserOutlined,
  BookOutlined,
  MailFilled,
  PhoneOutlined,
  PhoneFilled,
  PhoneTwoTone,
} from "@ant-design/icons";
import { useCompany, AppFeatures } from "../Context/CompanyContext";
import "../Common/MainLayout.css";
import { MasterTypes } from "../Constant/Enums";
import Students from "../pages/Students/StudentsList";
import {
  AttendanceIcon,
  ContactsIcon,
  DashboardIcon,
  EmployeeIcon,
  FinanceIcon,
  LMSIcon,
  PhoneIcon,
  ReportsIcon,
  SettingsIcon,
  StudentsIcon,
} from "../images/icons/IconSvg";

export interface IAccessCodes {
  enquiries: string;
  students: string;
  curriculums: string;
  streams: string;
  classes: string;
  paymentMedium: string;
  credentials: string;
  documents: string;
  holidays: string;
  status: string;
  medium: string;
  role: string;
  attendance: string;
  fees: string;
  settings: string;
  feesType: string;
  // enquiryLead: string;
  lms: string;
  assessment: string;
  request:string;
  reports:string;
}
export const AccessCodes: IAccessCodes = {
  enquiries: "564ABCD9C82C7705502477FD72385B057CF0",
  students: "D1C5FDE72EC230CD9E41CD1A035B1CFDDBA7c",
  curriculums: "F87D82327A49BEC0D61423696FD2A2348620",
  streams: "304B5FD57DD964F77FC910E727A2AA4F1983",
  classes: "18680A4D96329F1B47BF0D55097C914853F4",
  paymentMedium: "33831E7576C023614D405FD2D8AAD607778A",
  credentials: "194B46C6ACEBB78AEC6B97AE8E88ED90B368",
  documents: "DAA778971D60505FC8E46C93FAC42427BD09",
  holidays: "49500E4B07434838F287FF38AB8CB401CD27",
  status: "DDF2F8ACA18D5033D2618EC4B3C269AA45F0",
  medium: "71CE908A8A04C5F0527C2FE1FC4CFDF3C3E9",
  role: "B57927C05FB853D8665A97C38B9CBF892B97",
  attendance: "FDE87E74337FAAD2EF979DCDBA4C1280E07E",
  fees: "353E4192939291799CC23C27B57C6FB02037",
  settings: "C561F0626055218D84C1E92FFFAE8A7AEB3C",
  feesType: "610c5b106c81c8bddc03f6a1c0e1f07721d14f",
  lms: "610c5b156c81c8bddc03f6a1c0e1f07721d18f",
  assessment: "610c5b106c91c8bddc03f6a1c0e1h07721d14f",
  request:"610c5b106c91c8bddc03f6a1c0e1h07721abcd",
  reports:"610c5b106c91c8bddc03f6a1c0e1h07721efgh"
};
const Sidemenus: React.FC<{
  isCollapse: any;
  onsetSlide: any;
  styles: any;
  isSlide: any;
}> = (props: any) => {
  let loc = useLocation();
  const { isAuthenticated, isSuperAdmin } = useUser();
  const { isFeatureExists } = useCompany();
  let rights: IAccessCodes = JSON.parse(
    localStorage.getItem("tp-user") + ""
  )?.rights;

  const [current, setCurrent] = useState(
    loc.pathname === "/" ? "/" : `/${loc.pathname.split("/")[1]}`
  );
  useEffect(() => {
    if (loc) {
      if (current !== loc.pathname) {
        setCurrent(`/${loc.pathname.split("/")[1]}`);
      }
    }
  }, [loc, current]);
  const navigate = useNavigate();
  const setSelectedMenuItem = (key: any) => {
    if (key) {
      navigate(key);
      props.onsetSlide(false);
      setCurrent(key);
    }
  };
  console.log(current, "currentKye");
  const { isCollapse } = props;
  const [openKeys, setOpenKeys] = useState([""]);
  const rootSubmenuKeys = ["Basic", "Study"];
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey: any = keys.find((key) => openKeys.indexOf(key) === -1);
    // latestOpenKey = [...latestOpenKey, "sub1"];
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey, "sub1"] : []);
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onChange = (value: string) => {
    // console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  const items: MenuProps["items"] = [
    getItem(
      "Dashboard",
      "dashboard",
      <div
        style={{
          display: "flex",
          flexDirection: isCollapse ? "column" : "row",
        }}
      >
        <DashboardIcon />
        {/* <img src={Space_dashbaord}  /> */}
        {/* {isCollapse &&
                <p style={{ fontSize: "12px", marginTop: "5px" }} >Dashboard</p>
            } */}
      </div>
    ),

    rights?.enquiries === AccessCodes.enquiries
      ? getItem(
          "Enquiries",
          "enquiry",
          <div
            style={{
              display: "flex",
              flexDirection: isCollapse ? "column" : "row",
            }}
          >
            <ContactsIcon />
            {/* <img src={Contactsicon} /> */}
            {/* {isCollapse && (
            <p style={{ fontSize: "12px", marginTop: "5px" }}>Enquiries</p>
          )} */}
          </div>
        )
      : null,
    // : getItem(
    //   "Enquiries",
    //   "enquiry",
    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: isCollapse ? "column" : "row",
    //     }}
    //   >
    //     <ContactsIcon />
    //     {isCollapse && (
    //       <p style={{ fontSize: "12px", marginTop: "5px" }}>Enquiries</p>
    //     )}
    //   </div>
    // ),
    rights?.students === AccessCodes.students
      ? getItem(
          "Students",
          "student",
          <div
            style={{
              display: "flex",
              flexDirection: isCollapse ? "column" : "row",
            }}
          >
            <StudentsIcon />
            {/* <img src={accountcircle} /> */}
            {/* {isCollapse && (
            <p style={{ fontSize: "12px", marginTop: "5px" }}>Students</p>
          )} */}
          </div>
        )
      : null,

    // getItem(
    //   'Employee',
    //   'employee',
    //   <div style={{ display: 'flex', flexDirection: isCollapse ? "column" : 'row' }}>
    //     {' '}
    //     <EmployeeIcon/>
    //     {/* <img src={shield_person} /> */}
    //     {isCollapse &&
    //       <p style={{ fontSize: "12px",  marginTop:"5px" }}>Employee</p>
    //     }
    //   </div>
    // ),
    // rights?.lms === AccessCodes.lms
    //   ? getItem(
    //       "LMS",
    //       "lms",
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: isCollapse ? "column" : "row",
    //         }}
    //       >
    //         {" "}
    //         <img src={local_library} />
    //         {isCollapse && (
    //           <p style={{ fontSize: "12px", marginTop: "5px" }}>LMS</p>
    //         )}
    //       </div>,
    //       [
    //         // getItem('Class & Subjects', 'class&Subjects'),
    //         // getItem('Subject Allocation', 'subjectAllocation'),
    //         getItem("Manage Schedule", "manageSchedule"),
    //         // getItem('Lesson Planning', 'lessonPlanning')
    //       ]
    //     )
    //   : null,

    // rights?.assessment === AccessCodes.assessment
    //   ? getItem(
    //       "Assessments",
    //       "assessments",
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: isCollapse ? "column" : "row",
    //         }}
    //       >
    //         <img src={analytics} />
    //         {isCollapse && (
    //           <p style={{ fontSize: "12px", marginTop: "5px" }}>Assessments</p>
    //         )}
    //       </div>,
    //       [
    //         getItem("Assessments & Grades", "assessmentsGrades"),
    //         getItem("Grade Book", "Grade Book"),
    //       ]
    //     )
    //   : null,

    // import calendarmonth from "../images/calendarmonth.svg";
    // import accountcircle from "../images/accountcircle.svg";
    // import Contactsicon from "../images/Contactsicon.svg";

    // getItem("Masters", "sub1", <AppstoreOutlined />, [
    //   getItem("Genders", `Gender`),
    //   // getItem("Category", "Category"),
    //   getItem("Curriculums", "Curriculum"),
    //   getItem("Streams", "Stream"),
    //   getItem("Classes", "Class"),
    //   getItem("Payment Medium", "Payment"),
    //   getItem("Credentials", "Credentials"),

    //   getItem("Documents", "Document"),
    //   getItem("Holidays", "Holiday"),
    //   getItem("Status", "Status"),
    //   getItem("Medium", "Medium"),
    //   getItem(
    //     "Study Plan",
    //     "Study",
    //     <BookOutlined />,
    //     [
    //       getItem("Departments", "Departments"),
    //       getItem("Subjects", "Subjects"),
    //       getItem("Semesters", "Semesters"),
    //       getItem("Courses", "Course"),
    //       getItem("Batches", "Batch"),
    //     ],
    //     "group"
    //   ),
    // ]),
    rights?.curriculums === AccessCodes.curriculums ||
    rights?.streams === AccessCodes.streams ||
    rights?.classes === AccessCodes.classes ||
    rights?.paymentMedium === AccessCodes.paymentMedium ||
    rights?.credentials === AccessCodes.credentials ||
    rights?.documents === AccessCodes.documents ||
    rights?.holidays === AccessCodes.holidays ||
    rights?.status === AccessCodes.status ||
    rights?.medium === AccessCodes.medium ||
    rights?.attendance === AccessCodes.attendance
      ? getItem(
          "Attendance",
          "attendance",
          <div
            style={{
              display: "flex",
              flexDirection: isCollapse ? "column" : "row",
              color: current.split("/")[1] === "attendance" ? "#0D61FF" : "",
            }}
          >
            {/* <img src={calendarmonth} /> */}
            <AttendanceIcon />
            {/* {isCollapse && (
            <p style={{ fontSize: "12px", marginTop: "5px" }}>Attendance</p>
          )} */}
          </div>
        )
      : null,

      rights?.request === AccessCodes.request ?
            getItem("Requests","requests",
        <div
        style={{
          display: "flex",
          flexDirection: isCollapse ? "column" : "row",
          color: current.split("/")[1] === "attendance" ? "#0D61FF" : "",
        }}
      >
       <AttendanceIcon />
   </div>
      ):null,
    // getItem(
    //   'Graduation & Alumni',
    //   'graduationalumni',
    //   <div style={{ display: 'flex',  flexDirection: isCollapse ? "column" : 'row' }}>
    //     <img src={workspace_premium} />
    //     {isCollapse &&
    //       <p style={{ fontSize: "12px",marginTop:"5px"}}>Graduation ...</p>
    //     }

    //   </div>
    // ),
    rights?.reports===AccessCodes.reports?
    getItem(
      'Reports',
      'reports',
      <div style={{ display: 'flex', flexDirection: isCollapse ? "column" : 'row'}}>
        <ReportsIcon/>
        {isCollapse &&
          <p style={{ fontSize: "12px",  marginTop: "5px" }}>Reports</p>
        }
      </div>
    ):null,
    rights?.fees === AccessCodes.fees
      ? getItem(
          "Finance",
          "fees",
          <div
            style={{
              display: "flex",
              flexDirection: isCollapse ? "column" : "row",
              color: current.split("/")[1] === "fees" ? "#0D61FF" : "",
            }}
          >
            {/* <img src={wallet} /> */}
            <FinanceIcon />
            {/* {isCollapse &&
            <p style={{ fontSize: "12px", fontWeight: 300 ,marginTop:"5px"}}>Finance</p>
          } */}
            {/* {isCollapse && (
            <p style={{ fontSize: "12px", marginTop: "5px" }}>Finance</p>
          )} */}
          </div>
        )
      : null,
    // getItem(
    //   "CallLogs",
    //   "callLogs",
    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: isCollapse ? "column" : "row",
    //       color: current.split("/")[1] === "callLogs" ? "#0D61FF" : "",
    //     }}
    //   >
    //     <PhoneIcon/>
    //   </div>
    // ),
    rights?.settings === AccessCodes.settings
      ? getItem(
          "Settings",
          "settings",
          <div
            style={{
              display: "flex",
              flexDirection: isCollapse ? "column" : "row",
              color: current.split("/")[1] === "settings" ? "#0D61FF" : "",
            }}
          >
            {" "}
            {/* <img src={SettingsImg} /> */}
            <SettingsIcon />
            
            {/* {isCollapse && (
            <p style={{ fontSize: "12px", marginTop: "5px" }}>Settings</p>
          )} */}
          </div>
        )
      : null,
    // rights?.enquiryLead === AccessCodes.enquiryLead
    // true
    //   ? getItem("EnquiryLead", "enquiryLead", <MailFilled />)
    //   : null,
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // console.log("rrrr = = ", current.split("/")[1]);
  return (
    <>
      {isAuthenticated() ? (
        <Menu
          // theme="light"
          // style={props?.isSlide && props?.styles}

          style={{
            backgroundColor: "white",
            transition: " all 0.6s ease",
            position: "relative",
            width: "100%",

            overflowY: "auto",
            height: isCollapse ? `calc(100vh - 170px)` : `calc(100vh - 162px)`,
            top: "12px",
          }}
          mode="inline"
          className="sidebar"
          defaultSelectedKeys={[current]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[current.split("/")[1]]}
          onClick={(e) => setSelectedMenuItem(e.key)}
          items={items}
        />
      ) : null}
    </>
  );
};
export default Sidemenus;
