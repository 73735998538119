import { SizeType } from "antd/es/config-provider/SizeContext";
import { AvatarSize } from "antd/es/avatar/AvatarContext";
import Avatar, { AvatarProps, GroupProps } from "antd/es/avatar";
import {
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { getRandomColor, initialsgenerator, textnumbergenerator } from "../Utilities/Utils";
interface IAssigneeAvtar
  extends Omit<AvatarProps, "size">,
  Omit<GroupProps, "size">,
  GroupProps {
  data: any[];
}

const getComponent = (itm: any, index: number, maxCount: number) => {
  if (index + 1 <= maxCount) {
    return (
      <>
        <Space size={"large"}>
          {/* if image is available then show images  */}
          {itm?.thumb ? (
            <Tooltip title={itm?.name} placement="bottom">
              <Avatar
                gap={60}
                src={
                  <img style={{ objectFit: "fill" }} src={itm?.thumb}></img>
                }
              />
            </Tooltip>
          ) : (
            <>
              {/* else shows naming alias */}
              <Tooltip title={itm?.name}>
              <Avatar
              style={{backgroundColor:getRandomColor(itm?.name)}}
              // src={`https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
              //   itm?.name
              // )}-${textnumbergenerator(itm?.name)}.png?ssl=1`}
              //  icon={<UserOutlined />}
                >{initialsgenerator(
                  itm?.name
                )}</Avatar>
              </Tooltip>
            </>
          )}
        </Space>
      </>
    );
  }
  return (
    <>
      <Card bordered={false} size="small">
        <Meta
          avatar={
            itm?.thumb ? (
              <Avatar
                src={
                  <img style={{ objectFit: "fill" }} src={itm?.thumb}></img>
                }
              />
            ) : (
              <Avatar
              style={{backgroundColor:getRandomColor(itm?.name)}}
              // src={`https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
              //   itm?.name
              // )}-${textnumbergenerator(itm?.name)}.png?ssl=1`}
              //  icon={<UserOutlined />}
                >{initialsgenerator(
                  itm?.name
                )}</Avatar>
            )
          }
          title={itm?.name}
          style={{ fontSize: 12 }}
        />
      </Card>
    </>
  );
};
export const AssigneeAvatar = (props: IAssigneeAvtar) => {
  const list: any[] = props?.data;
  const maxCount = props?.maxCount || 2;
  return (
    <>
      <Avatar.Group
        {...props}
        maxCount={maxCount}
        maxStyle={
          props?.maxStyle || {
            color: "#212529",
            backgroundColor: "#EBF1FF",
          }
        }
        maxPopoverPlacement={props?.maxPopoverPlacement || "bottom"}
        // shape={props?.shape || "square"}
      >
        {list?.map((itm: any, index: number) => {
          return (
            <div style={{ marginRight: 5 }}>
              {getComponent(itm, index, maxCount)}
            </div>
          );
        })}
      </Avatar.Group>
    </>
  );
};