import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Form,
  message,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import "../../../component/EnquiryLeadModel/EnquiryLead.css";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { MasterTypes } from "../../../Constant/Enums";
import { LoadingOutlined } from "@ant-design/icons";
import type { TableColumnsType } from "antd";
import Search from "antd/es/input/Search";
import AddSubjectAllocation from "./AddSubjectAllocation";
import Schemas from '../../setting/Course/Schemas';
export const pageSizeOption: number[] = [10, 30, 50, 100, 200];

interface SubjectAllocationDataType {
  key: string;
  subject: string;
  subjectCode: string;
}
const SubjectAllocation = () => {
  const [form] = Form.useForm();
  const [courseData, setCourseData] = useState<any[]>([]);
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [sessionId, setSessionId] = useState<string>("");
  const [semesterData, setSemesterData] = useState<any[]>([]);
  const [batchData, setBatchData] = useState<any[]>([]);
  const [hideButton, setHideButton] = useState<boolean>(false);
  const [lodading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<SubjectAllocationDataType[]>();
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [refresh, setRefresh] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<string>("string");
  const [schemaId, setSchemaId] = useState<string>("string");
  const [semesterId, setSemesterId] = useState<string>("string");
  const tableLoading = {
    spinning: lodading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [listParams, setListParams] = useState<any>({
    start: 0,
    length: 15,
    sortCol: "",
    sortDir: "",
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  //-----Table Columns----------//
  const THeader = ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
    option,
    defaultValue,
  }: any) => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
              },
            },
          }}
        >
          <div
            className="searchDivStyle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {ifSelect ? (
              <Select
                allowClear
                style={{ width: "100%" }}
                onSelect={(value) => {
                  changeListParams(inputName, value);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                onClear={() => {
                  changeListParams(inputName);
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                popupMatchSelectWidth={false}
                variant="borderless"
                size="small"
                className="invSelectVendor"
                placeholder={title}
                options={option}
              // defaultValue={defaultValue}
              />
            ) : (
              <Search
                placeholder={title}
                className={"CitSearchInput"}
                allowClear
                onChange={(e) => changeListParams(inputName, e.target?.value)}
                onSearch={(val) => {
                  setListParams({ ...listParams, start: 0 });
                  setShouldRefresh((x: boolean) => !x);
                }}
                size="small"
                variant="borderless"
              />
            )}
          </div>
        </ConfigProvider>
      </>
    );
  };
  const columns: TableColumnsType<SubjectAllocationDataType> = [
    {
      title: (
        <THeader
          title={"Subject"}
          inputName={"subject"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subject",
      key: "subject",
      width: "50%",
      render: (text: any, record: any) => (
        <span
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {record.subject}
        </span>
      ),
    },
    {
      title: (
        <THeader
          title={"Subject Code"}
          inputName={"subjectCode"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subjectCode",
      key: "subjectCode",
      width: "50%",
    },
  ];
  const getDetails = async (semesterId: any) => {
    setApiDataLoading(true);
    
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS,
      {},
      {
        courseId,
        schemaId,
        semesterId
      }
    );

    if (/*res?.errors === null &&*/ res?.result) {
      setApiDataLoading(true);
      let courseData = res?.result?.courses?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setCourseData(courseData)

      let schemasData = res?.result?.schemas?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setSessionData(schemasData)
      setApiDataLoading(false);

      let semesterData = res?.result?.semesters?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }
      })
      setSemesterData(semesterData)
      
    } else {
      console.log("error", res?.errors);
      setApiDataLoading(false)
    }
    if (semesterId) {
      setTotalNoOfRecords(res?.result?.totalRecords);
      setDataSource(
        res?.result?.subjects?.map((itm: any, index: number) => ({
          key: index,
          subject: itm?.name,
          subjectCode: itm?.code,
        }))
      );
    }   
  }
  useEffect(() => {
    getDetails(semesterId);
  }, [courseId, schemaId,])

  return (
    <div>
      <Row>
        <Typography.Title
          level={5}
          style={{ color: "#373941", fontWeight: 500 }}
        >
          Subject Allocation
        </Typography.Title>
        <Divider className="marginDivider" />
      </Row>
      <Form
        form={form}
        name="batch-form"
        className="DrawerPadding"
        colon={false}
        autoComplete="off"
        labelAlign="left"
        requiredMark={false}
      >
        <Row gutter={10}>
          <Col lg={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Choose Course"
              optionFilterProp="children"
              labelInValue
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={courseData}
              loading={apiDataLoading}
              onSelect={(e: any) => {
                setCourseId(e.value);
                //console.log(e.value, "CourseId");
              }}
            />
          </Col>
          <Col lg={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Choose Session"
              optionFilterProp="children"
              labelInValue
              
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={sessionData}
              loading={apiDataLoading}
              onSelect={(e: any) => {
                setSchemaId(e.value);
                console.log(e, "TestSession");
              }}
            />
          </Col>
          <Col lg={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Choose Semester"
              optionFilterProp="children"
              labelInValue
              
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={semesterData}
              loading={apiDataLoading}
              onSelect={(e: any) => {
                setHideButton(true);
                setSemesterId(e.value);
                console.log(semesterId);

              }}
              onClear={() => {
                setHideButton(false);
                
              }}
            />
          </Col>
          <Col lg={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Choose Batch"
              optionFilterProp="children"
              labelInValue
              
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={batchData}
            />
          </Col>
          {hideButton && (
            <Col lg={4}>
              <Button type="primary" onClick={() => getDetails(semesterId)}>
                Get Subject
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      <Col span={24} style={{ paddingTop: "12px" }}>
        <Table
          className={"Tabel-style"}
          columns={columns}
          dataSource={dataSource}
          size="small"
          loading={apiDataLoading}
          // rowSelection={rowSelection}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: (page, pageSize) => {
              changeListParams("start", (page - 1) * pageSize);
              changeListParams("length", pageSize);
              setRefresh((x) => !x);
            },
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalNoOfRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 330px)` }}
        />
      </Col>
      {isModalOpen && (
        <AddSubjectAllocation
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default SubjectAllocation;
