import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  Select,
  Card,
  Space,
  Col,
  Radio,
} from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { SubjectsMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
interface AddEditSubjectsIn {
  setTrigger: any;
  openDrawer: any;
  subjectsId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(SubjectsMaster.endPoints, SubjectsMaster.prefix);

const AddEditSubjects = (props: AddEditSubjectsIn) => {
  let timeout: any = null;
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [courseData, setCourseData] = useState<any[]>([]);
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [semesterData, setSemesterData] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const [courseId, setCourseId] = useState<string>("");
  const [sessionId, setSessionId] = useState<string>("");


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };

  // const handleSearch = async (val: string) => {
  //   let result: any = [];
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
  //     {
  //       type: MasterTypes.Department,
  //       start: 0,
  //       length: 15,
  //       search: val,
  //     }
  //   );
  //   if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
  //     result = res?.result?.items?.map((x: any) => ({
  //       label: x?.name,
  //       value: x?.id,
  //     }));
  //   } else {
  //     message.error(res?.message);
  //   }
  //   // console.log("result = ", result)
  //   return result;
  // };

  // ------- Get Course Master ----------//
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      setCourseData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Course
        )?.[0]?.items
      );
      // setSessionData(
      //   transformedItems?.filter(
      //     (x: any) => x?.masterType === MasterTypes.Session
      //   )?.[0]?.items
      // );
      setSemesterData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Semester
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };

  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      // console.log("values", values);

      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Subjects,
        id: props?.subjectsId,
        name: values?.name,
        subjectCode: values?.subjectCode,
        subjectCredit: values?.subjectCredit,
        subjectType: values?.subjectType,
        sessionCourse: {
          id: values?.sessionCourse?.value,
          name: values?.sessionCourse?.label,
        },
        session: {
          id: values?.session?.value,
          name: values?.session?.label,
        },
        semester: {
          id: values?.semester?.value,
          name: values?.semester?.label,
        },
        // departments: values?.departments?.map((dept: any) => ({
        //   id: dept?.value,
        //   name: dept?.label,
        // })),
        // moduleCode: values?.moduleCode,
      };
      await api
        .POST(SubjectsMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.subjectsId
                ? "Subjects updated successfully"
                : "Subjects saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
      console.log(val, "SubjectValues");
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.subjectsId) {
        setLoading(true);
        getSubjectsById(props?.subjectsId);
      }
      getMaster();
    }
  }, [props?.openDrawer]);

  const getSubjectsById = async (id: any) => {
    setLoading(true);
    await api
      .GET(SubjectsMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Subjects,
      })
      .then((data: IApiResponse) => {
        if ( data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            ...res,
            name: res?.name,
            sessionCourse: {
              label: res?.sessionCourse?.name,
              value: res?.sessionCourse?.id,
            },
            session: {
              label: res?.session?.name,
              value: res?.session?.id,
            },
            semester: {
              label: res?.semester?.name,
              value: res?.semester?.id,
            },
            // moduleCode: res?.moduleCode,
            // departments: res?.departments?.map((dept: any) => ({
            //   label: dept?.name,
            //   value: dept?.id,
            // })),
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  useEffect(() => {
    if (courseId) {
      getSessionList(courseId);
    }
  }, [courseId]);

  const getSessionList = async (courseId: any) => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SESSION_LIST + "?",
      { courseId }
    );
    //console.log(res, "SemesterList");
    let itemsSession = res?.result?.map((itm: any) => {
      return {
        value: itm?.id,
        label: itm?.name,
      };
    });
    //console.log(itemsSession, "testSessionData");
    setSessionData(itemsSession);
  };

  useEffect(() => {
    if (sessionId) {
      getSemesterList(sessionId);
    }
  }, [sessionId]);

  const getSemesterList = async (sessionId: any) => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SEMESTER_LIST + "?",
      { sessionId }
    );
   // console.log(res, "SemesterList");
    let itemsSemester = res?.result?.map((itm: any) => {
      return ({
        value: itm?.id,
        label: itm?.name,
      });
    });
    //console.log(itemsSession, "testSessionData");
    setSemesterData(itemsSemester);
  };

  return (
    <>
      <Drawer
        title={props?.subjectsId ? "Edit Subjects" : "Add Subjects"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.subjectsId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "80%" }}
        >
          <Form
            form={form}
            name="Subjects-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            // size="small"
            // style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            // wrapperCol={{ span: 14 }}
            requiredMark={false}
          >
            {/* <Form.Item
              label="Course"
              name="courseRef"
              rules={[
                {
                  required: true,
                  message: "Please select Course!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Course"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={masterData?.course}
              />
            </Form.Item> */}
            <Form.Item label="Choose Course" name="sessionCourse">
              <Select
                placeholder="Choose Course"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={courseData}
                onSelect={(e: any) => {
                  setCourseId(e.value);
                  // console.log(e.value, "CourseId");
                }}
              />
            </Form.Item>
            <Form.Item label="Choose Session" name="session">
              <Select
                placeholder="Choose Session"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={sessionData}
                onSelect={(e: any) => {
                  setSessionId(e.value);
                  console.log(e.value,"TestSession");
                }}
              />
            </Form.Item>
            <Form.Item label="Choose Semester" name="semester">
              <Select
                placeholder="Choose Semester"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={semesterData}
              />
            </Form.Item>
            <Form.Item
              label="Subject Name"
              name="name"
              // wrapperCol={{ span: 12 }}
              rules={[
                { required: true, message: "Please enter Subjects name!" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Subject Name"
                // width={"50%"}
                // size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Subject Code"
              name="subjectCode"
              // wrapperCol={{ span: 12 }}
            >
              <Input
                placeholder="Enter Subject Code"
                // width={"50%"}
                // size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Subject Credit"
              name="subjectCredit"
              // wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Enter Subject Credit" />
            </Form.Item>
            <Form.Item label="Subject Type" name="subjectType">
              <Radio.Group>
                <Radio value={1}>Core</Radio>
                <Radio value={2}>Elective</Radio>
                <Radio value={3}>CIT</Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item
              label="Module Code"
              name="moduleCode"
              // wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: "Please enter Alias name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter ModuleCode"
                // width={"50%"}
                // size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Department"
              name="departments"
              // wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: "Please select department!" }]}
            >
              <SA
                mode="multiple"
                labelInValue
                onSearch={(value: string) => {
                  setFetching(true);
                  if (timeout) {
                    clearTimeout(timeout);
                  }
                  timeout = setTimeout(async () => {
                    await getMaster(value);
                    setFetching(false);
                  }, 1500);
                }}
                filterOption={fetching}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                showSearch
                allowClear
                onClear={async () => {
                  await getMaster();
                }}
                options={masterData}
              />
            </Form.Item> */}
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditSubjects;
