import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { MasterTypes } from "../../../Constant/Enums";
import AddEditSections from "./AddEditSections";
const Sections = () => {
  const [form] = Form.useForm();
  const [show, setShow] = useState("");
  const [editData, setEditData] = useState<any | undefined>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<{
    department: any;
    course: any;
    schema: any;
    batch: any;
    section: any;
  }>({
    course: undefined,
    department: undefined,
    schema: undefined,
    batch: undefined,
    section: undefined,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [details, setDetails] = useState<{
    departments: any;
    courses: any;
    schemas: any;
    sections: any;
    batches: any;
  }>({
    courses: [],
    batches: [],
    departments: [],
    schemas: [],
    sections: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [apiDataLoading, setApiDataLoading] = useState<boolean>(false);
  const [postData, setPostData] = useState<any>(undefined);

  const commonColumns: any = [
    {
      title: "CreateAt",
      dataIndex: "CreatedAt",
      render: (_: any, record: any) => (
        <div className="createUpdateDate">
          {record?.createdBy
            ? dayjs(record?.createdBy?.date)?.format("DD MMM YYYY HH:mm")
            : "-"}
        </div>
      ),
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      render: (_: any, record: any) => (
        <div className="createUpdateDate">
          {record?.updatedBy
            ? dayjs(record?.updatedBy?.date)?.format("DD MMM YYYY HH:mm")
            : "-"}
        </div>
      ),
    },
  ];

  const departmentColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div
          className={
            record?.id === selectedRecord?.department?.id
              ? "selectedRecord"
              : ""
          }
        >
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
  ];

  const courseColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div
          className={
            record?.id === selectedRecord?.course?.id ? "selectedRecord" : ""
          }
        >
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
  ];
  const batchColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div
          className={
            record?.id === selectedRecord?.batch?.id ? "selectedRecord" : ""
          }
        >
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
  ];
  const sectionColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div
          className={
            record?.id === selectedRecord?.section?.id ? "selectedRecord" : ""
          }
        >
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
    {
      title: " ",
      dataIndex: "edit",
      render: (_: any, record: any) => (
        <>
          <Button
            icon={<EditOutlined />}
            size="small"
            type="link"
            onClick={() => {
              setEditData(record);
              setShow("sections");
            }}
          />
        </>
      ),
    },
  ];

  const getComponent = (): ReactNode => {
    if (show === "sections")
      return (
        <AddEditSections
          data={{
            ...editData,
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
            course: {
              label: selectedRecord?.course?.name,
              value: selectedRecord?.course?.id,
            },
            schema: {
              label: details?.schemas[0]?.name,
              value: details?.schemas[0]?.id,
            },
            batch: {
              label: selectedRecord?.batch?.name,
              value: selectedRecord?.batch?.id,
            },
          }}
          form={form}
        />
      );
  };

  const saveData = async (data: any) => {
    form.validateFields().then(async (res: any) => {
      let data = {
        ...res,
        ...(editData?.id && { id: editData?.id }),
        masterType: MasterTypes.Section,
      };

      if (!!res?.department) {
        data = {
          ...data,
          department: {
            id: res?.department?.value,
            name: res?.department?.label,
          },
        };
      }
      if (!!res?.course) {
        data = {
          ...data,
          course: {
            id: res?.course?.value,
            name: res?.course?.label,
          },
        };
      }
      if (!!res?.schema) {
        data = {
          ...data,
          schema: {
            id: res?.schema?.value,
            name: res?.schema?.label,
          },
        };
      }
      if (!!res?.batch) {
        data = {
          ...data,
          batch: {
            id: res?.batch?.value,
            name: res?.batch?.label,
          },
        };
      }
      setLoading(true);
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_MASTER,
        {},
        data
      );
      if (response.message || response.errors) {
        setLoading(false);
        message.error(response.message);
      } else {
        setLoading(false);
        message.success("Submitted Successfully");
        closeDrawer(true);
        setRefresh(!refresh);
      }
      console.log(res,"res")
      console.log(data,"data")
    });
  };

  const closeDrawer = (isChange: boolean) => {
    setShow("");
    form.resetFields();
    if (!isChange) setEditData(undefined);
  };

  const getDetails = async () => {
    setApiDataLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_SECTIONS_DETAILS,
      {},
      {
        departmentId: selectedRecord?.department?.id,
        courseId: selectedRecord?.course?.id,
        batchId: selectedRecord?.batch?.id,
        sectionsId: selectedRecord?.section?.id,
      }
    );

    if (!res?.message) {
      let result = res?.result;
      setSelectedRecord((pre: any) => ({
        ...pre,
        department: Array.isArray(result?.departments)
          ? getSelectedId(result?.departments, selectedRecord?.department)
          : undefined,
        course: Array.isArray(result?.courses)
          ? getSelectedId(result?.courses, selectedRecord?.course)
          : undefined,
        schema: Array.isArray(result?.batches)
          ? getSelectedId(result?.batches?.schema, selectedRecord?.schema)
          : undefined,
        batch: Array.isArray(result?.batches)
          ? getSelectedId(result?.batches, selectedRecord?.batch)
          : undefined,
        section: Array.isArray(result?.sections)
          ? getSelectedId(result?.sections, selectedRecord?.section)
          : undefined,
      }));
      setDetails({
        departments: result?.departments?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        courses: result?.courses?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        schemas: result?.batches?.map((itm: any) => ({
          ...itm.schema,
          key: itm?.schema?.id,
        })),
        batches: result?.batches?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        sections: result?.sections?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
      });
      setApiDataLoading(false);
    } else {
      message.error(res?.message);
      setApiDataLoading(false);
    }
  };

  const getSelectedId = (a: any, b: any) => {
    let result: any = undefined;
    if (editData && editData?.id === b?.id) {
      result = a?.find((x: any) => x?.id === editData?.id);
    } else {
      result = b ? b : (a ? a[0] : undefined);
    }
    return result;
  };

  useEffect(() => {
    getDetails();
  }, [refresh]);


  const departmentRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.department?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          department: record[0],
          course: undefined,
          schema: undefined,
          section: undefined,
          batch: undefined,
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);

  const courseRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.course?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          course: record[0],
          schema: undefined,
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);

  const batchRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.batch?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          ...selectedRecord,
          batch: record[0],
          section: undefined,
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);


  const isEmpty = useCallback((source: any) => {
    if (Array.isArray(source)) return source.length <= 0;
    return !source ? true : false;
  }, []);

  return (
    <>
      <Spin
        spinning={apiDataLoading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <ConfigProvider
          theme={{
            components: {
              Table: { borderRadius: 0, headerBorderRadius: 0, fontSize: 13 },
            },
          }}
        >
          <Row gutter={[5, 24]}>
            <Col span={8}>
              <Card title="Department" size="small" className="tableCard">
                <Table
                  key={apiDataLoading ? "departmentLoading" : "Department"}
                  rowSelection={departmentRowSelection}
                  columns={departmentColumns}
                  dataSource={details?.departments}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Course" size="small" className="tableCard">
                <Table
                  key={apiDataLoading ? "courseLoading" : "Course"}
                  rowSelection={courseRowSelection}
                  columns={courseColumns}
                  dataSource={details?.courses}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Batch"
                size="small"
                className="tableCard"
              >
                <Table
                  key={apiDataLoading ? "schemaLoading" : "Schema"}
                  rowSelection={batchRowSelection}
                  columns={batchColumns}
                  dataSource={details?.batches}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[5, 24]} style={{ marginTop: 5 }}>
            <Col span={8}>
              <Card
                title="Sections"
                size="small"
                className="tableCard"
                extra={
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setShow("sections");
                    }}
                    disabled={isEmpty(details?.batches)}
                  >
                    Add
                  </Button>
                }
              >
                <Table
                  key={apiDataLoading ? "sectionLoading" : "Section"}
                  // rowSelection={sectionRowSelection}
                  columns={sectionColumns}
                  dataSource={details?.sections}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </ConfigProvider>

        <Drawer
          placement="right"
          width={"40%"}
          maskClosable={false}
          closeIcon={false}
          extra={
            <CloseOutlined
              style={{ fontSize: "16px" }}
              className=""
              onClick={() => closeDrawer(false)}
            />
          }
          footer={
            <Row justify="end">
              <Button
                onClick={() => closeDrawer(false)}
                disabled={loading}
                className="ttp-btn-light btn-m-sm"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="btn-m-sm"
                loading={loading}
                onClick={() => saveData(postData)}
              >
                {editData ? "Update" : "Save"}
              </Button>
            </Row>
          }
          title={"Section"}
          onClose={() => {
            setShow("");
          }}
          open={!!show}
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{ height: "100%" }}
          >
            {getComponent()}
          </Spin>
        </Drawer>
      </Spin>
    </>
  );
};

export default Sections;