import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, message, Row, Spin } from "antd";
import * as React from "react";
import { useContext, useState, useEffect,useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { adminContext } from "../../../Common/PageRoute";
import { MasterTypes } from "../../../Constant/Enums";
import { MailTemplatesMaster } from "../../../ApiUrls/URLS";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
interface AddEditMailTemplatesIn {
  setTrigger: any;
  openDrawer: any;
  templateId: string | undefined;
  SetDrawerClose: any;
}

const AddEditMailTemplaes = (props: AddEditMailTemplatesIn) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const editor = useRef();
  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.templateId) {
        setLoading(true);
      }
    }
  }, [props?.openDrawer]);
  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const contentBody = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Mail</title>
  </head>
  <body>
      <div style="width: 60%; font-size: 16px; font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif;">
          <p><span style="font-size: 16px">Hi,   </span></p>
          <p><span style="font-size: 16px"></span></p>
          <p><span style="font-size: 16px">--</span>
          <div style="line-height:0mm; font-size: 50px; font-family: verdana, sans-serif;">
          </div>
          <div style="line-height:0mm; margin-top: 38px; font-family: 'trebuchet ms', sans-serif; font-size: 13px;">
              <h4 style="color:#666666;">CAPSITECH INSTITUTE OF TECHNOLOGY</h4>
              <h4 style="color:#a80000; font-size: 10.6667px; font-family: Arial, sans-serif;">H-299 | 3rd Phase, RIICO Industrial Area | Boranada | Jodhpur-342012</h4>
          </div>
          <div style="line-height:1mm; margin-top: 25px;">
              <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Phone : +91 0291 294 2244 | Mob. +91 (0) 8302303370</p>
              <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Email : vineeta.tookey@capsitech.com | Web URL : <a href="https://www.capsitech.com/" style="color:rgb(0, 68, 255)">https://www.capsitech.com/</a></p>
          </div>
          <div>
              <img src="https://cit.capsitech.com/images/Citlogo.svg" />
          </div>
      </div>
  </body>
</html>
 `;
  const api = new ApiCalls(
    MailTemplatesMaster.endPoints,
    MailTemplatesMaster.prefix
  );
  const { openNotification } = React.useContext(adminContext);
  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.templateId) {
        setLoading(true);
        getTemplateById(props?.templateId);
      }
    }
  }, [props?.openDrawer]);
  const getTemplateById = async (id: any) => {
    setLoading(true);

    await api
      .GET(MailTemplatesMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.MailTemplates,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          form.setFieldsValue({
            name: res?.name,
            htmlContent: res?.htmlContent,
          });
          console.log(res, "this is res");
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.MailTemplates,
        id: props?.templateId,
        name: values?.name,
      };
      await api
        .POST(MailTemplatesMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.templateId
                ? "Task updated successfully"
                : "Task saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
    });
  };

  return (
    <>
      <Drawer
        title={props?.templateId ? "Edit Mail Templates" : "Add Mail Templates"}
        placement="right"
        width={858}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.templateId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="curriculum-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            // {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
            layout="vertical"
          >
            <Row gutter={[0, 35]}>
              <Col lg={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter template name!" },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Template Name"
                    size="middle"
                    onInput={(e: any) =>
                      (e.target.value =
                        e.target.value.length > 1
                          ? e.target.value
                          : e.target.value.toUpperCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="htmlContent"
                  // rules={[{ required: true, message: 'Please enter content!' }]}
                >
                  <SunEditor
                    setContents={
                      props?.templateId
                        ? form.getFieldValue("htmlContent")
                        : contentBody
                    }
                    getSunEditorInstance={getSunEditorInstance}
                    onBlur={(val: any, ed: any) => console.log(ed)}
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        ["bold", "underline", "italic", "strike"],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],
                        // ["table", "horizontalRule", "link", "image", "video"],
                        ["print"],
                        ["removeFormat"],
                        ["image"],
                      ],
                      defaultTag: "div",
                      font: [
                        "Arial",
                        "Comic Sans MS",
                        "Courier New",
                        "Impact",
                        "Georgia",
                        "Tahoma",
                        "Trebuchet MS",
                        "Verdana",
                      ],
                      fontSize: [
                        8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 36,
                      ],
                      defaultStyle: "font-family: Arial; font-size: 14px;", // Set default style
                      showPathLabel: false,
                    }}
                    height="400px"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditMailTemplaes;
