import dayjs, { Dayjs } from "dayjs";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Batch {
  id: string;
  name: string;
}

interface Student {
  id: string;
  name: string;
}

interface StudentAtt {
  batch: Batch;
  student: Student;
  date: Dayjs;
}

const initialState: { studentAtt: StudentAtt } = {
  studentAtt: {
    batch: {
      id: "",
      name: ""
    },
    student: {
      id: "",
      name: ""
    },
    date: dayjs() 
  },
};

const studentAttSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    AddStudentAtt: (state, action: PayloadAction<StudentAtt>) => {
      state.studentAtt = action.payload;
    },
    ResetStudentAtt: (state) => {
      state.studentAtt = initialState.studentAtt;
    },
  },
});

export const { AddStudentAtt,ResetStudentAtt } = studentAttSlice.actions;
export default studentAttSlice.reducer;
