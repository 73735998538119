import {
  DeleteTwoTone,
  EditOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  TabsProps,
  TimePicker,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { useState, useEffect } from "react";
import ManageClassSchedule from "./ManageClassSchedule";
// import { ScheduleCategoryMaster, SessionMaster } from "../../ApiUrls/URLS";
import {
  ScheduleCategory,
  categoryType,
  CategoryTypeOption,
} from "../../Constant/Enums";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../Services/HTTPCalls";
import "../../../src/Custom.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { adminContext } from "../../Common/PageRoute";
import Search from "antd/es/input/Search";
// import dots from "../../../src/images/more_vert.svg"
import moment from "moment";
import ConfirmationModel from "../../Common/ConfirmationModel";
import { queryClient } from "../../App";
interface DataType {
  key: string;
  name: string;
  type: string;
  semester: {
    id: string;
    name: string;
  };
}
// interface I_calenderData {
//     name: string;
// }
// type DataIndex = keyof DataType;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
  defaultValue,
}: any) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: "#1B1A1A",
              colorFillAlter: "transparent",
            },
            Select: {
              colorTextPlaceholder: "#1B1A1A",
            },
          },
        }}
      >
        <div
          className="searchDivStyle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {ifSelect ? (
            <Select
              allowClear
              style={{ width: "100%" }}
              onSelect={(value) => {
                changeListParams(inputName, value);
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              onClear={() => {
                changeListParams(inputName);
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              popupMatchSelectWidth={false}
              variant="borderless"
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={option}
            // defaultValue={defaultValue}
            />
          ) : (
            <Search
              placeholder={title}
              className="CitSearchInput"
              allowClear
              onChange={(e) => {
                changeListParams(inputName, e.target?.value);
              }}
              onSearch={(val) => {
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              variant="borderless"
            />
          )}
        </div>
      </ConfigProvider>
    </>
  );
};
const ManageSchedule = () => {
  const { RangePicker } = DatePicker;
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  // const navigate = useNavigate();
  const [trigger, setTrigger] = useState<boolean>(false);
  // const [trigger1, setTrigger1] = useState<boolean>(false);
  // const [totalRecords, setTotalRecords] = useState<number>(0);
  // const [createScheduleId, setCreateScheduleId] = useState<any>("");
  const showDrawer = () => {
    setVisible(true);
  };
  const { openNotification } = React.useContext(adminContext);
  // const tableLoading = {
  //      spinning: isTable,
  //      indicator: <Spin indicator={<LoadingOutlined />} />,
  // };
  // type DataIndex = keyof DataType;
  // const [searchText, setSearchText] = useState("");
  // const [searchedColumn, setSearchedColumn] = useState("");
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [allSchedule, setAllSchedule] = useState<DataType[]>([]);
  const [scheduleData, setScheduleData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<any>("1");
  // const [recordSemId, setRecordSemId] = useState<string>("");
  const [fillCalData, setFillCalenderData] = useState<any[]>([]);
  const [selectedSection, setSelectedSection] = useState<any>([{}]);
  // #region option Details state//------
  const [departMentId, setDepartMentId] = useState<string>("");
  const [courseId, setCourseId] = useState<string>("");
  const [batchId, setBatchId] = useState<string>("");
  const [semesterId, setSemesterId] = useState<string>("");
  const [departMentData, setDepartMentData] = useState<any[]>([]);
  const [courseData, setCourseData] = useState<any[]>([]);
  const [batchData, setBatchData] = useState<any[]>([]);
  const [sectionsData, setSectionsData] = useState<any[]>([]);
  const [semesterData, setSemesterData] = useState<any[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  // const [scheduleId, setScheduleId] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);

  //----------------------------------
  const [endTime, setEndTime] = useState<any>(null);
  const [subjectData, setSubjectData] = useState<any[]>([]);
  const [teachers, setTeachers] = useState<any[]>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
    sortCol: "",
    sortDir: "ascend",
    categorySearch: "",
    type: 0,
  });

  useEffect(() => {
    setPageLoader(true);
    getSchedule(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
      // recordSemId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, listParams, pagingItems, refresh]);
  const [calenderData, setCalenderData] = useState<any | string[]>();

  // const [getAllSemester, setGetAllSemester] = useState<any[]>([]);
  const changeListParams = (key: string, value: any) => {
    // setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 })
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const changeTab = (activeKey: any) => {
    setActiveTab(activeKey)
  };
  // const searchInput = useRef<InputRef>(null);

  // const handleSearch = (
  //     selectedKeys: string[],
  //     confirm: FilterDropdownProps["confirm"],
  //     dataIndex: DataIndex
  // ) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  // };
  // const handleReset = (clearFilters: () => void) => {
  //     clearFilters();
  //     setSearchText("");
  // };
  //#region  Custom TableHeader

  const handleEndTimeChange = (time: any) => {
    setEndTime(time);
  };

  // const valEndTime = (_: any, value: any) => {
  //     if (!value) {
  //         return Promise.reject(new Error("Please select an end time!"));
  //     }
  //     return Promise.resolve();
  // };

  // const valStartTime = (_: any, value: any) => {
  //     if (endTime && value && value.isAfter(endTime)) {
  //         return Promise.reject(new Error("Start time cannot be after end time!"));
  //     }
  //     return Promise.resolve();
  // };

  const getScheduleById = async (createScheduleId?: string) => {
    setLoading(true);
    // debugger;
    const res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.SCHEDULE_GET_BY_ID + "?",
      { id: createScheduleId }
    );
    // if(res?.message){
    //     message.error(res.message);
    // }
    // else{
    //    form.setFieldsValue({

    //    });
    // }

    if (res?.result) {
      form.setFieldsValue({
        ...res,
        type: res?.result?.type,
        name: res?.result?.name,
        semester: {
          label: res?.result?.semester?.name,
          value: res?.result?.semester?.id,
        },
        workingDays: res?.result?.workingDays.map((e: any) => ({
          mon: e?.mon,
          tues: e?.tues,
          wed: e?.wed,
          thu: e?.thu,
          fri: e?.fri,
          sat: e?.sat,
          sun: e?.sun,
        })),
        department: {
          label: res?.result?.department?.name,
          value: res?.result?.department?.id,
        },
        course: {
          label: res?.result?.course?.name,
          value: res?.result?.course?.id,
        },
        batch: {
          label: res?.result?.batch?.name,
          value: res?.result?.batch?.id,
        },
        section: res?.result?.section?.map((e: any) => ({
          label: e?.name,
          value: e?.id,
        })),
        date: [
          dayjs(res?.result?.date?.from, "YYYY-MM-DD"),
          dayjs(res?.result?.date?.to, "YYYY-MM-DD"),
        ],
        startTime: dayjs(res?.result?.startTime),
        endTime: dayjs(res?.result?.endTime),
        durationMinutes: res?.result?.durationMinutes,
        sessionBreaks: res?.result?.sessionBreaks?.map((ele: any) => {
          return {
            breakTitle: ele?.breakTitle,
            from: dayjs(ele?.time?.from),
            to: dayjs(ele?.time?.to),
          };
        }),
      });
      setLoading(false);
    }
  };
  const [scheduleOption, setScheduleOption] = useState<any>([]);
  const [sectionOption, setSectionOption] = useState<any>([]);
  const [scheduleId, setScheduleId] = useState<any>();
  const [manageScheduleId, setManageScheduleId] = useState<any>();
  // const [getDefaultData, setGetDefaultData] = useState<any | string>();
  // const items: MenuProps['items'] = [

  // ];
  const columns: ColumnsType<DataType> = [
    {
      title: (
        <>
          <THeader
            title={"Category Name"}
            inputName={"categorySearch"}
            changeListParams={changeListParams}
            listParams={listParams}
            setShouldRefresh={setRefresh}
            setListParams={setListParams}
            ifSelect={false}
          />
        </>
      ),
      dataIndex: "name",
      key: "name",
      width: "30%",
      sortDirections: ["ascend"],
      render: (text: any, record: any) => {
        return (
          <p
            style={{ padding: "8px 6px", cursor: "pointer" }}
            onClick={() => {
              setManageScheduleId(record?.id)
              changeTab("2");
            }}
          >
            {record?.name}
          </p>
        );
      },
    },
    {
      title: (
        <>
          <THeader
            title={"Category Type"}
            inputName={"type"}
            changeListParams={changeListParams}
            listParams={listParams}
            setShouldRefresh={setRefresh}
            setListParams={setListParams}
            ifSelect={true}
            option={CategoryTypeOption}
          />
        </>
      ),
      width: "20%",
      dataIndex: "type",
      key: "type",
      defaultSortOrder: "ascend",
      ellipsis: true,
      sortDirections: ["ascend"],
      render: (text: any, record: any) => {
        return <p style={{ padding: "8px 6px" }}>{record?.type}</p>;
      },
    },
    {
      title: "Start Date",
      width: "20%",
      dataIndex: "date",
      ellipsis: true,
      align: "left",
      render: (x: any, record: any) => (
        <>{dayjs(record?.date?.from)?.format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "End Date",
      width: "20%",
      ellipsis: true,
      dataIndex: "date",
      render: (x: any, record: any) => (
        <>{dayjs(record?.date?.to)?.format("DD MMM YYYY")}</>
      ),
    },
    // {
    //     title: "Action",
    //     align: "center",
    //     render: (x:any,record:any) => (
    //         <>
    //             {
    //                 <Dropdown menu={[
    //                        {
    //                     key: '1',
    //                     label: (
    //                         <p style={{ fontSize: "14px", color: "#000000" }} >Manage Class Schedule</p>
    //                     ),
    //                 },
    //                 {
    //                     key: '2',
    //                     label: (
    //                         <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
    //                             <p style={{ fontSize: "14px", color: "#000000" }}>Edit</p>
    //                         </a>
    //                     ),
    //                 },
    //                 {
    //                     key: '3',
    //                     label: (
    //                         <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
    //                             <p style={{ fontSize: "14px", color: "#000000" }}>Delete</p>
    //                         </a>
    //                     ),
    //                 },]} placement="bottomLeft">
    //                     <img src={dots} style={{ cursor: "pointer" }} />
    //                 </Dropdown>
    //             }
    //         </>
    //     )
    // }
    {
      title: " Edit ",
      width: "10%",
      align: "center",
      dataIndex: "",
      render: (data: any, record: any) => (
        <>
          <EditOutlined
            onClick={() => {
              // setCreateScheduleId(record?.id);
              showDrawer();
              getScheduleById(record?.id);
              setScheduleId(record?.id);
            }}
            style={{
              color: "#377dff",
            }}
          />
        </>
      ),
    },
    {
      title: " Delete ",
      width: "10%",
      align: "center",
      dataIndex: "",
      render: (data: any, record: any) => (
        <>
          {
            <DeleteTwoTone
              className="ca-delete-btn cursor"
              twoToneColor="#ff879d"
              onClick={() => {
                setScheduleId(record?.id);
                setConfirmation(true);
              }}
            />
          }
        </>
      ),
    },
  ];

  const TabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Schedule Categories",
      children: (
        <Row>
          <Col lg={24}>
            <Table
              size="small"
              className="cit-att-sheet"
              dataSource={allSchedule}
              style={{minHeight:720}}
              scroll={{ x: 800 }}
              loading={{
                spinning: pageLoader,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              onChange={(paging, filter, sort: any) => {
                sort?.order &&
                  setListParams({ 
                    ...listParams,
                    sortDir: sort?.order,
                    sortCol: sort?.columnKey,
                  });
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
              columns={columns}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                // onChange: (page, pagesize) => {
                //     changeListParams("start", (page - 1) * pagesize);
                //     changeListParams("length", pagesize);
                //     setRefresh((x) => !x);
                // },
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: ["15", "25", "50", "100"],
              }}
              // scroll={{ x: 1100, y: "65vh" }}
              showSorterTooltip={{ target: "sorter-icon" }}

            //
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Manage class schedule",
      children: (
        <ManageClassSchedule
          manageScheduleId={manageScheduleId}
          setManageScheduleId={setManageScheduleId}
        />
      ),
    },
    // {
    //   key: "3",
    //   label: "Teachers schedule",
    //   children: "contant of tab pane",
    // },
  ];

  const getDetails = async () => {
    setLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_LIST,
      {},
      {
        departMentId,
        courseId,
        batchId,
        semesterId,
      }
    );
    if (res?.result) {
      setLoading(true);

      let departmentData = res?.result?.departments?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      setDepartMentData(departmentData);
      setLoading(false);
      let courseData = res?.result?.courses?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      setCourseData(courseData);
      setLoading(false);
      let batchData = res?.result?.batches?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      setBatchData(batchData);
      setLoading(false);
      let sectionsData = res?.result?.sections?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });
      form.setFieldsValue({ section: sectionsData });
      setSectionsData(sectionsData);
      setLoading(false);
      setLoading(false);
      let semesterData = res?.result?.semesters?.map((itm: any) => {
        return {
          ...itm,
          value: itm?.id,
          label: itm?.name,
        };
      });
      setSemesterData(semesterData);
      let subjectsData = res?.result?.subjects?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name,
        };
      });

      setSubjectData(subjectsData);
      let teacherNames = res?.result?.teachers?.map((itm: any) => {
        return {
          value: itm?.teacher?.id,
          label: itm?.teacher?.name,
        };
      });
      setTeachers(teacherNames);
      setLoading(false);
    } else {
      console.log(res.errors);
    }
  };
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departMentId, courseId, batchId, semesterId]);

  const deleteSchedule = async () => {
    try {
      setLoading(true);
      if (scheduleId) {
        let res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.DELETE_SCHEDULE + "?",
          { scheduleId },
          {
            scheduleId,
          }
        );
        if (res.status) {
          setRefresh(!refresh);
          message.success("Delete Schedule Successfully");
        } else {
          console.log(res?.errors);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // schedule-Item-List Api ------------------------///

  // const getScheduleItemList = async (
  //   recordScheduleId?: string,
  //   sectionId?: string
  // ) => {
  //   setPageLoader(true);
  //   await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.SCHEDULE_ITEM_LIST + "?", {
  //     scheduleId: recordScheduleId,
  //     sectionId: sectionId,
  //   }).then((res: IApiResponse) => {
  //     if (res?.result) {
  //       console.log(res?.result, "scheduleItemListRes");
  //     } else {
  //       console.log(res?.errors);
  //     }
  //   });
  // };

  const getSchedule = async (
    start: any,
    length: any,
    recordScheduleId?: string
  ) => {
    setPageLoader(true);
    await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.SCHEDULE_GETLIST + "?", {
      start: start,
      length: length,
      sortCol: listParams.sortCol,
      sortDir: listParams.sortDir,
      categorySearch: listParams.categorySearch,
      type: listParams.type,
    }).then((res: IApiResponse) => {
      if (/*res?.errors === null &&*/ res?.result) {
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords;
          return p;
        });
        setAllSchedule(
          res?.result?.items?.map((arrayItems: any, index: any) => ({
            ...arrayItems,
            key: index,
            name: arrayItems?.name,
            type: categoryType[arrayItems?.type],
          }))
        );
        if (recordScheduleId) {
          // debugger;
          const findScheduleData = res?.result?.items?.find(
            (x: any) => x.id === recordScheduleId
          );
          setScheduleOption(
            res?.result?.items?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))
          );

          // const abcdef = findScheduleData?.semester;
          setSectionOption(
            findScheduleData?.section.map((x: any) => {
              return {
                label: x?.name,
                value: x?.id,
              };
            })
          );

          setFillCalenderData(findScheduleData);
        }
        setPageLoader(false);
      } else {
        message.error(res?.message);
        setPageLoader(false);
      }
    });
  };

  useEffect(() => { }, [refresh]);

  const SaveData = async () => {
    try {
      const values = await form.validateFields();
      setBtnLoading(true);
      const trueKeys: string[] = Object.keys(values?.workingDays[0]).filter(
        (key: any) => values?.workingDays[0][key]
      );

      const val = {
        ...values,
        ...(scheduleId && { id: scheduleId }),
        name: values?.name,
        sessionBreaks: values?.sessionBreaks?.map((item: any) => ({
          breakTitle: item?.breakTitle,
          time: {
            from: item?.from,
            to: item?.to,
          },
        })) || [],
        semester: {
          id: values?.semester.value,
          name: values?.semester.label,
        },
        workDays: trueKeys,
        workingDays: values?.workingDays?.map((itm: any) => ({
          mon: itm?.mon,
          tues: itm?.tues,
          wed: itm?.wed,
          thu: itm?.thu,
          fri: itm?.fri,
          sat: itm?.sat,
          sun: itm?.sun,
        })),
        date: {
          from: values.date[0],
          to: values.date[1],
        },
        startTime: values?.startTime,
        endTime: values?.endTime,
        durationMinutes: values?.durationMinutes,
        department: {
          id: values?.department.value,
          name: values?.department.label,
        },
        course: {
          id: values?.course.value,
          name: values?.course.label,
        },
        batch: {
          id: values?.batch.value,
          name: values?.batch.label,
        },
        section: values?.section?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
      };

      if (validateTime(val)) {
        const res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.SCHEDULE_CREATE,
          {},
          { ...val }
        );

        if (res.status) {
          setVisible(false);
          form.resetFields();
          openNotification(
            "success",
            scheduleId
              ? "Schedule Updated successfully"
              : "Schedule Created successfully"
          );
          queryClient.invalidateQueries({ queryKey: ["GetListWithIdName"] });
          setTrigger((x: boolean) => !x);
        } else {
          openNotification("error", res.message);
        }
      } else {
        openNotification("error", "Invalid Time");
      }
    } catch (error) {
      console.error("error", error);
      openNotification("error", "An error occurred while saving data.");
    } finally {
      setBtnLoading(false);
    }
  };
  
  const validateTime = (values: any): boolean => {
    let isValidated = true;
    const currentTime = dayjs(values.startTime);
    const endTime = dayjs(values.endTime);
    const duration = values.durationMinutes;

    let time = currentTime;
    let breakIndex = 0;

    while (time.isBefore(endTime)) {
      const nextTime = time.add(duration, "minute");

      if (
        values?.sessionBreaks?.length > 0 &&
        breakIndex < values.sessionBreaks.length
      ) {
        const breakStart = dayjs(values.sessionBreaks[breakIndex]?.time?.from);
        const breakEnd = dayjs(values.sessionBreaks[breakIndex]?.time?.to);

        if (time.isSame(breakStart)) {
          time = breakEnd;
          breakIndex++;
          continue;
        }
      }

      time = nextTime;
    }

    if (!time.isSame(endTime) || breakIndex !== values?.sessionBreaks?.length) {
      isValidated = false;
    }

    return isValidated;
  };

  dayjs.extend(customParseFormat);

  const validateStartTime = (_: any, value: any) => {
    if (!value || value.isAfter(moment().startOf("day"))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Start time must be after midnight!"));
  };
  const validateEndTime = (_: any, value: any) => {
    const startTime = form.getFieldValue("startTime");
    if (!value || (startTime && value.isAfter(startTime))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("End time must be after start time!"));
  };

  // const validateFrom = (_: any, value: any) => {
  //     if (!value || value.isAfter(moment().startOf("day"))) {
  //         return Promise.resolve();
  //     }
  //     return Promise.reject(new Error("Start time must be after midnight!"));
  // };
  // const validateTo = (_: any, value: any) => {
  //     const startTime = form.getFieldValue("from");
  //     if (!value || (startTime && value.isAfter(startTime))) {
  //         return Promise.resolve();
  //     }
  //     return Promise.reject(new Error("End time must be after start time!"));
  // };
    const validateAtLeastOneChecked = (_: any, value: any) => {
    const checkedDays = value.filter((day: any) =>
      Object.values(day).some((val) => val)
    );
    return checkedDays.length > 0
      ? Promise.resolve()
      : Promise.reject(
        new Error("At least one checkbox needs to be selected.")
      );
  };
  const handleCheckboxChange = () => {
    form.validateFields();
  };
  return (
    <>
    <div  className=  "roundedCornerMedium bg-white px-3 py-2" >
      <Row>
        <Col lg={12}>
          {/* <Typography.Title
            level={5}
            style={{ color: "#373941", fontWeight: 500, paddingLeft: "2px" }}
          >
            {" "}
            Manage Schedule
          </Typography.Title> */}
        </Col>
        <Col lg={12} style={{ display: "flex", justifyContent: "end" }}>
          <Button type="primary" onClick={showDrawer}>
            Create Schedule{" "}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Tabs
            activeKey={activeTab}
            onChange={changeTab}
            defaultActiveKey="1"
            items={TabItems}
          />
        </Col>
      </Row>
      <Drawer
        width={"53%"}
        // loading={scheduleId}
        closable
        destroyOnClose
        title="Create Schedule"
        placement="right"
        open={visible}
        onClose={() => {
          setVisible(false);
          setScheduleId(null);
          form.resetFields();
        }}
        footer={
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => SaveData()}
              loading={btnLoading}
            >
              {scheduleId ? "Update" : "Save"}
              {/* Save */}
            </Button>{" "}
            <Button
              onClick={() => {
                setVisible(false);
                setScheduleId(null);
                form.resetFields();
              }}
            >
              Close
            </Button>
          </Space>
        }
      >
        <div
          style={{
            padding: 24,
            height: `calc(100vh-25px)`,
            fontFamily: "Inter,sans-serif",
        
          }}
        >
          <Form
            form={form}
            name="Schedule-form"
            layout="vertical"
            style={{ fontFamily: "In" }}
          >
            <Row gutter={35}>
              <Col>
                <Form.Item
                  name="type"
                  style={{ fontWeight: 600 }}
                  label="Category Type"
                  rules={[
                    { required: true, message: "Please Select Category Type" },
                  ]}
                >
                  <Select
                    // defaultValue="Class Schedule"
                    style={{ width: 280 }}
                    // onSelect={(value:any)=>setListParams}
                    allowClear
                    options={[
                      {
                        value: ScheduleCategory.ClassSchedule,
                        label: "Class Schedule",
                      },
                      {
                        value: ScheduleCategory.ExamSchedule,
                        label: "Exam Schedule",
                      },
                      {
                        value: ScheduleCategory.GeneralSchedule,
                        label: "General Schedule",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="date"
                  label="Select Date"
                  style={{ fontWeight: 600 }}
                  rules={[{ required: true, message: "Please Select Date" }]}
                >
                  <RangePicker format="YYYY-MM-DD" style={{ width: 280 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={35}>
              <Col>
                <Form.Item
                  name="name"
                  style={{ fontWeight: 600 }}
                  label="Class Schedule Name"
                  rules={[
                    {
                      required: true,
                      message: "Class schedule name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Class Schedule name"
                    style={{ width: 280 }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="department"
                  style={{ fontWeight: 600 }}
                  label="Department"
                  rules={[
                    { required: true, message: "Please select Department!" },
                  ]}
                >
                  <Select
                    style={{ width: 280 }}
                    placeholder="Choose Department"
                    optionFilterProp="children"
                    labelInValue
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                    options={departMentData}
                    onSelect={(e: any) => {
                      setDepartMentId(e.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={35}>
              <Col>
                <Form.Item
                  name="course"
                  style={{ fontWeight: 600 }}
                  label="Course"
                >
                  <Select
                    style={{ width: 280 }}
                    placeholder="Choose Course"
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                    options={courseData}
                    onSelect={(e: any) => {
                      setCourseId(e.value);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="batch"
                  style={{ fontWeight: 600 }}
                  label="Batch"
                >
                  <Select
                    style={{ width: 280 }}
                    placeholder="Choose Batch"
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={batchData}
                    onSelect={(e: any) => {
                      setBatchId(e.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={35}>
              <Col>
                <Form.Item
                  name="semester"
                  style={{ fontWeight: 600 }}
                  label="Semester"
                  rules={[{ required: true, message: "Please Pick Semester" }]}
                >
                  <Select
                    // defaultValue="Pick Semester"
                    style={{ width: 280 }}
                    allowClear
                    // options={getAllSemester}
                    options={semesterData}
                    onSelect={(e: any) => {
                      setSemesterId(e.value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    labelInValue
                    optionRender={(option, info) => {
                      return (
                        <Space>
                          {option?.data?.course?.name}/
                          {option?.data?.schema?.name}/
                          <span role="img" aria-label={option?.data?.label}>
                            {option?.data?.name}
                          </span>
                        </Space>
                      );
                    }}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="section"
                  style={{ fontWeight: 600 }}
                  label="Section"
                >
                  <Select
                    style={{ width: 280 }}
                    mode="multiple"
                    placeholder="Select Section"
                    optionFilterProp="children"
                    labelInValue={true}
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={sectionsData}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h4 style={{ fontWeight: 600 }}>Select Working days</h4>
            <Row style={{ paddingTop: 8 }}>
              <Form.List name="workingDays" rules={[{validator:validateAtLeastOneChecked}]} initialValue={[{}]}>
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "mon"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Mon</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "tues"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Tues</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "wed"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange} >Wed</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "thu"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Thu</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "fri"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Fri</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "sat"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Sat</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            {...restField}
                            name={[name, "sun"]}
                            valuePropName="checked"
                          >
                            <Checkbox style={{ color: "gray" }}  onChange={handleCheckboxChange}>Sun</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col lg={10}></Col>
                        <Col lg={24} style={{marginTop:"-30px"}}>
                        <Form.Item
                          shouldUpdate
                          dependencies={[name]} // Trigger validation when any checkbox changes
                          validateTrigger="onChange" // Trigger validation on checkbox value change
                          name={[name]}
                          rules={[
                            {
                              validator: (_, value) => {
                                // debugger;
                                const isAnyChecked = Object.values(value || {}).some((checked) => checked);
                                if (!isAnyChecked) {
                                  return Promise.reject(new Error("At least one day must be selected"));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        ></Form.Item>
                        </Col>
                       
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </Row>
            <Row gutter={30}>
              <Col>
                <Form.Item
                  name="startTime"
                  style={{ fontWeight: 600 }}
                  label="Start Time"
                  rules={[
                    { required: true, message: "Please select a start time!" },
                    // { validator: validateStartTime },
                  ]}
                >
                  <TimePicker
                    // format="HH:mm"
                    use12Hours
                    format="h:mm a"
                    showNow={false}
                    style={{ width: 280 }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="endTime"
                  style={{ fontWeight: 600 }}
                  label="End Time"
                  rules={[
                    { required: true, message: "Please select an end time!" },
                    { validator: validateEndTime },
                  ]}
                >
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    showNow={false}
                    style={{ width: 280 }}
                    onChange={handleEndTimeChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Item
                  name="durationMinutes"
                  label="Display Time Table in the duration of # mins*"
                  rules={[{ required: true, message: "add duration" }]}
                >
                  {/* <TimePicker format="mm" showNow={false} /> */}
                  <InputNumber min={1} max={250} />
                </Form.Item>
              </Col>
              <Col lg={8}></Col>
            </Row>

            <h4 style={{ paddingTop: 18, color: "#1E1E1E", fontWeight: 600 }}>
              Set up break time
            </h4>
            <Row
              gutter={35}
              style={{ paddingTop: 16, maxHeight: 280, overflowY: "auto" }}
            >
              <Col>
                <Form.List name="sessionBreaks">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          size={25}
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "breakTitle"]}
                            rules={[
                              {
                                required: true,
                                message: "Please add a title!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Break title"
                              style={{ width: 280 }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "from"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select start time!",
                              },
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              style={{ width: 280 }}
                              disabledHours={() => {
                                if (!endTime) return [];
                                const endHour = endTime?.hour();
                                return Array.from(
                                  { length: 24 },
                                  (_, i) => i
                                ).filter((hour) => hour > endHour);
                              }}
                              disabledMinutes={(selectedHour) => {
                                if (
                                  !endTime ||
                                  selectedHour !== endTime?.hour()
                                )
                                  return [];
                                const endMinute = endTime?.minute();
                                return Array.from(
                                  { length: 60 },
                                  (_, i) => i
                                ).filter((minute) => minute > endMinute);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "to"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select an end time!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const startTime = getFieldValue([
                                    "sessionBreaks",
                                    name,
                                    "from",
                                  ]);
                                  if (
                                    !value ||
                                    !startTime ||
                                    value.isAfter(startTime)
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "End time must be greater than start time!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              style={{ width: 280 }}
                              disabledHours={() => {
                                if (!endTime) return [];
                                const endHour = endTime?.hour();
                                return Array.from(
                                  { length: 24 },
                                  (_, i) => i
                                ).filter((hour) => hour > endHour);
                              }}
                              disabledMinutes={(selectedHour) => {
                                if (
                                  !endTime ||
                                  selectedHour !== endTime?.hour()
                                )
                                  return [];
                                const endMinute = endTime?.minute();
                                return Array.from(
                                  { length: 60 },
                                  (_, i) => i
                                ).filter((minute) => minute > endMinute);
                              }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            // const lastBreak = fields[fields.length - 1];
                            // const lastBreakEndTime = lastBreak ? form.getFieldValue(['sessionBreaks', lastBreak.name, 'to']) : null;

                            // if (lastBreakEndTime && lastBreakEndTime.isSame(endTime)) {
                            //     message.warning("You cannot add more breaks because the last break ends at the session's end time.");
                            // } else {
                            //     add();
                            // }
                            add();
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Breaks
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer>
      {confirmation && (
        <ConfirmationModel
          open={confirmation}
          text={"Are you sure you want to Delete !"}
          onOk={() => {
            deleteSchedule();
            setConfirmation(false);
            setScheduleId(null);
          }}
          onCancel={() => {
            setConfirmation(false);
            setScheduleId(null);
          }}
        />
      )}
       </div>
    </>
  );
};

export default ManageSchedule;

