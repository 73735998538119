import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  InputNumber,
  Space,
  Col,
  ColorPicker,
  Checkbox,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { FeesTypeMaster } from "../../../ApiUrls/URLS";
import { EnquiryType, MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import TextArea from "antd/es/input/TextArea";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { cyan, generate, green, presetPalettes, red } from "@ant-design/colors";
import { Divider, theme } from "antd";
import type { ColorPickerProps } from "antd";
interface AddEditProcessSteps {
  processType:EnquiryType,
  processStepId: string | undefined;
  onDismiss: any;
}

const AddEditProcessSteps = (props: AddEditProcessSteps) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const { token } = theme.useToken();
  type Presets = Required<ColorPickerProps>["presets"][number];

  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map<Presets>(([label, colors]) => ({
      label,
      colors,
    }));
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });

  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <div style={{ width: "100%" }}>
      <Row style={{ width: "100%" }} gutter={[24, 16]} wrap={false}>
        <Col span={12}>
          <Presets />
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col span={12} flex="auto">
          <Picker />
        </Col>
      </Row>
    </div>
  );
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const getHexValue = (val: any) => {
    if (val) {
      if (typeof(val)==='string') return val;
      else return val?.toHexString();
    } else return val;
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        id: props?.processStepId,
        ...values,
        processType:props?.processType,
        color: getHexValue(values?.color),
        steps: values?.steps?.map((itm: any) => ({
          ...itm,
          color: getHexValue(itm?.color),
        })),
      };
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_PROCESS_STEPS,
        {},
        val
      );

      if (res?.message) {
        messageApi.error(res?.message);
      } else {
        messageApi.success("Step added successfully");
        setTimeout(() => {
          props?.onDismiss(true);
        }, 600);
      }
      setBtnLoading(false);
    });
  };

  useEffect(() => {
    if (props?.processStepId) {
      setLoading(true);
      getProcessStepById(props?.processStepId);
    }
  }, []);

  const getProcessStepById = async (processStepId: any) => {
    setLoading(true);
    const res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_PROCESS_STEP_BY_ID + "?",
      { processType:props.processType,id: processStepId }
    );
    if (res?.message) {
      messageApi?.error(res?.message);
    } else {
      form.setFieldsValue({ ...res?.result });
    }
    setLoading(false);
  };
  const onClose = (rec: boolean = false) => {
    form.resetFields();
    props?.onDismiss(rec);
  };

  return (
    <>
      {contextHolder}
      <Drawer
        title={props?.processStepId ? "Edit Process Step" : "Add Process Step"}
        placement="right"
        width={1024}
        maskClosable={false}
        onClose={() => onClose}
        closeIcon={false}
        open={true}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={() => onClose()}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={() => onClose()}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.processStepId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="processStep-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
            initialValues={{
              color: "#0000",
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter  name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter  Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Order No"
              name="orderNumber"
              rules={[{ required: true, message: "This filed is required" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                autoFocus
                placeholder="Enter Order No."
                size="middle"
                min={1}
              />
            </Form.Item>
            <Form.Item label="Color" name="color">
              <ColorPicker
                allowClear
                showText
                size="middle"
                onClear={() => {
                  form.setFieldValue("color", "#0000");
                }}
              />
            </Form.Item>
            <Form.Item
              label="Remark Drawer"
              name="remarkDrawer"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea
                autoFocus
                placeholder="Enter Description"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.List name="steps">
              {(fields, { add, remove }) => (
                <>
                  <Button
                    ghost
                    type="primary"
                    onClick={() => add({ color: "#0000" })}
                    icon={<PlusOutlined />}
                    size="middle"
                    style={{ marginBottom: 20 }}
                  >
                    Add Steps
                  </Button>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ width: "100%" }}>
                      <Row gutter={[24, 16]}>
                        <Col span={1}>
                          <Form.Item
                            {...restField}
                            name={[name, "remarkDrawer"]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              title="Remark Drawer"
                              style={{ marginTop: 5 }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                          >
                            <Input
                              autoFocus
                              placeholder="Enter Name"
                              size="middle"
                              onInput={(e: any) =>
                                (e.target.value =
                                  e.target.value.length > 1
                                    ? e.target.value
                                    : e.target.value.toUpperCase())
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "orderNumber"]}
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder="Enter Order No."
                              size="middle"
                              min={1}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Form.Item {...restField} name={[name, "color"]}>
                            <ColorPicker
                              size="middle"
                              allowClear
                              onClear={() => {
                                form.setFieldValue([name, "color"], "#0000");
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={9}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                          >
                            <Input
                              placeholder="Enter Description"
                              size="middle"
                              onInput={(e: any) =>
                                (e.target.value =
                                  e.target.value.length > 1
                                    ? e.target.value
                                    : e.target.value.toUpperCase())
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Button
                            size="middle"
                            icon={<DeleteOutlined />}
                            type="default"
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditProcessSteps;
