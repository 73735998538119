import { ArrowLeftOutlined, PlusOutlined, LoadingOutlined, FormOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Col, Row, Select, Spin, Table, message, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import AddEditLeaveTypes from './AddEditLeaveTypes';
import HTTPSCalls from '../../../Services/HTTPCalls';

const { Search } = Input;

export const pageSizeOption: number[] = [10, 15, 30, 50, 100, 200];

enum ApplicationUserStatus {
    Active = 0,
    Inactive = 1,
    Deleted = 2,
}

interface ILeaveType {
    id: string;
    leaveType: string;
    isAttachmentRequired: boolean;
    recordStatus: ApplicationUserStatus;
}

const LeaveTypes = () => {
    const [editId, setEditId] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<ILeaveType[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(pageSizeOption[0]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [editDetails, setEditDetails] = useState<any>();
    const navigate = useNavigate();

    const columns: ColumnsType<ILeaveType> = [
        {
            title: "S. No",
            dataIndex: "uuid",
            fixed: true,
            width: "4%",
            render: (_, __, index) => <p>{index + 1}</p>,
        },
        {
            title: "Leave Types",
            dataIndex: "leaveType",
            ellipsis: true,
            render: (text, record) => (
                <span
                    onClick={() => {
                        setEditId(record?.id);
                        setIsOpen(true);
                        setEditDetails(record);
                    }}
                    style={{ cursor: "pointer", color: "#4096FF" }}
                >
                    {record?.leaveType}
                </span>
            ),
        },
        {
            title: "Is Attachment Required",
            width: "15%",
            dataIndex: "isAttachmentRequired",
            render: (isAttachmentRequired) => (
                <span>{isAttachmentRequired ? "Yes" : "No"}</span>
            ),
        },
        {
            title: " ",
            width: "3%",
            dataIndex: "edit",
            render: (_, record) => (
                <FormOutlined
                    className="ca-edit-btn"
                    style={{ color: "#0d6efd" }}
                    onClick={() => {
                        setEditId(record?.id);
                        setEditDetails(record)
                        setIsOpen(true);
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        getList();
    }, [shouldRefresh, currentPage, pageSize, searchTerm]);

    const getList = async () => {
        setIsLoading(true);
        const params = {
            start: (currentPage - 1) * pageSize,
            length: pageSize,
            search: searchTerm
        };

        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_LEAVE_TYPES + "?", params);
        setIsLoading(false);

        if (res?.result?.length > 0) {
            setDataSource(res.result.map((item: any) => ({
                id: item?.id,
                leaveType: item?.leaveType,
                isAttachmentRequired: item?.isAttachmentRequired,
                recordStatus: item?.recordStatus,
            })));
            setTotalRecords(res?.result?.totalRecords || res.result.length);
        } else {
            messageApi.error(res?.message);
        }
    };

    return (
        <>
            <Row justify={'space-between'} align="middle" style={{ marginBottom: "6px" }}>
                <ArrowLeftOutlined style={{ color: '#1677ff' }} onClick={() => navigate('/settings')} />
                <Breadcrumb style={{ color: "#172B4D" }} items={[{ title: "Leave Types" }]} />
            </Row>

            <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
                <Col span={24}>
                    <Row>
                        <Col lg={21}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setEditId("");
                                    setIsOpen(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add Leave Type
                            </Button>
                        </Col>
                        <Col lg={3}>
                            {/* <Search
                                placeholder="Search Name..."
                                allowClear
                                className="att-search-input mb-1"
                                onSearch={(val: string) => {
                                    setSearchTerm(val);
                                    setCurrentPage(1); // Reset to first page on new search
                                }}
                                style={{ width: "100%" }}
                            /> */}
                        </Col>
                    </Row>
                    <Col span={24} style={{ paddingTop: "12px" }}>
                        <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
                            <Table
                                size="small"
                                dataSource={dataSource}
                                columns={columns}
                                scroll={{ x: 1100, y: "65vh" }}
                                pagination={{
                                    showTotal: (total) => `${total} items`,
                                    current: currentPage,
                                    pageSize: pageSize,
                                    showSizeChanger: true,
                                    total: totalRecords,
                                    onChange: (page, size) => {
                                        setCurrentPage(page);
                                        setPageSize(size);
                                    },
                                    pageSizeOptions: pageSizeOption.map(String),
                                    position: ["bottomRight"],
                                }}
                            />
                        </Spin>
                    </Col>
                </Col>
            </Row>

            {isOpen && (
                <AddEditLeaveTypes
                    open={isOpen}
                    onDismiss={(rec: boolean) => {
                        setIsOpen(false);
                        if (rec) setShouldRefresh((prev) => !prev);
                    }}
                    id={editId}
                    detail={editDetails}
                    editDetails={undefined}
                />
            )}
        </>
    );
};

export default LeaveTypes;
