import { DatePicker, Form, FormInstance, Input, message, Select } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { MasterTypes } from "../../../Constant/Enums";
import HTTPSCalls from "../../../Services/HTTPCalls";
const { RangePicker } = DatePicker;
interface IAddEditSchema {
  data: any;
  form: FormInstance<any>;
}
const AddEditSemester = ({ data, form }: IAddEditSchema) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  useEffect(() => {
    if (!!data) form.setFieldsValue({ ...data });
  }, []);
  const [allSections, setAllSections] = useState<any[]>([]);
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items?.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name
            };
          }),
        };
      });
      
      setAllSections(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Section
        )?.[0]?.items
      )
      console.log("tran",transformedItems);
    } else {
      message.error(res?.message)
    }
  };
  useEffect(() => {
    getMaster();
  }, [])
  return (
    <Form
      form={form}
      name="department-form"
      className="DrawerPadding"
      colon={false}
      autoComplete="off"
      style={{ padding: 16 }}
      labelAlign="left"
      requiredMark={false}
      {...formItemLayout}
    >
      <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item
        label="Course"
        name="course"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Course"} disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name="schema"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Schema"} disabled />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Enter Semester Name"
          size="middle"
          onInput={(e: any) =>
          (e.target.value =
            e.target.value.length > 1
              ? e.target.value
              : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
      {/* <Form.Item label="Select Section" name="section" >
        <Select mode="multiple"
          labelInValue={true}
          placeholder='Select section'
          options={allSections}
          filterOption={(input: any, option: any) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          } />

      </Form.Item> */}
    </Form>
  );
};

export default AddEditSemester;
