import { EyeFilled, LoadingOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { Card, message, Spin, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import { BsHeadphones } from "react-icons/bs";
import dayjs from "dayjs";

const TATACallLogs = () => {
  const [callDetails, setCallDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const columns = [
    {
      title: "S. No",
      dataIndex: "uuid",
      fixed: true,
      width: "3%",
      render: (text: any, record: any, index: any) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    {
      title: "Agent Name",
      dataIndex: "answeredAgentName",
      width: "10%",
    },
    {
      title: "Direction",
      dataIndex: "direction",
      width: "3%",
    },
    {
      title: "Call Status",
      dataIndex: "call_status",
      width: "4%",
    },
    {
      title: "Hangup Cause",
      dataIndex: "hangupCause",
      width: "4%",
    },
    {
      title: "Dialer",
      dataIndex: "callerIdNumber",
      width: "3%",
    },
    {
      title: "Receiver",
      dataIndex: "callToNumber",
      width: "5%",
    },

    {
      title: "Date",
      dataIndex: "startStamp",
      width: "3%",
      render: (date: any) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Start Time",
      dataIndex: "startStamp",
      width: "5%",
      // render: (time: string) => {
      //     const date = new Date(time);
      //     const formattedTime = date.toLocaleTimeString([], {
      //       hour: '2-digit',
      //       minute: '2-digit',
      //       second: '2-digit',
      //     });

      //     return formattedTime;
      //   },
      render: (time: string) => dayjs(time).format("HH:mm:ss"),
    },
    {
      title: "End Time",
      dataIndex: "endStamp",
      width: "5%",
      render: (time: string) => dayjs(time).format("HH:mm:ss"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "5%",
      render: (seconds: any) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        // Format each unit to be two digits
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(secs).padStart(2, "0");
        return `
  ${formattedHours}
  :
  ${formattedMinutes}
  :
  ${formattedSeconds}
  `;
      },
    },

    {
      title: "",
      dataIndex: "recording_url",
      width: "4%",
      render: (text: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BsHeadphones
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() =>
              window.open(`${text}`, "_blank", "rel=noopener noreferrer")
            }
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    get_logs();
  }, []);

  const get_logs = async () => {
    try {
      await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.TATA_CALL_LOG_LIST, {}).then(
        (res: IApiResponse) => {
          setLoading(true);

          if (res?.result) {
            setCallDetails(res.result);
          }
        }
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Card>
        <Table
          columns={columns}
          dataSource={callDetails}
          style={{ minHeight: 750 }}
          // scroll={{ x: 500,y:800 }}

          loading={{
            spinning: loading,
            indicator: <Spin indicator={<LoadingOutlined />} />,
          }}
        />
      </Card>
    </>
  );
};

export default TATACallLogs;
