import { wait } from "@testing-library/user-event/dist/utils";
import * as React from "react";
import { useContext, useState, useEffect, useMemo } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { Card, Col, Empty, Flex, Row, Spin, Timeline, Typography,Button, ConfigProvider, Segmented, Tooltip } from "antd";
import type { TooltipProps } from 'antd';
import {
  ArrowRightOutlined,
  EditOutlined,
  FormOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { DataTypeEnum, EnquiryType, UserLogActions } from "../../Constant/Enums";
import dayjs from "dayjs";
import moment from "moment";
const { Text, Link } = Typography;
interface IAllActivityLog {
  apiData: any;
  shouldRefresh: boolean;
}
const AllActivityLog = (props: IAllActivityLog) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [arrow, setArrow] = useState<'Show' | 'Hide' | 'Center'>('Show');

  const mergedArrow = useMemo<TooltipProps['arrow']>(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  // if (props?.apiData === undefined) {
  //     setLoading(true)
  // } else {
  //     setLoading(false)
  // }
  const getData = async () => {
    setLoading(true);
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_LOG + "?", {
      entityId: props?.apiData?.id,
    });
    setLoading(false);
    setDataSource(
      res?.result?.items?.map((itm: any) => {
        let component: any = null;
        if (itm?.action === UserLogActions.ProcessStepChanged) {
          component = {
            dot: <SwapOutlined />,
            children: (
              <>
                {
                  <>
                    <p>
                      {" "}
                      <Text strong>Changes in Process step </Text>
                      <Text
                        type="secondary"
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          marginLeft: 10,
                        }}
                      >
                        {moment(itm?.createdBy?.date).calendar(null, {
                          sameDay: "[Today at] hh:mm A",
                          nextDay: "[Tomorrow at] hh:mm A",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday at] hh:mm A",
                          lastWeek: "[Last] dddd [at] hh:mm A",
                          sameElse: "MMM DD YYYY, [at] hh:mm A",
                        })}
                      </Text>
                    </p>
                    <Card size="small">
                      {itm?.changes?.map((val: any, index: number) => (
                        <>
                          {/* <p key={index}>
                          {val?.from}{" "}
                          <ArrowRightOutlined style={{ fontSize: 11 }} />{" "}
                          {val?.to}
                        </p> */}
                          <Row gutter={[24, 16]} key={index}>
                            <Col span={9}>
                              <Text title={val?.from || "null"} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{val?.from||"null"}</Text>
                            </Col>
                            <Col span={2}>  
                              <ArrowRightOutlined style={{ fontSize: 11 }} />
                            </Col>
                            <Col span={11}>
                            <Text title={val?.to|| "null"} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{val?.to || "null"}</Text>
                            </Col>
                          </Row>
                        </>
                      ))}

                      <p>
                        <Text
                          type="secondary"
                          style={{ fontWeight: 500, fontSize: 12 }}
                        >
                          By {itm?.createdBy?.userName}
                        </Text>
                      </p>
                    </Card>
                  </>
                }
              </>
            ),
          };
        }
        if (itm?.action === UserLogActions.Insert) {
          component = {
            color: "green",
            dot: <PlusOutlined />,
            children: (
              <>
                {
                  <>
                    <p>
                      <Text>New enquiry raised </Text>
                      <Text
                        type="secondary"
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          marginLeft: 10,
                        }}
                      >
                        {moment(itm?.createdBy?.date).calendar(null, {
                          sameDay: "[Today at] hh:mm A",
                          nextDay: "[Tomorrow at] hh:mm A",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday at] hh:mm A",
                          lastWeek: "[Last] dddd [at] hh:mm A",
                          sameElse: "MMM DD YYYY, [at] hh:mm A",
                        })}
                      </Text>
                    </p>
                    <Card size="small">
                      {itm?.enquiryType === EnquiryType.Website && (
                        <>
                          <p>
                            <Text>Mode </Text>
                            <Text>Via Website</Text>
                          </p>
                        </>
                      )}
                      {itm?.enquiryType === EnquiryType.Application && (
                        <>
                          <Row gutter={[24, 16]}>
                            <Col span={4}>

                              <Text title={"Mode"} ellipsis style={{ fontSize: 13, color: "black" }}>Mode </Text>
                            </Col>
                            <Col>
                              <Text title={"Application"} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>Application</Text>
                            </Col>
                          </Row>
                          <p>
                            <Text
                              type="secondary"
                              style={{ fontWeight: 500, fontSize: 12 }}
                            >
                              By {itm?.createdBy?.userName}
                            </Text>
                          </p>
                        </>
                      )}
                    </Card>
                  </>
                }
              </>
            ),
          };
        }
        if (itm?.action === UserLogActions.Update) {
          component = {
            dot: <EditOutlined />,
            children: (
              <>
                {
                  <>
                    <p>
                      {" "}
                      <Text strong>Changes in Information </Text>
                      <Text
                        type="secondary"
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          marginLeft: 10,
                        }}
                      >
                        {moment(itm?.createdBy?.date).calendar(null, {
                          sameDay: "[Today at] hh:mm A",
                          nextDay: "[Tomorrow at] hh:mm A",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday at] hh:mm A",
                          lastWeek: "[Last] dddd [at] hh:mm A",
                          sameElse: "MMM DD YYYY, [at] hh:mm A",
                        })}
                      </Text>
                    </p>
                    <Card size="small">

                      {itm?.changes?.map((val: any, index: number) => (
                        <>
                          <Row gutter={[10, 16]} key={index}>
                            <Col span={8}>
                              <Text title={val?.fieldName} ellipsis style={{ fontSize: 13 }} >{val?.fieldName}</Text>
                            </Col>
                            <Col span={6}>
                              {
                                val?.dataType === DataTypeEnum.DateTime
                                  ?
                                  <Text title={moment(val?.from, "DD-MM-YYYY hh:mm:ss A").add(1, 'days').format("DD MMM YYYY")} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{moment(val?.from, "DD-MM-YYYY hh:mm:ss A").add(1, 'days').format("DD MMM YYYY")}</Text>
                                  :
                                  val?.path?
                                  <Tooltip placement="top" title={"Click to open the preview"} arrow={mergedArrow}>
                                  <Text ellipsis style={{ fontSize: 13, color: "#1677FF",cursor:"pointer" }} onClick={()=>{
                                    val?.path&&
                                    window.open(val?.path,"_black")
                                  }}>{val?.from||"null"}</Text></Tooltip>:
                                  <Text title={val?.from||"null"} ellipsis style={{ fontSize: 13, color: "#1677FF",cursor:"pointer" }} onClick={()=>{
                                    val?.path&&
                                    window.open(val?.path,"_black")
                                  }}>{val?.from|| "null"}</Text>
                              }
                            </Col>
                            <Col span={2}>
                              <ArrowRightOutlined style={{ fontSize: 11 }} />
                            </Col>
                            <Col span={8}>
                              {
                                val?.dataType === DataTypeEnum.DateTime
                                  ?
                                  <Text title={moment(val?.to, "DD-MM-YYYY hh:mm:ss A").add(1, 'days').format("DD MMM YYYY")} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{moment(val?.to, "DD-MM-YYYY hh:mm:ss A").add(1, 'days').format("DD MMM YYYY")}</Text>
                                  :
                                  <Text title={val?.to||"null"} ellipsis style={{ fontSize: 13, color: "#1677FF",cursor:"pointer" }} >{val?.to||"null"}</Text>
                              }
                            </Col>
                          </Row>
                        </>
                      ))}
                      <p>
                        <Text
                          type="secondary"
                          style={{ fontWeight: 500, fontSize: 12 }}
                        >
                          By {itm?.createdBy?.userName}
                        </Text>
                      </p>
                    </Card>


                  </>
                }
              </>
            ),
          };
        }
        if (itm?.action === UserLogActions.EnquiryReject) {
          component = {
            color: "red",
            // dot: <SwapOutlined />,
            children: (
              <>
                {
                  <>
                    <p>
                      {" "}
                      <Text strong>Enquiry is Rejected </Text>
                      <Text
                        type="secondary"
                        style={{
                          fontWeight: 500,
                          fontSize: 13,
                          marginLeft: 10,
                        }}
                      >
                        {moment(itm?.createdBy?.date).calendar(null, {
                          sameDay: "[Today at] hh:mm A",
                          nextDay: "[Tomorrow at] hh:mm A",
                          nextWeek: "dddd",
                          lastDay: "[Yesterday at] hh:mm A",
                          lastWeek: "[Last] dddd [at] hh:mm A",
                          sameElse: "MMM DD YYYY, [at] hh:mm A",
                        })}
                      </Text>
                    </p>
                    <Card size="small">
                      {itm?.changes?.map((val: any, index: number) => (
                        <>
                          {/* <p key={index}>
                          {val?.from}{" "}
                          <ArrowRightOutlined style={{ fontSize: 11 }} />{" "}
                          {val?.to}
                        </p> */}
                          <Row gutter={[24, 16]} key={index}>
                            <Col span={9}>
                              <Text title={val?.from} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{val?.from}</Text>
                            </Col>
                            <Col span={2}>
                              <ArrowRightOutlined style={{ fontSize: 11 }} />
                            </Col>
                            <Col span={11}>
                              <Text title={val?.to} ellipsis style={{ fontSize: 13, color: "#1677FF" }}>{val?.to}</Text>
                            </Col>
                          </Row>
                        </>
                      ))}

                      {
                        <Text strong type="secondary">{props?.apiData?.reject?.note}</Text>
                      }

                      <p>
                        <Text
                          type="secondary"
                          style={{ fontWeight: 500, fontSize: 12 }}
                        >
                          By {itm?.createdBy?.userName}
                        </Text>
                      </p>
                    </Card>
                  </>
                }
              </>
            ),
          };
        }
        return component;
      })
    );
  };

  useEffect(() => {
    getData();
  }, [props?.apiData, props?.shouldRefresh]);

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <div style={{ maxHeight: `calc(100vh - 500px)`, overflowY: "auto", padding: 10 }}>
        {dataSource && dataSource?.length > 0 ? (
          <Row>
            <Col xxl={10} lg={10} md={12} sm={24} xs={24}>
              <Timeline
                items={dataSource}
                style={{ overflowY: "auto", padding: 20 }}
              />
            </Col>
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Spin>
  );
};

export default AllActivityLog;
