import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Drawer, Button, Form, Input, Spin, message, Select } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { SemesterMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
interface AddEditSemesterIn {
  setTrigger: any;
  openDrawer: any;
  semesterId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(SemesterMaster.endPoints, SemesterMaster.prefix);

const AddEditSemester = (props: AddEditSemesterIn) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [courseData, setCourseData] = useState<any[]>([]);
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [courseId, setCourseId] = useState<string>("");

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const [department, setDepartment]: any = useState<any>([]);
  const [subjects, setSubjects]: any = useState<any>([]);
  // const getDepartment = async (val: string = "") => {
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
  //     {
  //       type: MasterTypes.Department,
  //       start: 0,
  //       length: 15,
  //       search: val,
  //     }
  //   );
  //   if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
  //     const result = res?.result?.items;
  //     // console.log("result", result);
  //     setDepartment(
  //       result?.map((x: any) => ({ label: x?.name, value: x?.id }))
  //     );
  //   } else {
  //     message.error(res?.message);
  //   }
  // };
  const getSubject = async (val: string = "") => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: MasterTypes.Subjects,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      // console.log("result", result);
      setSubjects(result?.map((x: any) => ({ label: x?.name, value: x?.id })));
    } else {
      message.error(res?.message);
    }
  };
  const getSubjectOptions = async (departmentId: string) => {
    setSubjects([]);
    form.setFieldValue("subjects", []);
    setDepartmentLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SUBJECTS_BY_DEPARTMENT + "?",
      {
        departmentId: departmentId,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.length >= 0) {
      setSubjects(
        res?.result?.map((x: any) => ({ label: x?.name, value: x?.id }))
      );
      setDepartmentLoading(false);
    } else {
      message.error(res?.message);
      setDepartmentLoading(false);
    }
  };
  const handleSearch = async (val: string, Master: MasterTypes) => {
    let result: any = [];
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: Master,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      result = res?.result?.items?.map((x: any) => ({
        label: x?.name,
        value: x?.id,
      }));
    } else {
      message.error(res?.message);
    }
    // console.log("result = ", result)
    return result;
  };

  // ------- Get Course Master ----------//

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      setCourseData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Course
        )?.[0]?.items
      );
      // setSessionData(
      //   transformedItems?.filter(
      //     (x: any) => x?.masterType === MasterTypes.Session
      //   )?.[0]?.items
      // );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Semester,
        id: props?.semesterId,
        sessionCourse: {
          name: values?.sessionCourse?.label,
          id: values?.sessionCourse?.value,
        },
        session: {
          name: values?.session?.label,
          id: values?.session?.value,
        },

        // department: {
        //   name: values?.department?.label,
        //   id: values?.department?.value,
        // },
        // subjects: values?.subjects?.map((val: any) => ({
        //   name: val?.label,
        //   id: val?.value,
        // })),
      };
      await api
        .POST(SemesterMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.semesterId
                ? "Semester updated successfully"
                : "Semester saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
      //console.log(val,"SemesterValues");
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      // getSubject()
      // getDepartment()
      getMaster();
      if (props?.semesterId) {
        setLoading(true);
        getSemesterById(props?.semesterId);
      }
    }
  }, [props?.openDrawer]);

  useEffect(() => {
    if (courseId) {
      getSessionList(courseId);
    }
  }, [courseId]);

  const getSemesterById = async (id: any) => {
    setLoading(true);
    await api
      .GET(SemesterMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Semester,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          // getSubjectOptions(res?.department?.id);
          form.setFieldsValue({
            name: res?.name,
            semesterCode: res?.semesterCode,
            sessionCourse: {
              label: res?.sessionCourse?.name,
              value: res?.sessionCourse?.id,
            },
            session: {
              label: res?.session?.name,
              value: res?.session?.id,
            },
            // subjects: res?.subjects?.map((x: any) => ({
            //   label: x?.name,
            //   value: x?.id,
            // })),
            // department: {
            //   label: res?.department?.name,
            //   value: res?.department?.id,
            // },
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };

  const getSessionList = async (courseId: any) => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SESSION_LIST + "?",
      { courseId }
    );
    console.log("SessionList = ", res);
    let items = res?.result?.map((itm: any) => {
      return {
        value: itm?.id,
        label: itm?.name,
      };
    });
    setSessionData([...items]);
    // form.setFieldValue("session",[...items])
  };

  const onClose = () => {
    setDepartment([]);
    setSubjects([]);
    props?.SetDrawerClose(false);
    form.resetFields();
  };

  return (
    <>
      <Drawer
        title={props?.semesterId ? "Edit Semester" : "Add Semester"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.semesterId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="semester-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Choose Course"
              name="sessionCourse"
              rules={[{ required: true, message: "Please select Course!" }]}
            >
              <Select
                placeholder="Choose Course"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={courseData}
                onSelect={(e: any) => {
                  console.log("eeeeeeeeeeeeeee = ", e);
                  setCourseId(e.value);
                  //console.log(e.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Choose Session"
              name="session"
              // className="marginbtm"
              rules={[{ required: true, message: "Please select Session!" }]}
            >
              <Select
                placeholder="Choose Session"
                optionFilterProp="children"
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={sessionData}
              />
            </Form.Item>
            <Form.Item
              label="Semester Name"
              name="name"
              rules={[
                { required: true, message: "Please enter Semester Name!" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Semester Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Semester Code"
              name="semesterCode"
              rules={[
                { required: true, message: "Please enter Semester Code!" },
              ]}
            >
              <Input
                autoFocus
                placeholder="Enter Semester Code"
                size="middle"
              />
            </Form.Item>
            {/* <Form.Item
              label="Department"
              name="department"
              rules={[{ required: true, message: "Please select department!" }]}
            >
              <Select
                labelInValue
                options={department}
                onSelect={(val: any) => {
                  getSubjectOptions(val?.value)
                }}
              // autoFocus
              // placeholder="Enter Semester Name"
              // size="middle"
              // onInput={(e: any) =>
              //   (e.target.value =
              //     e.target.value.length > 1
              //       ? e.target.value
              //       : e.target.value.toUpperCase())
              // }
              />
            </Form.Item>
            <Form.Item
              label="Subjects"
              name="subjects"
              rules={[{ required: true, message: "Please select subjects!" }]}
            >
              <Select
                // menuItemSelectedIcon={<>{`${"♠"}`}</>}
                loading={departmentLoading}
                mode="multiple"
                options={subjects}
                labelInValue
              // open={!departmentLoading}
              // autoFocus
              // placeholder="Enter Semester Name"
              // size="middle"
              // onInput={(e: any) =>
              //   (e.target.value =
              //     e.target.value.length > 1
              //       ? e.target.value
              //       : e.target.value.toUpperCase())
              // }
              />
            </Form.Item> */}
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditSemester;
