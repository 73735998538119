import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  TimePicker,
  UploadFile,
  UploadProps,
} from "antd";
import "./EnquiryLead.css";
import dayjs from "dayjs";
import HTTPSCalls from "../../Services/HTTPCalls";
import DNCModel from "./DNCModel";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { AttachmentsCategory, CallType, CommunicationType, OutcomeType, outcomeTypeOptions } from "../../Constant/Enums";


const CallLogModel: React.FC<{
  open: boolean;
  id: string;
  type: number;
  apiData: any;
  onCancel: (rec: boolean, isUploadAtt: boolean) => void;
}> = (props) => {
  const [form] = Form.useForm();
  const [createTask, setCreateTask] = React.useState<boolean>(false);
  const [dncModelOpen, setDncModelOpen] = React.useState<boolean>(false);
  const [textValue, setTextValue] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setButtonLoading(true);
        let formValue = {
          ...values,
          enquiryId: props?.apiData?.id,
          type: CommunicationType.CallLog,
          attachmentsCategory: AttachmentsCategory.CallLog,
          attachments: values?.attachments ? await Promise.all(
            values?.attachments?.fileList?.map(
              async (itm: any, index: number) => {
                let currentFileBase64 = await getBase64(
                  itm?.originFileObj as RcFile
                );
                let fileInfo = {
                  fileName: {
                    id: "",
                    name: itm?.name,
                    contentType: itm?.type,
                    length: itm?.size,
                    path: currentFileBase64,
                  },
                  category: AttachmentsCategory.CallLog
                };
                return fileInfo;
              }
            )
          ) : [],
        };
        console.log("values = ", formValue)
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.EnquiryCommunication,
          {},
          formValue
        );
        if (response.status) {
          form.resetFields();
          message.success("Call Log Submitted");
          setButtonLoading(true);
          props.onCancel(true, !!formValue?.attachments);
        } else {
          message.error(response.message);
          setButtonLoading(true);
        }
      })
      .catch((error: any) => {
      });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    })
  }
  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                    {
                      file.name
                    }
  
                  </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };
  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) { },
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };
  return (
    <>
      <Modal
        title={`Add Call Log [${props?.apiData?.fullName}]`}
        maskClosable={false}
        open={props.open}
        onCancel={() => props.onCancel(false, false)}
        width={1080}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={() => props.onCancel(false, false)}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={buttonLoading}>
              {props?.id === "" ? "Save" : "Update"}
            </Button>
          </Space>
        }
      >
        {/* <Divider className="marginDivider" /> */}

        <div style={{ maxHeight: "59vh", overflowY: "auto", padding: "7px" }}>
          <Form form={form} requiredMark={true} layout="vertical">
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="name" label="Name" className="marginbtm">
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="number" label="Phone Number" className="marginbtm">
                  <InputNumber
                    style={{ width: "100%" }}
                    maxLength={10}
                    minLength={10}
                    controls={false}
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="callType"
                  label="Call Type"
                  className="marginbtm"
                  rules={[{ required: true, message: "Select Call Type" }]}
                >
                  <Select
                    placeholder="Choose Type"
                    options={[
                      {
                        value: CallType.Incoming,
                        label: "Incoming",
                      },
                      {
                        value: CallType.Outgoing,
                        label: "Outgoing",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="outcomeType"
                  label="Outcome Type"
                  className="marginbtm"
                >
                  <Select
                    placeholder="Choose Outcome"
                    options={outcomeTypeOptions}
                    onSelect={(e: any) => {
                      if (e === OutcomeType.DNC) {
                        setDncModelOpen(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="note" label="Note" className="marginbtm">
              <Input.TextArea
                rows={4}
                showCount
                placeholder="Add Note Here....."
                maxLength={100}
                onChange={(e: any) => {
                  setTextValue(e.target.value);
                  //console.log(textValue, "textValue");
                }}
              />
            </Form.Item>
            <Form.Item
              name={"attachments"}
              label="Add Attachment(s)"
            // rules={[
            //   {
            //     required: true,
            //     message: "this field is required",
            //   },
            // ]}
            >
              <Dragger
                {...uploadProps}
              // onPreview={async (file: UploadFile) => {
              //     console.log("testtest = ", await getBase64(
              //       file.originFileObj as any
              //     ))
              // }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Dragger>
            </Form.Item>
            {/* <Form.Item
              name="followUpTask"
              valuePropName="checked"
              className="marginbtm"
            >
              <Checkbox
                onChange={(e) => {
                  setCreateTask(e.target.checked);
                }}
              >
                <span className="themeColor fw-500">Create follow up task</span>
              </Checkbox>
            </Form.Item> */}
            {createTask && (
              <>
                <Form.Item
                  name="startDate"
                  label="Choose Date"
                  className="marginbtm"
                  rules={[{ required: true, message: "Select Date" }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    //defaultValue={dayjs()}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Form.Item>

                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item name="startTime" className="marginbtm">
                      <TimePicker
                        style={{ width: "100%" }}
                        format={"h:mm A"}
                        placeholder="StartTime"
                      //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item name="endTime" className="marginbtm">
                      <TimePicker
                        style={{ width: "100%" }}
                        format={"h:mm A"}
                        placeholder="EndTime"
                      //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </div>
      </Modal>
      {dncModelOpen && (
        <DNCModel
          open={dncModelOpen}
          text={"Are you sure you want to add this number in DNC ?"}
          onOk={() => setDncModelOpen(false)}
          onCancel={() => setDncModelOpen(false)}
        />
      )}
    </>
  );
};

export default CallLogModel;
