import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
    Button,
    Col,
    Row,
    Tabs,
    Typography,
    TabsProps,
    Modal,
    Space,
    Input,
    Form,
    InputNumber,
} from "antd";
import DegreeList from "./DegreeList";
import DegreeAuditList from "../DegreeAudit/DegreeAuditList";
import StudentAuditList from '../StudentAudit/StudentAuditList';
import AlumniList from "../Alumni/AlumniList";

const onChange = (key: string) => {
    console.log(key);
};
const items: TabsProps["items"] = [
    {
        key: "1",
        label: "Degree",
        children: <DegreeList />,
    },
    {
        key: "2",
        label: "Degree Audit",
        children: <DegreeAuditList/>,
    },
    {
        key: "3",
        label: "Student Audit",
        children: <StudentAuditList/>,
    },
    {
        key: "4",
        label: "Alumni",
        children: <AlumniList/>,
    },
];
const Degree: React.FC = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSubmit = () => {
        form.validateFields().then((values: any) => {
            console.log(values);
            form.resetFields()
        }).catch((error: any) => {
            console.log(error);
        })
    }
    return (
        <>
            <Row>
                <Col lg={12}>
                    <Typography.Title
                        level={5}
                        style={{ color: "#373941", fontWeight: 500 }}
                    >
                        Graduation and Alumni
                    </Typography.Title>
                    <Typography.Paragraph style={{ color: "GrayText" }}>
                        Where you can manage Graduation structure and alumni
                    </Typography.Paragraph>
                </Col>

                <Col lg={12} style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        type="primary"
                        style={{ marginTop: "15px" }}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Add Degree
                    </Button>
                </Col>
            </Row>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            <Modal
                title="Add Degree  "
                open={isModalOpen}
                maskClosable={false}
                onCancel={() => setIsModalOpen(false)}
                footer={
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            marginRight: 0,
                        }}
                    >
                        <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary">
                            Save
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    layout="horizontal"
                    labelCol={{ lg: 9 }}
                    labelAlign="left"
                    requiredMark={false}
                    colon={false}
                >
                    <Form.Item label="Degree Name" name="degreeName">
                        <Input
                            style={{ width: "100%" }}
                            onKeyPress={(e) => !/[a-z]/.test(e.key) && e.preventDefault()}
                        />
                    </Form.Item>
                    <Form.Item label="Credits" name="credits" rules={[{
                        required: true,
                        message: 'Required Field'
                    }]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            controls={false}
                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        />
                    </Form.Item>
                    <Form.Item label="Cumulative GPA (CGPA)" name="cgpa">
                        <InputNumber
                            style={{ width: "100%" }}
                            controls={false}
                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Degree;
