import { Row, Typography, Tabs } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import "./ManageWeight/Weigth.css"

import type { TabsProps } from "antd";
import DisplayCategory from "./AssessmentsCategory/DisplayCategory";
import DisplayAssements from "./Assessments/DisplayAssements";
import DisplayGrade from "./Grade Setup/DisplayGrade";
import DisplayWeight from "./ManageWeight/DisplayWeight";

const onChange = (key: string) => {
  console.log(key);
};

const Assessments_Grades: React.FC = () => {
  const [isAssessModalOpen, setAssessIsModalOpen] = useState<boolean>(false);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Assessments Category",
      children: (
        <DisplayCategory
          isAssessModalOpen={isAssessModalOpen}
          setAssessIsModalOpen={setAssessIsModalOpen}
        />
      ),
    },
    {
      key: "2",
      label: "Assessments",
      children: <DisplayAssements />,
    },
    {
      key: "3",
      label: "Grade Setup",
      children: <DisplayGrade />,
    },
    {
      key: "4",
      label: "Manage weight",
      children: <DisplayWeight />,
    },
  ];

  return (
    <>
      <div className="roundedCornerMedium bg-white px-3 py-2">
        {/* <Row>
          <Typography.Title
            level={5}
            style={{
              color: "#373941",
              fontWeight: 500,
              fontSize: "1.5rem",
              // borderBottom: "1px solid #D9D9D9",width:"100%"
            }}
          >
            Assessment & Grades
          </Typography.Title>
        </Row> */}
        {/* <Row>
            <Typography.Text style={{ marginBottom: 14, marginTop: -8, fontSize: 12 }}>
              Where you can manage Assessments and Grades
            </Typography.Text>
          </Row> */}
        <Tabs
          className="custom-tabs"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default Assessments_Grades;
