import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Modal, Space, Button } from "antd";

const EnrollForm: React.FC<{
  onDismiss: any;
  htmlContents: any;
  onDownload: any;
  viewDownload: any;
}> = (props) => {
  return (
    <>
      <Modal
        width={"45vw"}
        title={"Enroll Form"}
        style={{ top: 10 }}
        open={true}
        footer={() => (
          <>
            <Space>
              <Button type="primary" ghost onClick={() => props.onDismiss()}>
                Cancel
              </Button>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                loading={props.viewDownload}
                ghost
                onClick={props.onDownload}
              >
                Download
              </Button>
            </Space>
          </>
        )}
        maskClosable={false}
        onCancel={() => props.onDismiss()}
      >
        <>
          <div
            style={{
              maxHeight: "83vh",
              overflowY: "auto",
            }}
          >
            <div
              // style={{ maxHeight: "750px", overflowY: "auto" }}
              dangerouslySetInnerHTML={{ __html: props.htmlContents }}
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default EnrollForm;
