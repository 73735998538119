/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  EyeTwoTone,
  FormOutlined,
  InfoCircleTwoTone,
  LoadingOutlined,
  LogoutOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Table,
  Spin,
  message,
  Row,
  Col,
  Breadcrumb,
  Tooltip,
  Space,
  Badge,
  Popconfirm,
  ConfigProvider,
  Switch,
  Dropdown,
  MenuProps,
  Typography,
} from "antd";
import "./Enquiry.css";
import { Button } from "antd";
import { Select } from "antd";
import HTTPSCalls from "../Services/HTTPCalls";
import { ColumnsType } from "antd/es/table";
import EnquiryForm from "./EnquiryForm";
import { dayjs } from "../Utilities/dayjs";
import {
  CandidateRequestType,
  CenterVisited,
  CenterVisitedOptions,
  CommonEnqListType,
  MasterTypes,
  ProcessStepsEnumName,
  ResponseStatusOption,
  outcomeType,
  processStepsOptions,
  responseStatus,
} from "../Constant/Enums";
import Search from "antd/es/input/Search";
import "../Custom.css";
import { ApiCalls } from "../ApisCaller/ApiCall";
import { EnquiryEndPoint, SourceMaster } from "../ApiUrls/URLS";
import { ApiUtility } from "../Utilities/ApiUtility.axios";
import { pageSizeOption } from "../component/Users/UserList";
import { AssigneeAvatar } from "../Common/AssigneeAvatar";
import { ViewType } from "../component/EnquiryLeadModel/EmailEnquiry";

const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");
const api1 = new ApiCalls(SourceMaster.endPoints, SourceMaster.prefix);
interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}
interface IEnquiryTable {
  id: string;
  key: number;
  name: string;
  fatherName: string;
  email: string;
  dob: string;
  category: string;
  passOutYear: number;
  curriculum: string;
  stream: string;
  reference: string;
  mobileNumber: string;
}
interface ICommonEnquiryList {
  columns: {
    id?: boolean;
    name?: boolean;
    phoneNo?: boolean;
    email?: boolean;
    activity?: boolean;
    assignedTo?: boolean;
    status?: boolean;
    all?: boolean;
  };
  filter: CommonEnqListType;
  setEditRecord?: any;
  onDismiss?: any;
  listParams1?: any;
  setListParams1?: any;
  changeListParams?: (key: string, value: any) => void;
  options: any;
}

//#region  Custom TableHeader
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
  defaultValue,
}: any) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: "#1B1A1A",
              colorFillAlter: "transparent",
            },
            Select: {
              colorTextPlaceholder: "#1B1A1A",
            },
          },
        }}
      >
        <div
          className="searchDivStyle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {ifSelect ? (
            <Select
              allowClear
              style={{ width: "100%" }}
              onSelect={(value) => {
                // props?.changeListParams(inputName, value);
                // Type guard to check if changeListParams is defined
                if (changeListParams) {
                  changeListParams(inputName, value);
                }
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              onClear={() => {
                if (inputName === "counselors" || inputName === "sourceSearch") changeListParams(inputName, "");
                else changeListParams(inputName);
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              popupMatchSelectWidth={false}
              variant="borderless"
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={option}
              // defaultValue={defaultValue}
            />
          ) : (
            <Search
              placeholder={title}
              className="CitSearchInput"
              allowClear
              onChange={(e) => {
                if (changeListParams) {
                  changeListParams(inputName, e.target?.value);
                }
                // props?.changeListParams(inputName, e.target?.value)
              }}
              onSearch={(val) => {
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              variant="borderless"
            />
          )}
        </div>
      </ConfigProvider>
    </>
  );
};
//#endregion
const CommonEnquiryList = (props: ICommonEnquiryList) => {
  const [downloadLoading,setDownloadLoading] = useState<string>("")
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [masterData, setMasterData] = useState<{
    status: any;
  }>({
    status: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [listParams, setListParams] = useState<any>({
    filterId: "",
    nameSearch: "",
    batchSearch: "",
    sortCol: "",
    sortDir: "",
    userRoleId: "",
    start: 0,
    length: 10,
  });
  const [isCSVDownloading, setIsCSVDownloading] = useState<boolean>(false);
  const [empList, setEmpList] = useState([]);
  // Drawer states and functions
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [basicDetailFrm, setBasicDetailFrm] = useState<any>({});
  const [credentialFrm, setCredentialFrm] = useState<any>({});
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  // const [listParams, setListParams] = useState({
  //   filterId: "",
  //   nameSearch: "",
  //   batchSearch: "",
  //   sortCol: "",
  //   sortDir: "",
  //   userRoleId: "",
  // });

  let userType: any = JSON.parse(localStorage.getItem("tp-user") + "")?.role;
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        status: any;
      } = {
        status: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Status
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };
  // const setListParamsAndRefresh = (key: string, value: any) => {
  //   setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
  //   setListParams((p: any) => {
  //     p[key] = value;
  //     return p;
  //   });
  // };

  //---------------------------------------------- Get and post function for master and enquiry -----------------------------------------------------

  const downloadCSV = async () => {
    setIsCSVDownloading(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.ENQUIRY_CSV_DOWNLOAD,
      {
        requestType: CandidateRequestType.Enquire,
        filterId: listParams?.filterId,
        batchSearch: listParams?.batchSearch,
        nameSearch: listParams?.nameSearch,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        userRoleId: listParams?.userRoleId,
      }
    );
    if (res?.status) {
      setIsCSVDownloading(false);
    } else {
      setIsCSVDownloading(false);
    }
  };


  //#region Enquiry List GET
  const getEnquiryData = async () => {
    setIsLoading(true)
    ;
    let res = await api.GET(EnquiryEndPoint.getList + "?", {
      type: props?.listParams1?.type,
      filterId: listParams?.filterId,
      batchSearch: listParams?.batchSearch,
      nameSearch: props?.listParams1?.nameSearch,
      source: props?.listParams1?.sourceSearch,
      start: props?.listParams1.start,
      length: props?.listParams1.length,
      sortCol: props?.listParams1.sortCol,
      sortDir: props?.listParams1?.sortDir,
      userRoleId: props?.listParams1?.userRoleId,
      streamSearch: "",
      snoType: true,
      fatherName: props?.listParams1?.fatherName,
      phoneNo: props?.listParams1?.phoneNo,
      centerVisited: props?.listParams1?.centerVisited,
      responseType: props?.listParams1?.responseType,
      counselors: props?.listParams1?.counselors,
      processStep: props?.listParams1?.processStep,
      // nameSearch: listParams?.nameSearch,
      // start: listParams.start,
      // length: listParams.length,
      // sortCol: listParams.sortCol,
      // sortDir: listParams?.sortDir,
      // userRoleId: listParams?.userRoleId,
    });
    // setPagingItems((p) => {
    //   p.totalRecords = res?.result?.totalRecords;
    //   return p;
    // });
    setTotalNoOfRecords(res?.result?.totalRecords);
    console.log(
      "UserListssss",
      res?.result?.items?.map((itm: any, indx: number) => ({
        key: indx,
        counselorsData: itm.counselorsData,
      }))
    );
    setDataSource(
      res?.result?.items?.map((arrayItem: any, index: number) => ({
        ...arrayItem,
        id: arrayItem?.id,
        sno: arrayItem?.sno,
        enqId: arrayItem?.incrementalSeries,
        key: index,
        name: arrayItem?.name,
        fullName: arrayItem?.fullName,
        fatherName:
          arrayItem?.fatherName !== null ? arrayItem?.fatherName : "-",
        email: arrayItem?.contactDetail?.email,
        approvalStatus: arrayItem?.approvalStatus,
        response:
          arrayItem?.response !== 0 ? responseStatus[arrayItem?.response] : "-",
        centerVisited:
          arrayItem?.centerVisited !== 0
            ? CenterVisited[arrayItem?.centerVisited]
            : "-",
        dob: dayjs(arrayItem?.dateOfBirth)?.format("DD MMM YYYY"),
        enquiryDate: dayjs(arrayItem?.enquiryDate)?.format("DD MMM YYYY"),
        category: arrayItem?.category?.name,
        counselors: arrayItem?.counselors ?? undefined,
        passOutYear: arrayItem?.currentPassOutYear,
        curriculum: arrayItem?.curriculum?.name,
        stream: arrayItem?.stream?.name,
        source: arrayItem?.sources?.name, 
        referralSource:
          arrayItem?.referralSource !== null ? arrayItem?.referralSource : "-",
        phoneNo: arrayItem?.contactDetail?.mobileNumber,
        amount: arrayItem?.amount,
        isActive: arrayItem?.isActive,
        assignTo: arrayItem?.assignTo,
        counselorsData:
          arrayItem?.counselorsData?.map((itm: any, indx: number) => {
            return {
              ...itm,
              name: itm?.name?.first,
              thumb: itm?.userImage?.path,
            };
          }) ?? undefined,
        // counselorsData: arrayItem?.userImage?.map((itm:any, indx:number) => itm.path)
      }))
    );
    setIsLoading(false);
  };
  //#endregion
  const getList = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?",
      listParams
    );
    if (res?.result?.items?.length > 0) {
      setEmpList(
        res?.result?.items?.map((item: any) => ({
          value: item?.id,
          label: item?.name?.first,
        }))
      );
    }
  };

  // useEffect(() => {
  //   getMaster();
  // }, []);
  let StudyModeObj: any = {
    0: "Regular",
    1: "Private",
  };
  let LevelObj: any = {
    0: "Beginner",
    1: "Intermediate",
    2: "Advanced",
  };

  const getById = async (id: string) => {
    setIsLoading(true);
    let res = await api.GET(EnquiryEndPoint.getById + "?", { id });
    setBasicDetailFrm({
      ...res?.result,
      gender: {
        label: res?.result?.gender?.name,
        value: res?.result?.gender?.id,
      },
      medium: {
        label: res?.result?.medium?.name,
        value: res?.result?.medium?.id,
      },
      category: {
        label: res?.result?.category?.name,
        value: res?.result?.category?.id,
      },
      curriculum: {
        label: res?.result?.curriculum?.name,
        value: res?.result?.curriculum?.id,
      },
      batch: {
        label: res?.result?.batch?.name,
        value: res?.result?.batch?.id,
      },
      stream: {
        label: res?.result?.stream?.name,
        value: res?.result?.stream?.id,
      },
      englishProficiency: {
        label: LevelObj[res?.result?.englishProficiency],
        value: res?.result?.englishProficiency,
      },

      dateOfBirth:
        res?.result?.dateOfBirth === ""
          ? undefined
          : dayjs(res?.result?.dateOfBirth),
      academicRecord: res.result?.academicRecord?.map(
        (arrayItem: any, index: any) => ({
          key: index,
          ...arrayItem,
          class: {
            label: arrayItem?.class?.name,
            value: arrayItem?.class?.id,
          },
          studyMode: {
            label: StudyModeObj[arrayItem?.studyMode],
            value: arrayItem?.studyMode,
          },
        })
      ),
      attachments: res?.result?.attachments?.map((itm: any, index: any) => {
        return {
          key: index,
          ...itm,
          fileType: {
            label: itm?.fileType?.name,
            value: itm?.fileType?.id,
          },
          fileName: {
            id: itm?.fileName?.id,
            name: itm?.fileName?.name,
            contentType: itm?.fileName?.contentType,
            length: itm?.fileName?.length,
            path: itm?.fileName?.path,
          },
          description: itm?.description,
        };
      }),
    });
    setCredentialFrm({
      approvalStatus: {
        label: res?.result?.approvalStatus?.name,
        value: res?.result?.approvalStatus?.id,
      },
      amount: res?.result?.amount,
      cardNo: res?.result?.cardNo,
      studentId: res?.result?.studentId,
      enrolledDate: dayjs(res?.result?.enrolledDate).format("DD MMM YYYY"),
      credentials: res?.result?.credentials?.map(
        (arrayItem: any, index: any) => ({
          ...arrayItem,
          key: index,
          credentialName: {
            value: arrayItem?.credentialName?.id,
            label: arrayItem?.credentialName?.name,
          },
        })
      ),
    });
    onOpen();
    setIsLoading(false);
  };

  //*********************** .COM*/
  const [tableData, setTableData] = useState<any>([]);
  const [showIsApproveAll, setShowIsApproveAll] = useState(false);
  const [approveRequestList, setApproveRequestList]: any = useState([]);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeListParams = (key: string, value: any) => {
    props?.setListParams1((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);

  const items: MenuProps["items"] = [
    {
      label: "CV",
      key: "CV",
      icon: <EyeTwoTone />,
      onClick: () => {
        console.log("CCCCCCCCCCCCCCCCCVVVVVVVVVVVVVVVVVVVVVV");
      },
    },
    {
      label: "Enroll Form",
      key: "enrollForm",
      icon: <EyeTwoTone />,
    },
  ];
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };
  const menuProps: any = {
    items,
    // onClick: handleMenuClick,
  };
  //#region Table Columns
  const tableColumns: ColumnsType<any> = [
    {
      // title: (
      //   <THeader
      //     title={"Enquiry ID"}
      //     inputName={"enqId"}
      //     changeListParams={changeListParams}
      //     setShouldRefresh={setRefresh}
      //     listParams={listParams}
      //     setListParams={setListParams}
      //     ifSelect={false}
      //   />
      // ),
      title: "S No.",
      dataIndex: "sno",
      key: "id",
      width: "4%",
      render: (sno: string) => (
        <div style={{ fontSize: "12px" }}>{sno ?? "-"}</div>
      ),
    },
    // {
    //   // title: (
    //   //   <THeader
    //   //     title={"Enquiry ID"}
    //   //     inputName={"enqId"}
    //   //     changeListParams={changeListParams}
    //   //     setShouldRefresh={setRefresh}
    //   //     listParams={listParams}
    //   //     setListParams={setListParams}
    //   //     ifSelect={false}
    //   //   />
    //   // ),
    //   title: "Enquiry ID",
    //   dataIndex: "enqId",
    //   key: "id",
    //   // width: 150,
    //   render: (enqId: string) => (
    //     <div style={{ fontSize: "12px" }}>{enqId ?? "-"}</div>
    //   ),
    // },
    {
      title:"Full Name",
      dataIndex: "fullName",
      key: "FullName",
      ellipsis: true,
      sorter:true,
      
      width: "15%",
      // responsive: ["xl"],
      render: (fullName: string, record: any) => (
        <Typography.Link
          // type="link"
          onClick={() => {
            props?.setEditRecord(record);
          }}
        >
          {fullName}
        </Typography.Link>
      ),
      // width: 280
    },
    {
      title: "Father's Name",
      dataIndex: "fatherName",
      key: "FatherName",
      sorter:true,
      responsive: ["xl"],
      width: "12%",
      ellipsis: true,
      render: (fatherName: string) => (
        <span className=" font13">{fatherName}</span>
      ),
    },
    {
      title: "Contact No.",
      dataIndex: "phoneNo",
      sorter:true,
      key: "PhoneNo",
      responsive: ["xl"],
      width: 150,
      render: (phoneNo: string) => (
        <span className="ps-2 font13">{phoneNo}</span>
      ),
    },
    {
      // title: "Center Visited",
      title: (
        <>
          <THeader
            title={"Center Visited"}
            inputName={"centerVisited"}
            changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={props?.listParams1}
            setListParams={props?.setListParams1}
            ifSelect={true}
            option={CenterVisitedOptions}
          />
        </>
      ),
      dataIndex: "centerVisited",
      // align:"center",
      key: "centerVisited",
      responsive: ["xl"],
      width: 150,
      render: (val: any) => <span className=" font13" style={{paddingLeft:"12px"}}>{val}</span>,
    },
    {
      // title: "Status",
      title: (
        <>
          <THeader
            title={"Status"}
            inputName={"responseType"}
            changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={props?.listParams1}
            setListParams={props?.setListParams1}
            ifSelect={true}
            option={ResponseStatusOption}
          />
        </>
      ),
      dataIndex: "response",
      key: "response",
      responsive: ["xl"],
      // width: 150,
      render: (val: any) => <span className=" font13">{val}</span>,
    },
    {
      // title: "Counselor",
      title: (
        <THeader
          title={"Counselor"}
          inputName={"counselors"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={props?.listParams1}
          setListParams={props?.setListParams1}
          ifSelect={true}
          option={props?.options}
        />
      ),
      dataIndex: "counselorsData",
      key: "counselors",
      responsive: ["xl"],
      width: 150,
      render: (val: any) => (
        <span className=" font13">
          {!!val ? <AssigneeAvatar data={val} size={"small"} /> : "-"}
        </span>
      ),
    },
    // {
    //   title: "Assign To",
    //   dataIndex: "assignTo",
    //   key: "assignTo",
    //   responsive: ["xl"],
    //   // width: 150,
    //   render: (val: any) => (
    //     <span className=" font13"><AssigneeAvatar data={val} /></span>
    //   ),
    // },
    {
      title: "ENQ Date",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      responsive: ["xl"],
      width: "8%",
      render: (val: string) => <span className=" font13">{val}</span>,
    },
    {
      title: (
        <THeader
          title={"Source"}
          inputName={"sourceSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={props?.listParams1}
          setListParams={props?.setListParams1}
          ifSelect={true}
          option={sourceOptions} 
        />
      ),
      dataIndex: "source",
      key: "sourceSearch",
      responsive: ["xl"],
      ellipsis: true,
      // width: 150,
      render: (val: any) => <span className=" font13">{val ||"-"}</span>,
    },
    // {
    //   // title: (
    //   //   <THeader
    //   //     title={"Email Address"}
    //   //     inputName={"email"}
    //   //     changeListParams={changeListParams}
    //   //     setShouldRefresh={setRefresh}
    //   //     listParams={listParams}
    //   //     setListParams={setListParams}
    //   //     ifSelect={false}
    //   //   />
    //   // ), 
    //   title: "Email Address",
    //   dataIndex: "email",
    //   key: "email",
    //   render: (addedBy: string) => (
    //     <span className="ps-2 font13">{addedBy}</span>
    //   ),
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Activity"}
    //       inputName={"activity"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "activity",
    //   key: "activity",
    //   render: (activity: string) => (
    //     <span className="ps-2 font13">{activity}</span>
    //   ),
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Assigned To"}
    //       inputName={"assignedTo"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "assignedTo",
    //   key: "assignedTo",
    //   render: (assignedTo: string) => (
    //     <span className="ps-2 font13">{assignedTo}</span>
    //   ),
    // },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"processStep"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={props?.listParams1}
          setListParams={props?.setListParams1}
          ifSelect={true}
          option={processStepsOptions}
          // defaultValue={{ label: "Pending", value: 2 }}
        />
      ),
      // title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      render: (_: any, record: any) => (
        <>{ProcessStepsEnumName[record?.processStep?.processStep]}</>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "actions",
    //   key: "actions",
    //   ellipsis: true,
    //   // width: 150,
    //   render: (val: string,record) => (
    //     //   <>
    //     //   <Dropdown.Button
    //     //     size="small"
    //     //     type="link"
    //     //     icon={<DownOutlined />}
    //     //     trigger={["click"]}
    //     //     menu={menuProps}
    //     //     onClick={() => {
    //     //       // window.open(att?.fileName?.path, "_blank");
    //     //     }}
    //     //   >
    //     //     Download
    //     //   </Dropdown.Button>
    //     // </>
    //     <Select
    //       size="small"
    //       loading={downloadLoading===record?.id}
    //       style={{width:"100%"}}
    //       placeholder="Download"
    //       allowClear
    //       options={[
    //         { label: "All", value: 1 },
    //         { label: "CV", value: 2 },
    //         { label: "Enrollment", value: 3},
    //       ]}
    //       onChange={(val)=>{
            
    //         downloadDocument(val,record?.id);
    //       }}
    //     />
    //   ),
    // },
    // {
    //   title: " ",
    //   width: "3%",
    //   dataIndex: "isActive",
    //   render: (isActive, record) => (
    //     <>
    //       <Tooltip placement="top" color="#1677ff">
    //         {/* <Select
    //           // bordered={false}
    //           style={{ width: "100%" }}
    //           // onChange={handleChange}
    //           // value={ApprovalStatus[approvalStatus]}
    //           options={[
    //             {
    //               value: ApprovalStatus.Rejected,
    //               label: "True",
    //             },
    //             {
    //               value: ApprovalStatus.OptionalOut,
    //               label: "Optional Out",
    //             }
    //           ]}
    //           onSelect={(val: any) => {
    //             let res = {
    //               id: record?.id,
    //               fieldName: "ApprovalStatus",
    //               valueType: "enum",
    //             };
    //             IsActivePost(res, val);
    //           }}
    //         /> */}
    //         <Switch size="small" defaultValue={isActive} defaultChecked={isActive} onChange={(val: any) => {
    //           console.log(val);

    //           let res = {
    //             id: record?.id,
    //             fieldName: "IsActive",
    //             valueType: "bool",
    //           };
    //           IsActivePost(res, val);
    //         }} />
    //         {/* {approvalStatus === ApprovalStatus.Enrolled ? (
    //         <Tag color="success">Enrolled</Tag>
    //       ) : approvalStatus === ApprovalStatus.Rejected ? (
    //         <Tag color="error">Rejected</Tag>
    //       ) : approvalStatus === ApprovalStatus.OnHold ? (
    //         <Tag color="warning">On Hold</Tag>
    //       ) : approvalStatus === ApprovalStatus.OptionalOut ? (
    //         <Tag color="processing">Optional Out</Tag>
    //       ) : (
    //         <Tag color="cyan">Interested</Tag>
    //       )} */}

    //         {isActive}
    //       </Tooltip>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    const fetchSources = async () => {
      const res = await api1.GET(SourceMaster.endPoints.getList + "?", {
        masterType: MasterTypes.Source,
        status: "active",
      });
      if (res?.result) {
        const sourceOptions = res?.result?.items.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        }));
        setSourceOptions(sourceOptions); 
      } else {
        message.error("Failed to fetch sources.");
      }
    };
  
    fetchSources();
  }, []);

  const downloadDocument = async (val:number,id:string)=>{
    if(val && id ){
      setDownloadLoading(id)
       if(val == 2){
        await downloadCV(id)
       }
       else if(val === 3){
        await  downloadEnrollForm(id)
       }else{
        await  downloadCV(id)
        await downloadEnrollForm(id)

       }
       setDownloadLoading("")

    }
   }
   const downloadCV = async(id:string)=>{
    await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {enqId: id,
      type: ViewType.CV,}
    );
  }
   const downloadEnrollForm =async (id:string)=>{
    await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {
        enqId:id ,
        type: ViewType.Enroll,
      }
    )
   }
  const IsActivePost = async (res: any, value: any) => {
    setIsLoading(true);
    await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.ENQUIRY_UPDATE + "?",
      { ...res },
      value
    )
      .then((res: any) => {
        setShouldRefresh((x: boolean) => !x);
        message.success("Updated Successfully");
        setIsLoading(false);
      })
      .catch((error: any) => {
        // setRecall(1);
        setShouldRefresh((x: boolean) => !x);
        setIsLoading(false);
        message.error(error?.message);
      });
  };
  //#endregion
  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];

      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item);
      });
      setApproveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsApproveAll(true)
        : setShowIsApproveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };
  useEffect(() => {
    getEnquiryData();
  }, [props.listParams1, refresh]);

  const filterColumns = (): any => {
    console.log("table",tableColumns);
    if (props?.columns?.all) return tableColumns;
    return tableColumns?.filter((itm: any) => {
      if (props?.columns[itm?.key as keyof typeof props.columns] === true) {
        return itm;
      }
    });
    
  };
  return (
    <>
      <Row>
        <Col lg={24}>
          <Table
            size="small"
             style={{
              width:"100%",
              maxHeight:730,
              overflow:"auto",
              minHeight:700
             }}
            columns={filterColumns()}
            dataSource={dataSource}
            loading={tableLoading}
            // rowSelection={rowSelection}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (page, pageSize) => {
                changeListParams("start", (page - 1) * pageSize);
                changeListParams("length", pageSize);
                setRefresh((x) => !x);
              },
              current:
                props?.listParams1?.start / props?.listParams1?.length + 1,
              pageSize: props?.listParams1?.length,
              showSizeChanger: true,
              total: totalNoOfRecords,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
  
            onChange={(paging, filter, sort: any) => {
              sort?.order &&
                props?.setListParams1({
                  ...props?.listParams1,
                  sortDir: sort?.order,
                  sortCol: sort?.columnKey
                })

            }}

            
            scroll={{ y: `calc(100vh - 330px)` }}
          />
        </Col>
      </Row>
    </>
  );
};
export default CommonEnquiryList;
