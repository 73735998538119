import * as React from 'react';        
import {useState} from "react" 
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Row, Table } from 'antd';
import HTTPSCalls from '../../Services/HTTPCalls';
import AddEditECard from './AddEditECards';
import { render } from '@testing-library/react';
const Ecard = ()=>{
    const [requestParams,setRequestParams] = React.useState<{
        start:number,
        length:number,
        fieldName?:string,
        fieldValue?:string,
        sortFieldName?:string,
        sortFieldDir?:string
    }>({
        start:0,
        length:15
    })
    const [openDrawer, setOpenDrawer] = React.useState<string>("");
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eCardId, setECardId] = useState<string | undefined>();
    const [dataSource,setDataSource] = React.useState<any>(undefined)
    const columns = [
        {
            key:"name",
            title:"Card No.",
            dataIndex:"cardNo",
        },
        {
            key:"assignee",
            title:"Series",
            dataIndex:"incrementalSeries"
        },
        //  {
        //     key:"status",
        //     title:"Status",
        //     dataIndex:"status"
        // },
        {
            key:"biometricCode",
            title:"Biometric",
            dataIndex:"biometricCode"
        },
        {
            key:"student",
            title:"Student",
            dataIndex:"student",
            render:(val:any)=><>{val?.name?val?.name:"-"}</>
        }
    ]
    React.useEffect(()=>{
        getList()
    },[shouldRefresh])

    const getList=async()=>{
        setLoading(true)
        let res:any = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_ECARD_LIST + "?",requestParams)
        setDataSource(res?.result?.items?.map((itm:any,index:number)=>({...itm,key:index})))
        setLoading(false)
    }
    return (
    <>

            <Row justify="end" align="middle" style={{ marginBottom: "6px" }}>
                <Breadcrumb
                    style={{ color: "#172B4D" }}
                    items={[
                        {
                            title: "E Card",
                        },
                    ]}
                ></Breadcrumb>
            </Row>

            <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
                <Col span={24}>
                    <Row justify="end" gutter={[24, 18]}>
                        <Col md={24}>
                            <Button
                                type="primary"
                                className="cit-add-btn"
                                onClick={() => {
                                    // setEditId("");
                                    setOpenDrawer("showDrawer");
                                }}
                                icon={<PlusOutlined />}
                            >
                                Ecard
                            </Button>
                        </Col>
                    </Row>
                    <Col span={24} style={{ paddingTop: "12px" }}>
                        <Table
                            size="small"
                            // className="cit-table"
                            dataSource={dataSource}
                            loading={{indicator:<LoadingOutlined style={{ fontSize: 24 }} spin/>,spinning:loading}}
                            columns={columns}
                            scroll={{ x: 1100, y: "65vh" }}
                            // pagination={{
                            //     showTotal: (total, range) =>
                            //         `${range[0]}-${range[1]} of ${total} items`,
                            //     current: listParams.start / listParams.length + 1,
                            //     pageSize: listParams.length,
                            //     showSizeChanger: true,
                            //     total: totalRecords,
                            //     onChange: (page, pageSize) => {
                            //         changeListParams("start", (page - 1) * pageSize);
                            //         changeListParams("length", pageSize);
                            //         setShouldRefresh((x) => !x);
                            //     },
                            //     pageSizeOptions: pageSizeOption,
                            //     position: ["bottomRight"]
                            // }}
                        />
                    </Col>
                </Col>
            </Row>

           { openDrawer === "showDrawer" &&  <AddEditECard

        eCardId={eCardId}
    onDismiss={(rec:any)=>{setOpenDrawer("")
        if(rec){
            setShouldRefresh(x=>!x)
        }
    }}
      />}
 </>
    )
}

export default Ecard


