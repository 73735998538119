import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { FeesTypeMaster, PaymentMethodMaster } from "../../ApiUrls/URLS";
import { MasterTypes } from "../../Constant/Enums";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import HTTPSCalls from "../../Services/HTTPCalls";
import { idText } from "typescript";
const masterApi = new ApiCalls(
  PaymentMethodMaster.endPoints,
  PaymentMethodMaster.prefix
);
interface RegistrationFormProps {
  open: boolean;
  id: string;
  data: any;
  onCancel: (val: boolean, feesData: any, withoutFees: boolean) => void;
  // isWithoutAmount?: boolean
}
interface LabelValue {
  label: string;
  value: string;
}

interface IdName {
  id: string;
  name: string;
}
const RegistrationFees = ({
  open,
  id,
  data,
  onCancel,
}: // isWithoutAmount
RegistrationFormProps) => {
  const [form] = Form.useForm();
  const [textValue, setTextValue] = React.useState<string>("");
  const [masterOptions, setMasterOptions] = React.useState<any>({
    feesTypeOption: [{ label: "", value: "" }],
    paymentDetails: [{ label: "", value: "" }],
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [withoutFees, setWithoutFees] = useState<boolean>(false);

  //--------Get FeesType Endpoint---------//
  const GetFeesType = async () => {
    let res = await masterApi.GET(FeesTypeMaster.endPoints.getList + "?", {
      masterType: MasterTypes.FeesType,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const feesTypeOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      feesTypeOption: feesTypeOptions,
    }));
  };

  //-----------toggle button-----------//
  const handleToggle = () => {
    setWithoutFees(!withoutFees);
  };

  //--------Get PaymentType Endpoint---------//

  const GetPaymentMethods = async () => {
    let res = await masterApi.GET(PaymentMethodMaster.endPoints.getList + "?", {
      masterType: MasterTypes.PaymentMethod,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const paymentOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      paymentDetails: paymentOptions,
    }));
  };
  function convertLabelValueToIdName(labelValue: LabelValue): IdName {
    return {
      id: labelValue?.value,
      name: labelValue?.label,
    };
  }
  React.useEffect(() => {
    GetFeesType();
    GetPaymentMethods();
  }, []);

  const onSubmit = async () => {
    form.validateFields().then(async (formValues: any) => {
      let val: any = null;
      setSaveLoading(true);
      if (withoutFees) {
        val = {
          ...formValues,
        };
      } else {
        val = {
          ...formValues,
          student: { name: data?.name, id: data?.id },
          medium: {
            id: formValues?.medium?.value,
            name: formValues?.medium?.label,
          },
          feesType: {
            id: formValues?.feesType?.value,
            name: formValues?.feesType?.label,
          },
        };
      }
      console.log("datadatadatadata = ", val);
      if (withoutFees) {
        onCancel(true, val, withoutFees);
      } else {

        await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.PAYMENT_ADD,
          {},
          {
            ...val,
          }
        ).then((res) => {
          if (res?.message) {
            message.error(res?.message);       
            setIsLoading(false);
            onCancel(false, undefined, false);
          } else {
            onCancel(true, val, withoutFees);
            message.success("Submitted Successfully");
            //   setReset((prev: number) => prev + 1);
            setIsLoading(false);
          }
          setSaveLoading(false);
        });
      }
    });
  };
  return (
    <>
      <Modal
        title={
          <>
            Registration Fees <Tag color="blue">{data?.fullName}</Tag>
          </>
        }
        open={open}
        maskClosable={false}
        onCancel={() => onCancel(false, undefined, false)}
        width={500}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={() => onCancel(false, undefined, false)}>
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" loading={saveLoading}>
              Save
            </Button>
          </Space>
        }
      >
        <Divider className="marginDivider" />

        <Form
          form={form}
          layout="vertical"
          requiredMark={true}
          initialValues={{ date: dayjs() }}
        >
          <div>
            <Row gutter={[24, 16]} style={{ marginBottom: 15 }}>
              <Col>
                <Switch
                  onClick={handleToggle}
                  checkedChildren="Without fees"
                  unCheckedChildren="With fees"
                  value={withoutFees}
                />
              </Col>
            </Row>
            {withoutFees ? (
              <Form.Item
                name="noteForWithoutFees"
                label="Note"
                rules={[
                  {
                    required: true,
                    message: "Please enter a note",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  showCount
                  placeholder="Add Note Here....."
                  maxLength={100}
                />
              </Form.Item>
            ) : (
              <>
                {/* Fees amount section */}

                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item
                      name="date"
                      label="Date"
                      className="marginbtm"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        allowClear={false}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) =>
                          current && current > dayjs().endOf("day")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item
                      name="feesType"
                      label="Fees Type"
                      className="marginbtm"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select fees type"
                        showSearch
                        labelInValue
                        options={masterOptions?.feesTypeOption}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      name="medium"
                      label="Medium"
                      className="marginbtm"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select medium"
                        showSearch
                        labelInValue
                        options={masterOptions?.paymentDetails}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item
                      name="amount"
                      label="Amount"
                      className="marginbtm"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                        controls={false}
                        name="mobileNumber"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      name="transactionId"
                      label="Transaction ID"
                      className="marginbtm"
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Transaction ID"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <Form.Item name="mediumDetails" label="Note">
                      <Input.TextArea
                        rows={3}
                        showCount
                        placeholder="Add Note Here....."
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default RegistrationFees;
