import * as React from "react";
import { useContext, useState, useEffect } from "react";
import LoginImg from '../../images/LoginIllustrationNew.svg';
import newLogoImg from '../../images/TTPLogo.svg';
import { Button, Col, Form, Input, Row, message } from 'antd';
import './Login.css';
import { Link } from 'react-router-dom';
import { AuthService } from '../../Services/AuthService';
import { UserOutlined } from '@ant-design/icons';

const ForgotPassword: React.FC = () => {

    const [btnLoading, setBtnLoading] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [form] = Form.useForm();

    const SendForgotPasswordLink = () => {
        form.validateFields().then(async (val) => {
            setBtnLoading(true);
            await AuthService.forgotPassword(val?.email?.trim()).then((res) => {
                if (res?.result) {
                    setBtnLoading(false);
                    setMailSent(true);
                }
                else {
                    setBtnLoading(false);
                    message.error(res?.message); 
                }
            });
            
        }).catch(() => { return false })
    }

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            SendForgotPasswordLink();
        }
    };

    return (
        <Row align="middle" className="h100vh">

            <Col span={12} style={window.innerWidth < 992 ? { display: "none" } : { display: "block" }}>
                <Row>
                    <Col xl={20} lg={23}>
                        <Row justify="end"><img src={LoginImg} alt="loginImg" /></Row>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ backgroundColor: "#fff", height: "100%" }}>
                <Row className="h100" align="middle">
                    <Col xxl={16} xl={18} lg={20} md={17} sm={18} xs={21}>
                        <Row align="middle" justify="end">
                            <Col xl={14} lg={16} md={13} sm={15} xs={21} className="pb-5 border-bottom"><img src={newLogoImg} height="36px" className="cursor" alt="Logo" /></Col>
                            <Col xl={14} lg={16} md={13} sm={15} xs={21} className="pt-4 pb-5"><span style={{ color: "#223b95", fontSize: 18, fontWeight: 500 }}>Recover Password</span></Col>
                            <Col xl={14} lg={16} md={13} sm={15} xs={21} style={{marginBottom:74} }>

                                {!mailSent ? <Form form={form} name="login" initialValues={{ remember: true }} className="login-form" onKeyDown={handleKeyDown}>
                                    <Row>
                                        <Col span={24} className="loginnames mb-1">
                                            Email
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Please enter your email!' }]}
                                            >
                                                <Input suffix={<UserOutlined style={{ color: "#00000073" }} />} className="loginInput" autoComplete="on" autoFocus />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: "0px 0px 20px 0px" }} justify="end" >
                                        <Col>
                                            <Link to="/login">Back to Login</Link>
                                        </Col>
                                    </Row>
                                    <Form.Item >
                                        <Row justify="center" className="pb-5 border-bottom">
                                            <Col>
                                                <Button type="primary" loading={btnLoading} onClick={() => SendForgotPasswordLink()}  >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>
                                    :
                                    <Row style={{ height: 197, alignContent:"flex-start" }}>
                                        <Col span={24}>
                                            Please check your email to reset your password.
                                        </Col>
                                        <Col span={24} className="mt-2">
                                            <Link to="/login">Back to Login</Link>
                                        </Col>
                                    </Row>}

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>


        </Row>
    );
};

export default ForgotPassword;
