import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  message,
  Select,
  Col,
  DatePicker,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { CourseMaster } from "../../../ApiUrls/URLS";
import { MasterTypes } from "../../../Constant/Enums";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
import dayjs from "dayjs";
import { RuleObject } from "antd/es/form";
let timeout: any;
interface AddEditCourseIn {
  setTrigger: any;
  openDrawer: any;
  courseId: string | undefined;
  SetDrawerClose: any;
}
const api = new ApiCalls(CourseMaster.endPoints, CourseMaster.prefix);

const AddEditCourse = (props: AddEditCourseIn) => {
  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [semesterLoading, setSemesterLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const [departmentData, setDepartmentData]: any = useState([]);
  const [semesterData, setSemesterData]: any = useState([]);

  const saveData = () => {
    form.validateFields().then(async (values: any) => {
      setBtnLoading(true);
      let val = {
        ...values,
        masterType: MasterTypes.Course,
        name: values?.name,
        credit: values?.credit,
        cgpa: values?.cgpa,
        id: props?.courseId,
        // department: {
        //   id: values?.department?.value,
        //   name: values?.department?.label,
        // },
        // schemas: values?.schemas?.map((item: any) => ({
        //   ...item,
        //   semesters: item?.semesters?.map((sem: any) => ({
        //     id: sem?.value,
        //     name: sem?.label,
        //   })),
        //   fromTo: {
        //     from: dayjs.utc(item?.fromTo?.[0]),
        //     to: dayjs.utc(item?.fromTo?.[1])
        //   }
        // }))
      };
      // if (val?.schemas?.length > 0) {
      await api
        .POST(CourseMaster.endPoints.save, null, val)
        .then((res: any) => {
          if (res.status) {
            onClose();
            openNotification(
              "success",
              props?.courseId
                ? "Course updated successfully"
                : "Course saved successfully"
            );
            props?.setTrigger((x: boolean) => !x);
          } else {
            openNotification("error", res.message);
          }
          setBtnLoading(false);
        });
      setBtnLoading(false);

      // }
      // else {
      //   message.error("Schema is not empty.")
      //   setBtnLoading(false);
      // }
      //console.log(val,'CourseValues');
    });
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.courseId) {
        setLoading(true);
        getCourseById(props?.courseId);
      }
      // getDepartments();
    }
  }, [props?.openDrawer]);

  const getCourseById = async (id: any) => {
    setLoading(true);
    await api
      .GET(CourseMaster.endPoints.getById + "/" + id + "?", {
        masterType: MasterTypes.Course,
      })
      .then((data: IApiResponse) => {
        if (data?.result) {
          let res = data?.result;
          // getSemesterOptions(res?.department?.id);
          form.setFieldsValue({
            name: res?.name,
            credit: res?.credit,
            cgpa: res?.cgpa,
            // alias: res?.alias,
            // department: {
            //   value: res?.department?.id,
            //   label: res?.department?.name,
            // },
            // schemas: res?.schemas?.map((item: any) => ({
            //   ...item,
            //   fromTo: [
            //     dayjs.utc(item?.fromTo?.from),
            //     dayjs.utc(item?.fromTo?.to),
            //   ],
            //   semesters: item?.semesters?.map((sem: any) => ({
            //     label: sem?.name,
            //     value: sem?.id,
            //   })),
            // })),
            // semesters: res?.semesters?.map((sem: any) => ({
            //   value: sem?.id,
            //   label: sem?.name,
            // }))
          });
          setLoading(false);
        } else {
          message.error(data?.message);
          setLoading(false);
        }
      });
  };
  const getSemesterOptions = async (departmentId: string) => {
    setSemesterData([]);
    // let schemas = form.getFieldValue("schemas")
    // console.log("Schemas = ", schemas)
    // schemas?.map((item: any, index: number) => (form.resetFields(["schemas", index, "semesters"])))
    form.resetFields(["schemas"]);
    setSemesterLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_SEMESTERS_BY_DEPARTMENT + "?",
      {
        departmentId: departmentId,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.length >= 0) {
      setSemesterData(
        res?.result?.map((x: any) => ({ label: x?.name, value: x?.id }))
      );
      setSemesterLoading(false);
    } else {
      message.error(res?.message);
      setSemesterLoading(false);
    }
  };
  const onClose = () => {
    props?.SetDrawerClose(false);
    setSemesterData([]);
    setDepartmentData([]);
    form.resetFields();
  };
  const getDepartments = async (val: string = "") => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: MasterTypes.Department,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      // console.log("result", result);
      setDepartmentData(
        result?.map((x: any) => ({ label: x?.name, value: x?.id }))
      );
    } else {
      message.error(res?.message);
    }
  };
  const getSemesters = async (val: string = "") => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: MasterTypes.Semester,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      // console.log("result", result);
      setSemesterData(
        result?.map((x: any) => ({ label: x?.name, value: x?.id }))
      );
    } else {
      message.error(res?.message);
    }
  };
  const handleSearch = async (val: string, masterType: MasterTypes) => {
    let result: any = [];
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: masterType,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      result = res?.result?.items?.map((x: any) => ({
        label: x?.name,
        value: x?.id,
      }));
    } else {
      message.error(res?.message);
    }
    // console.log("result = ", result)
    return result;
  };
  // console.log("val=  ", form.getFieldValue("schemas"))
  return (
    <>
      <Drawer
        title={props?.courseId ? "Edit Course" : "Add Course"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.courseId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="Course-form"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter Course Name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter Course Name"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Credit"
              name="credit"
              rules={[{ required: true, message: "Please enter Credit!" }]}
            >
              <Input placeholder="Enter Credit" />
            </Form.Item>
            <Form.Item
              label="CGPA"
              name="cgpa"
              rules={[{ required: true, message: "Please enter CGPA!" }]}
            >
              <Input placeholder="Enter CGPA" />
            </Form.Item>
            {/* <Form.Item
              label="Alias"
              name="alias"
              rules={[{ required: true, message: "Please enter Alias name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter Course Alias"
                onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Department"
              name="department"
              // wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: "Please select department!" }]}
            >
              <Select
                placeholder="Select Department"
                labelInValue
                onSelect={(val: any) => {
                  getSemesterOptions(val?.value)
                }}
                // filterOption={(value: any) => {
                //   // console.log("value = ", value)
                //   let r: any = []
                //   if (value) {
                //     clearTimeout(timeout)
                //     timeout = setTimeout(async () => {
                //       r = await handleSearch(value, MasterTypes.Department)
                //     }, 350)
                //     // console.log("r =  ", r)
                //     return r
                //   }
                // }}
                // showSearch
                // allowClear
                // onClear={async () => {
                //   await getDepartments()
                // }}
                options={departmentData}
              />
            </Form.Item>

            <Form.Item>
              <Form.List
                name={"schemas"}
              //   initialValue={[{}]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }, index) => {
                        return (
                          <div key={index}>
                            <Row gutter={[8, 20]} key={index}>
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "fromTo"]}
                                  label={""}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Select duration",
                                    },
                                  ]}
                                >
                                  <RangePicker
                                    // picker="year"
                                    onChange={(val: any) => {
                                      if (val) {
                                        form.setFieldValue(["schemas", name, "name"],
                                          `Schema ${val[0]?.format("YYYY")}-${val[1]?.format("YYYY")}`
                                        )
                                      }
                                      else {
                                        form.setFieldValue(["schemas", name, "name"],
                                          ""
                                        )
                                      }
                                    }}
                                    size="middle"
                                    style={{ width: "100%" }}
                                    format="YYYY-MM-DD"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={5}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  label={""}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Name is required",
                                    },
                                  ]}
                                >
                                  <Input readOnly placeholder="Name" />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "semesters"]}
                                  label={""}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Select semester(s)",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Semester"
                                    mode="multiple"
                                    labelInValue
                                    loading={semesterLoading}
                                    // filterOption={(value: any) => {
                                    //   // console.log("value = ", value)
                                    //   let r: any = []
                                    //   if (value) {
                                    //     clearTimeout(timeout)
                                    //     timeout = setTimeout(async () => {
                                    //       r = await handleSearch(value, MasterTypes.Semester)
                                    //     }, 350)
                                    //     // console.log("r =  ", r)
                                    //     return r
                                    //   }
                                    // }}
                                    // showSearch
                                    // allowClear
                                    // onClear={async () => {
                                    //   await getSemesters()
                                    // }}
                                    options={semesterData}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "description"]}
                                  label={""}
                                >
                                  <Input.TextArea
                                    placeholder="Description"
                                    autoSize={{
                                      minRows: 1,
                                      maxRows: 6,
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <Button
                                  style={
                                    index === 0
                                      ? {
                                        borderRadius: "5px",
                                        // marginTop: "25px",
                                      }
                                      : {
                                        borderRadius: "5px",
                                        // marginTop: "2px",
                                      }
                                  }
                                  onClick={() => {
                                    remove(name);
                                  }}
                                  icon={<DeleteOutlined />}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                    <Form.Item className="m-0 pb-4">
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (form.getFieldValue("department")) {
                            add()
                          }
                          else {
                            message.error("Select department")
                          }
                        }}
                      >
                        Schemas
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item> */}
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditCourse;
